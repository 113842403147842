import React from "react";
import { FormattedMessage } from "react-intl";
import "./Header.scss";

export const Header = () => (
  <div className="kyc-header">
    <h1>
      <FormattedMessage id="kyc.header" />
    </h1>
    <p>
      <FormattedMessage id="kyc.intro" />
    </p>
  </div>
);
