import { useContext } from "react";
import { LysaCountry } from "@lysaab/shared";
import { DocModalLink } from "@lysaab/ui-2";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { LocalizationContext } from "../../state/LocalizationContext";

const messages = defineMessages({
  modalAnnouncement: { id: "intro.modal.announcement" },
});

const docList: Record<"suitabilityAssessment", Record<LysaCountry, string>> = {
  suitabilityAssessment: {
    [LysaCountry.SWEDEN]: "legal/se/sv/lämplighetsbedömning.md",
    [LysaCountry.DENMARK]: "legal/all/en/suitability-assessment.md",
    [LysaCountry.FINLAND]: "legal/all/en/suitability-assessment.md",
    [LysaCountry.GERMANY]: "legal/de/de/suitability-assessment.md",
    [LysaCountry.SPAIN]: "legal/all/en/suitability-assessment.md",
  },
};

export const IntroDocsParagraph = () => {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);
  const country = localizationContext.state.country;

  if (!country) {
    return null;
  }

  return (
    <p>
      <FormattedMessage
        id="intro.acknowledge.privacy_policy"
        values={{
          link: (linkText: React.ReactNode[]) => {
            return (
              <DocModalLink
                document={docList.suitabilityAssessment[country]}
                modalAnnouncement={intl.formatMessage(
                  messages.modalAnnouncement
                )}
                key="element-key-sa"
              >
                {linkText}
              </DocModalLink>
            );
          },
        }}
      />
    </p>
  );
};
