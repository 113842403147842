import React, { useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  RequiredValidator,
  BirthdayInput,
  MaxStringDateValidator,
} from "@lysaab/ui-2";
import { SignupContext } from "../../state/SignupContext";
import "./Crs.scss";
import { DateTime } from "luxon";

const messages = defineMessages({
  dateOfBirthLabel: { id: "crs.date_of_birth.label" },
  dateOfBirthRequired: { id: "crs.date_of_birth.required" },
  min18: { id: "crs.date_of_birth.min18" },
});

const eighteenYearsAgo = DateTime.local()
  .minus({ years: 18 })
  .toFormat("yyyy-LL-dd");

export const CrsBirthday: React.VFC = () => {
  const intl = useIntl();
  const signupContext = useContext(SignupContext);

  return (
    <BirthdayInput
      label={intl.formatMessage(messages.dateOfBirthLabel)}
      onChange={(value) => signupContext.setState({ crsBirthday: value })}
      value={signupContext.state.crsBirthday || ""}
      max={eighteenYearsAgo}
      validators={[
        new RequiredValidator(intl.formatMessage(messages.dateOfBirthRequired)),
        new MaxStringDateValidator(
          eighteenYearsAgo,
          intl.formatMessage(messages.min18)
        ),
      ]}
    />
  );
};
