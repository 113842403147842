import React, { useState } from "react";
import { Modal } from "../modal/Modal";
import { useLocalizationContext } from "../../state/LocalizationContext";
import { LysaLink } from "@lysaab/ui-2";

interface Props {
  children: React.ReactNode;
  header: React.ReactNode;
  content: React.ReactNode;
}

export const DescriptionModal: React.FC<Props> = ({
  children,
  header,
  content,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { state } = useLocalizationContext();

  if (typeof state.country === "undefined") {
    return null;
  }

  return (
    <>
      <LysaLink
        onClick={() => setIsModalVisible((isModalVisible) => !isModalVisible)}
      >
        {children}
      </LysaLink>
      <Modal
        showModal={isModalVisible}
        header={header}
        onClose={() => setIsModalVisible(false)}
      >
        {content}
      </Modal>
    </>
  );
};
