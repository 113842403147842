import { useLocation } from "react-router-dom";
import { useRef, useEffect } from "react";
import { EventTracker, TrackerEvent } from "../utils/eventTracker/EventTracker";

export function useActivityTracker() {
  const prevPathname = useRef<string>();
  const location = useLocation();

  useEffect(() => {
    if (prevPathname.current !== location.pathname) {
      EventTracker.track({
        event: TrackerEvent.NAVIGATION,
        message: { from: prevPathname.current },
      });
      prevPathname.current = location.pathname;
    }
  }, [location]);
}
