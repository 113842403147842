import { Icon } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { SmallPosition } from "../data/holdings";
import { Currency } from "./Currency";
import { Percent } from "./Percent";

interface Props {
  positions: SmallPosition[];
  percent: number;
  totalWorth?: number;
}

export const PositionsRemaining: React.VFC<Props> = ({
  positions,
  percent,
  totalWorth,
}) => {
  if (positions.length === 0) {
    return null;
  }

  return (
    <>
      <dt>
        <Icon.Add size={10} />{" "}
        <FormattedMessage
          id="positionsremaining.text.other"
          values={{ count: positions.length }}
        />
      </dt>
      <dd>
        <span>
          <Percent number={percent / 100} maxDecimals={2} minDecimals={2} />
        </span>
        <span className="positions-currency">
          {totalWorth && (
            <Currency
              number={(totalWorth / 100) * percent}
              minDecimals={2}
              maxDecimals={2}
            />
          )}
        </span>
      </dd>
    </>
  );
};
