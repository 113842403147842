import { SignupId } from "@lysaab/lysa-onfido/data/signup";
import { API, encode } from "@lysaab/ui-2";
import { EventTrackRequest } from "../utils/eventTracker/EventTracker";
export interface TrackerSignupIdResponse {
  signupId: SignupId;
}

export const trackerAPI = {
  postEvents: (trackerSignupId: SignupId, events: EventTrackRequest[]) => {
    return API.post<TrackerSignupIdResponse>(
      encode`/signup/person/event/${trackerSignupId}`,
      events
    );
  },
};
