import React from "react";
import { Switch, Route } from "react-router-dom";
import { SwedenRouter } from "./Sweden/Router";
import { DenmarkRouter } from "./Denmark/Router";
import { FinlandRouter } from "./Finland/Router";
import { GermanyRouter } from "./Germany/Router";
import { SpainRouter } from "./Spain/Router";
import { CountrySelectPage } from "./pages/countrySelect/CountrySelectPage";
import { SwedenContextProvider } from "./Sweden/SwedenContext";
import { DenmarkContextProvider } from "./Denmark/DenmarkContext";
import { FinlandContextProvider } from "./Finland/FinlandContext";
import { GermanyContextProvider } from "./Germany/GermanyContext";
import { SpainContextProvider } from "./Spain/SpainContext";
import { NetsDonePage, netsDoneSignup } from "@lysaab/lysa-nets";
import { LysaCountry } from "@lysaab/shared";
import "./App.scss";
import { usePageTracking } from "./hooks/usePageTracking";

export const ROUTES: Record<LysaCountry, string> = {
  [LysaCountry.SWEDEN]: "/se",
  [LysaCountry.DENMARK]: "/dk",
  [LysaCountry.FINLAND]: "/fi",
  [LysaCountry.GERMANY]: "/de",
  [LysaCountry.SPAIN]: "/es",
};

export const NETS_DONE = "/:country(\\w\\w)/nets/done";

export const TopLevelRoutes = React.memo(() => {
  usePageTracking();

  return (
    <Switch>
      <Route path="/" exact component={CountrySelectPage} />
      <Route path={NETS_DONE}>
        <NetsDonePage netsDone={netsDoneSignup} />
      </Route>
      <Route path={ROUTES[LysaCountry.SWEDEN]}>
        <SwedenContextProvider>
          <SwedenRouter />
        </SwedenContextProvider>
      </Route>
      <Route path={ROUTES[LysaCountry.DENMARK]}>
        <DenmarkContextProvider>
          <DenmarkRouter />
        </DenmarkContextProvider>
      </Route>
      <Route path={ROUTES[LysaCountry.FINLAND]}>
        <FinlandContextProvider>
          <FinlandRouter />
        </FinlandContextProvider>
      </Route>
      <Route path={ROUTES[LysaCountry.GERMANY]}>
        <GermanyContextProvider>
          <GermanyRouter />
        </GermanyContextProvider>
      </Route>
      <Route path={ROUTES[LysaCountry.SPAIN]}>
        <SpainContextProvider>
          <SpainRouter />
        </SpainContextProvider>
      </Route>
    </Switch>
  );
});
