import React from "react";
import { Fund, FundType, LargePosition } from "../data/holdings";
import { FormattedMessage } from "react-intl";

import "./PositionsList.scss";
import { Currency } from "./Currency";
import { InstrumentName } from "./InstrumentName/InstrumentName";
import { Isin } from "../data/Investments";
import { isTranslatedFund } from "./InstrumentName/FundName";
import { PositionsRemaining } from "./PositionsRemaining";
import { Percent } from "./Percent";

export type Positions = {
  [key: string]: {
    worth: number;
  };
};

interface Props {
  holdings?: Fund[];
  positions: Positions;
  header: string;
  type: FundType;
}

interface FundWithWorth extends Fund {
  worth: number;
  isin: Isin;
}

export const PositionsList: React.VFC<Props> = ({
  holdings,
  positions,
  header,
  type,
}) => {
  if (!positions || !holdings) {
    return null;
  }

  const positionIsins = Object.keys(positions) as Isin[];

  const funds = positionIsins
    .reduce((funds, isin) => {
      const fund = holdings.find((fund) =>
        fund.fundShareClasses.map(({ isin }) => isin).includes(isin)
      );
      if (fund) {
        funds.push({
          ...fund,
          isin: isin,
          worth: positions[isin].worth,
        });
      }
      return funds;
    }, [] as FundWithWorth[])
    .filter(({ fundType }) => fundType === type)
    .sort((a, b) => b.worth - a.worth) as FundWithWorth[];

  return (
    <div className="positions-list">
      <h4>{header}</h4>
      {funds.map((fund, index) => {
        const shareClass = fund.fundShareClasses.find(
          (shareClass) => shareClass.isin === fund.isin
        );

        const fundname = isTranslatedFund(fund.isin) ? (
          <InstrumentName isin={fund.isin} />
        ) : (
          shareClass?.name
        );

        return (
          <React.Fragment key={index}>
            {fund.largePositions.map((position) => (
              <PositionsInstrument
                position={position}
                totalWorth={fund.worth}
              />
            ))}
            {fund && (
              <PositionsRemaining
                positions={fund.remainingPositions}
                percent={fund.remainingPositionsPercent}
                totalWorth={fund.worth}
              />
            )}
            <div className="summary-cell-container">
              <dt className="summary-cell">
                <FormattedMessage
                  id="positionslist.text.total"
                  values={{ fundname }}
                />
              </dt>
              <dd className="summary-cell text-right">
                <strong>
                  <Currency number={fund.worth} />
                </strong>
              </dd>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

interface PositionsInstrumentProps {
  position: LargePosition;
  totalWorth: number;
}

const PositionsInstrument: React.VFC<PositionsInstrumentProps> = ({
  position,
  totalWorth,
}) => {
  const translatedInstrument = position.instruments.find((instrument) =>
    isTranslatedFund(instrument.isin)
  );

  const instrumentText = position.instruments
    .map(({ name }) => name)
    .join(", ");

  return (
    <React.Fragment key={position.instruments[0].isin}>
      <dt>
        <span className="name">
          {translatedInstrument ? (
            <InstrumentName isin={translatedInstrument.isin} />
          ) : (
            instrumentText
          )}
        </span>
      </dt>
      <dd className="position-value-cell">
        <span className="positions-percent">
          <Percent
            number={position.percent / 100}
            maxDecimals={2}
            minDecimals={2}
          />
        </span>
        <span className="positions-currency">
          <Currency
            number={(totalWorth / 100) * position.percent}
            minDecimals={2}
            maxDecimals={2}
          />
        </span>
      </dd>
    </React.Fragment>
  );
};
