import { DateTime } from "luxon";
import { LocalStorage } from "./localStorage";
interface Invite {
  invite: string;
  created: number;
}

/**
 * 30 days in millis
 */
const INVITE_TIMEOUT = 1000 * 3600 * 24 * 30;

export class ReferralStorage {
  static setInvite(invite: string) {
    const data: Invite = {
      invite: invite,
      created: DateTime.local().toMillis(),
    };
    LocalStorage.setItem(LocalStorage.Key.INVITE, JSON.stringify(data));
  }

  static getInvite(): Invite | undefined {
    const data = LocalStorage.getItem(LocalStorage.Key.INVITE);
    if (typeof data === "undefined") {
      return;
    }
    const parsedData = JSON.parse(data) as Invite;
    if (DateTime.local().toMillis() - parsedData.created > INVITE_TIMEOUT) {
      return;
    }
    return parsedData;
  }
}
