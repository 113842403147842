import React, { useContext, useState, useRef, useEffect } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import {
  Card,
  OtpInput,
  RequiredValidator,
  OtpValidator,
  Form,
  LysaFormRef,
  TotpQrcode,
  Spinner,
  Snackbar,
  SNACKBAR_TYPES,
  Button,
} from "@lysaab/ui-2";
import { totpSetupInit, totpSetup } from "../../data/signup";
import "./Totp.scss";
import { SignupContext } from "../../state/SignupContext";
import { useCountryUrl } from "../../hooks/useCountryUrl";
import { useHistory } from "react-router-dom";

export const ROUTE = "/totp";

const messages = defineMessages({
  totpError: { id: "totp.error" },
  otpInputLabel: { id: "totp.otp.input.label" },
  otpRequired: { id: "totp.otp.required" },
  otpInvalid: { id: "totp.otp.invalid" },
  linkDescription: { id: "totp.otp.linkDescription" },
  manualSetupDescription: { id: "totp.otp.manualSetupDescription" },
});

interface Props {
  next: () => void;
}

export const Totp: React.FC<Props> = (props) => {
  const intl = useIntl();
  const signupContext = useContext(SignupContext);
  const [totp, setTotp] = useState("");
  const [error, setError] = useState("");
  const formRef = useRef<LysaFormRef>();
  const countryUrl = useCountryUrl();
  const history = useHistory();

  useEffect(() => {
    if (!signupContext.state.totpSecret) {
      totpSetupInit().then((resp) => {
        signupContext.setState({
          totpSecret: resp.secret,
          totpSignature: resp.signature,
        });
      });
    }
  }, [signupContext]);

  useEffect(() => {
    const tin = signupContext.state.crsReportingCountries?.[0].tin;
    if (!tin) {
      history.push(countryUrl);
    }
  }, [signupContext, countryUrl, history]);

  const next = () => {
    if (!formRef.current?.isValid) {
      return;
    }

    totpSetup({
      code: totp,
      secret: signupContext.state.totpSecret,
      signature: signupContext.state.totpSignature,
    })
      .then((resp) => {
        signupContext.setState({ totpSignedSecret: resp.signedSecret });
        props.next();
      })
      .catch(() => {
        setError(intl.formatMessage(messages.totpError));
      });
  };

  if (!signupContext.state.totpSecret) {
    return <Spinner />;
  }

  return (
    <div className="totp-page">
      <h1>
        <FormattedMessage id="totp.header" />
      </h1>
      <Card>
        <h2>
          <FormattedMessage id="totp.setup.header" />
        </h2>
        <p className="desc-desktop">
          <FormattedMessage id="totp.setup.desc" />
        </p>
        <p>
          <FormattedMessage id="totp.setup.warning" />
        </p>
        <TotpQrcode
          linkDescription={intl.formatMessage(messages.linkDescription)}
          manualSetupDescription={intl.formatMessage(
            messages.manualSetupDescription
          )}
          tin={signupContext.state.crsReportingCountries?.[0].tin || ""}
          secret={signupContext.state.totpSecret}
        />
      </Card>
      <Card>
        <h2>
          <FormattedMessage id="totp.verification.header" />
        </h2>
        <p>
          <FormattedMessage id="totp.verification.info" />
        </p>
        <Form
          lysaFormRef={formRef}
          onSubmit={(event) => {
            event.preventDefault();
            if (formRef.current?.isValid) {
              next();
            }
          }}
        >
          <OtpInput
            value={totp}
            label={intl.formatMessage(messages.otpInputLabel)}
            validators={[
              new RequiredValidator(intl.formatMessage(messages.otpRequired)),
              new OtpValidator(intl.formatMessage(messages.otpInvalid)),
            ]}
            onChange={(totp) => {
              setTotp(totp);
              setError("");
            }}
          />

          {error && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              {error}
            </Snackbar>
          )}

          <Button
            block
            type="submit"
            label={<FormattedMessage id="totp.next" />}
          />
        </Form>
      </Card>
    </div>
  );
};
