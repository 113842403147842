import { LysaCountry } from "@lysaab/shared";
import { API, InvestmentType, encode } from "@lysaab/ui-2";
import { AccountType } from "../pages/confirmation/ConfirmationHelpers";

export interface GetFeesResponse {
  future: {
    discretionary: number;
    fundManagement: number;
    fundAssets: number;
    transactionFees: number;
    total: number;
  };
  cost: {
    discretionary: number;
    fundManagement: number;
    fundAssets: number;
    transactionFees: number;
    total: number;
  };
}

interface FeesEstimatedParams {
  amount: number;
  risk: number;
  investmentType: InvestmentType;
  country: LysaCountry;
  accountType: AccountType.ISK_SWE | AccountType.VP;
}

export function getFees({
  amount,
  risk,
  investmentType,
  country,
  accountType,
}: FeesEstimatedParams) {
  const url = encode`/fees/estimate/yearly?amount=${amount}&risk=${risk}&investmentType=${investmentType}&country=${country}&accountType=${accountType}`;
  return API.get<GetFeesResponse>(url);
}
