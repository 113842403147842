import React, { useContext, useState, useRef } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import {
  Card,
  NewPasswordInput,
  PasswordValidator,
  PasswordConfirmValidator,
  RequiredValidator,
  Form,
  LysaFormRef,
  Button,
} from "@lysaab/ui-2";
import "./Password.scss";
import { SignupContext } from "../../state/SignupContext";
import { LocalizationContext } from "../../state/LocalizationContext";

export const ROUTE = "/password";

const messages = defineMessages({
  label_first: {
    id: "password.input.label_first",
  },
  label_repeat: {
    id: "password.input.label_confirm",
  },
  required_validator_msg: {
    id: "password.input.required_validator_msg",
  },
  password_validator_msg: {
    id: "password.input.password_validator_msg",
  },
  required_confirm_validator_msg: {
    id: "password.input.required_confirm_validator_msg",
  },
  password_confirm_validator_msg: {
    id: "password.input.password_confirm_validator_msg",
  },
});

interface Props {
  next: () => void;
}

export const Password: React.FC<Props> = (props) => {
  const localizationContext = useContext(LocalizationContext);
  const signupContext = useContext(SignupContext);
  const [passwordCopy, setPasswordCopy] = useState("");
  const intl = useIntl();
  const formRef = useRef<LysaFormRef>();

  return (
    <div className="password-page">
      <h1>
        <FormattedMessage id="password.header" />
      </h1>
      <p>
        <FormattedMessage
          id="password.instructions"
          values={{
            country: localizationContext.state.country,
            username: signupContext.state.crsReportingCountries?.[0]?.tin,
          }}
        />
      </p>
      <Form
        lysaFormRef={formRef}
        onSubmit={(event) => {
          event.preventDefault();
          if (formRef.current?.isValid) {
            props.next();
          }
        }}
      >
        <Card>
          {/* Give password managers a username to tie to the password */}
          <input
            type="text"
            name="username"
            disabled
            style={{ display: "none" }}
            value={signupContext.state.crsReportingCountries?.[0]?.tin || ""}
          />
          <NewPasswordInput
            label={intl.formatMessage(messages.label_first)}
            value={signupContext.state.password}
            onChange={(value) => signupContext.setState({ password: value })}
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.required_validator_msg)
              ),
              new PasswordValidator(
                intl.formatMessage(messages.password_validator_msg)
              ),
            ]}
          />
          <NewPasswordInput
            label={intl.formatMessage(messages.label_repeat)}
            value={passwordCopy}
            onChange={setPasswordCopy}
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.required_confirm_validator_msg)
              ),
              new PasswordConfirmValidator(
                intl.formatMessage(messages.password_confirm_validator_msg),
                signupContext.state.password
              ),
            ]}
          />
        </Card>

        <Button
          block
          type="submit"
          label={<FormattedMessage id="password.next" />}
        />
      </Form>
    </div>
  );
};
