import React, { useContext } from "react";
import { ReactForecast, GetMessageFunction, Slider } from "@lysaab/ui-2";
import { MoneyInput } from "@lysaab/ui-2/components/input/MoneyInput";

import "./EditAllocationForecast.scss";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { LocalizationContext } from "../../state/LocalizationContext";
import { SignupContext } from "../../state/SignupContext";
import { DEFAULT_VALUES } from "./DefaultValues";

const messages = defineMessages({
  yearAlternativesYear: { id: "editallocationforecast.yearalternatives.year" },
  graphTooShort: { id: "editallocationforecast.graph.tooShort" },
  graphProbability: { id: "editallocationforecast.graph.probability" },
  graphVeryGood: { id: "editallocationforecast.graph.veryGood" },
  graphGood: { id: "editallocationforecast.graph.good" },
  graphBad: { id: "editallocationforecast.graph.bad" },
  graphVeryBad: { id: "editallocationforecast.graph.veryBad" },
});

interface Props {
  chosenAllocation: string;
  monthly: number;
  setMonthly: (monthly: number) => void;
  horizon: number;
  setHorizon: (horizon: number) => void;
}

export const EditAllocationForecast = ({
  chosenAllocation,
  monthly,
  setMonthly,
  horizon,
  setHorizon,
}: Props) => {
  const intl = useIntl();
  const signupContext = useContext(SignupContext);
  const localizationContext = useContext(LocalizationContext);

  const getTexts: GetMessageFunction = (args) => {
    return {
      tooShort: intl.formatMessage(messages.graphTooShort),
      probability: intl.formatMessage(messages.graphProbability, {
        years: args.years,
        months: args.months,
      }),
      veryGoodText: intl.formatMessage<React.ReactNode>(
        messages.graphVeryGood,
        {
          sum: intl.formatNumber(args.veryGoodSum, {
            currency: localizationContext.state.currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
          percent: args.veryGoodPercentage / 100,
          bold: (str) => <b>{str}</b>,
        }
      ),
      goodText: intl.formatMessage<React.ReactNode>(messages.graphGood, {
        sum: intl.formatNumber(args.goodSum, {
          currency: localizationContext.state.currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        percent: args.goodPercentage / 100,
        bold: (str) => <b>{str}</b>,
      }),
      badText: intl.formatMessage<React.ReactNode>(messages.graphBad, {
        sum: intl.formatNumber(args.badSum, {
          currency: localizationContext.state.currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        percent: args.badPercentage / 100,
        bold: (str) => <b>{str}</b>,
      }),
      veryBadText: intl.formatMessage<React.ReactNode>(messages.graphVeryBad, {
        sum: intl.formatNumber(args.veryBadSum, {
          currency: localizationContext.state.currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        percent: args.veryBadPercentage / 100,
        bold: (str) => <b>{str}</b>,
      }),
    };
  };

  let investment = signupContext.state.allocationInvestment;

  if (
    investment <
      DEFAULT_VALUES[localizationContext.state.currency].minInvestment ||
    investment >
      DEFAULT_VALUES[localizationContext.state.currency].maxInvestment
  ) {
    investment = 0;
  }

  return (
    <div className="edit-allocation-forecast-page">
      <h4>
        <FormattedMessage id="editallocationforecast.header.monthly" />
      </h4>
      <MoneyInput
        locale={localizationContext.getLocale()}
        currency={localizationContext.state.currency}
        value={monthly.toString()}
        onChange={(value) => setMonthly(parseFloat(value) || 0)}
        placeholder={intl.formatNumber(
          DEFAULT_VALUES[localizationContext.state.currency].monthly
        )}
      />
      <Slider
        min={1}
        max={50}
        step={1}
        value={horizon}
        onChange={(value) => setHorizon(value)}
        label={
          <h4 className="edit-allocation-forecast-slider-header">
            <strong>
              <FormattedMessage id="editallocation.text.horizon" />
            </strong>
            <i>
              <FormattedMessage
                id="editallocation.text.horizon.years"
                values={{ horizon }}
              />
            </i>
          </h4>
        }
      />
      <div className={"graph-wrapper" + (investment ? "" : " disabled")}>
        <ReactForecast
          initial={investment.toString()}
          interval={horizon.toString()}
          monthly={investment ? monthly.toString() : "0"}
          risk={chosenAllocation}
          getTexts={getTexts}
          currency={localizationContext.state.currency}
          locale={localizationContext.getLocale()}
        />
      </div>
    </div>
  );
};
