import { LysaCountry } from "@lysaab/shared";
import {
  AllTinTypes,
  AllTinValidatorTypes,
  BaseValidator,
  CircleButton,
  CountrySelect,
  DanishTin,
  DanishTinValidator,
  FinnishTin,
  FinnishTinValidator,
  GermanTin,
  GermanTinValidator,
  MinAgeTinValidator,
  RequiredValidator,
  SpanishTin,
  SpanishTinValidator,
  SwedishTin,
  SwedishTinValidator,
  TextInput,
  TinServerValidator,
  WorldCountry,
} from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import React, { useContext } from "react";
import { LocalizationContext } from "../../state/LocalizationContext";
import { SignupContext } from "../../state/SignupContext";
import { reportingCountriesMessages } from "./CrsMessages";
import { TaxLink } from "../../components/taxLink/TaxLink";

const countryTinValidatorMap: Record<LysaCountry, AllTinValidatorTypes> = {
  [LysaCountry.DENMARK]: DanishTinValidator,
  [LysaCountry.FINLAND]: FinnishTinValidator,
  [LysaCountry.SWEDEN]: SwedishTinValidator,
  [LysaCountry.GERMANY]: GermanTinValidator,
  [LysaCountry.SPAIN]: SpanishTinValidator,
};

const countryTinParserMap: Record<LysaCountry, AllTinTypes> = {
  [LysaCountry.DENMARK]: DanishTin,
  [LysaCountry.FINLAND]: FinnishTin,
  [LysaCountry.SWEDEN]: SwedishTin,
  [LysaCountry.GERMANY]: GermanTin,
  [LysaCountry.SPAIN]: SpanishTin,
};

interface Props {
  validateAge: boolean;
}

export const CrsReportingCountries: React.VFC<Props> = ({ validateAge }) => {
  const intl = useIntl();
  const signupContext = useContext(SignupContext);
  const localizationContext = useContext(LocalizationContext);

  return (
    <>
      {signupContext.state.crsReportingCountries?.map(
        (reportingCountryData, index, arr) => {
          const tinValidators: BaseValidator[] = [
            new RequiredValidator(
              intl.formatMessage(reportingCountriesMessages.tinRequired)
            ),
          ];

          // Typecasting to LysaCountry here because we'll use `country` in a
          // couple of Map lookups. But we also make sure to handle the
          // case where it isn't actually a country we know how to handle
          // by checking for truthy after the lookups
          const { country } = reportingCountryData;

          if (country) {
            // If `country` is a country Lysa doesn't exist in the map
            // lookup will return undefined
            const CountryTinValidator =
              countryTinValidatorMap[country.toString() as LysaCountry];

            const msg = intl.formatMessage(
              reportingCountriesMessages.tinValidation,
              {
                country,
              }
            );
            if (CountryTinValidator) {
              tinValidators.push(new CountryTinValidator(msg));
            } else {
              tinValidators.push(new TinServerValidator(msg, country));
            }

            const CountryTinParser =
              countryTinParserMap[country.toString() as LysaCountry];
            if (CountryTinParser && validateAge) {
              tinValidators.push(
                new MinAgeTinValidator(
                  18,
                  CountryTinParser,
                  intl.formatMessage(reportingCountriesMessages.min18)
                )
              );
            }
          }

          return (
            <div className="repeat-country" key={"repeat-country-" + index}>
              <CountrySelect
                language={localizationContext.state.language}
                label={intl.formatMessage(
                  reportingCountriesMessages.reportingCountriesLabel
                )}
                placeholder={intl.formatMessage(
                  reportingCountriesMessages.reportingCountriesPlaceholder
                )}
                value={reportingCountryData.country}
                onChange={(alternative) => {
                  const crsReportingCountries = [
                    ...(signupContext.state.crsReportingCountries || []),
                  ];
                  crsReportingCountries[index].country = alternative.value;
                  signupContext.setState({ crsReportingCountries });
                }}
                validators={[
                  new RequiredValidator(
                    intl.formatMessage(
                      reportingCountriesMessages.reportingCountriesRequired
                    )
                  ),
                ]}
                omitList={[
                  ...(signupContext.state.crsReportingCountries ?? [])
                    .filter(
                      ({ country }) => country !== reportingCountryData.country
                    )
                    .map(({ country }) => country as WorldCountry),
                  WorldCountry.UNITED_STATES_OF_AMERICA,
                ]}
                disabled={index === 0}
              />
              <TextInput
                label={intl.formatMessage(reportingCountriesMessages.tinLabel, {
                  country,
                })}
                value={reportingCountryData.tin || ""}
                onChange={(tin) => {
                  const crsReportingCountries = [
                    ...(signupContext.state.crsReportingCountries || []),
                  ];
                  crsReportingCountries[index].tin = tin;
                  signupContext.setState({ crsReportingCountries });
                }}
                validators={tinValidators}
              />
              {index === 0 && (
                <p>
                  {intl.formatMessage<React.ReactNode>(
                    reportingCountriesMessages.residenceInfo,
                    {
                      country: intl.formatDisplayName(
                        localizationContext.state.country || "",
                        { type: "region" }
                      ),
                      help: (parts) => <TaxLink>{parts}</TaxLink>,
                    }
                  )}
                </p>
              )}

              {arr.length > 1 && (
                <CircleButton
                  onClick={() => {
                    const crsReportingCountries = [
                      ...(signupContext.state.crsReportingCountries || []),
                    ];
                    crsReportingCountries.splice(index, 1);
                    signupContext.setState({ crsReportingCountries });
                  }}
                  disabled={index === 0}
                  icon="Minus"
                />
              )}
              {index === arr.length - 1 && (
                <CircleButton
                  onClick={() => {
                    const crsReportingCountries = [
                      ...(signupContext.state.crsReportingCountries || []),
                    ];
                    crsReportingCountries.push({ tin: "", country: undefined });
                    signupContext.setState({ crsReportingCountries });
                  }}
                  icon="Plus"
                />
              )}
            </div>
          );
        }
      )}
    </>
  );
};
