import { defineMessages } from "react-intl";

export const crsMessages = defineMessages({
  header: { id: "crs.header" },
  buttonNextConfirmText: { id: "crs.button.next.confirmText" },
  buttonNext: { id: "crs.button.next" },
  needToAddReportingCountry: { id: "crs.needToAddReportingCountry" },
});

export const addressMessages = defineMessages({
  streetLabel: { id: "crs.street.label" },
  streetRequired: { id: "crs.street.required" },
  streetLength: { id: "crs.street.length" },
  postCodeLabel: { id: "crs.postal_code.label" },
  postCodeRequired: { id: "crs.postal_code.required" },
  cityLabel: { id: "crs.city.label" },
  cityRequired: { id: "crs.city.required" },
  cityLength: { id: "crs.city.length" },
});

export const reportingCountriesMessages = defineMessages({
  residenceInfo: { id: "crs.residence_country.info" },
  reportingCountriesLabel: { id: "crs.reporting_countries.label" },
  reportingCountriesPlaceholder: { id: "crs.reporting_countries.placeholder" },
  reportingCountriesRequired: { id: "crs.reporting_country.required" },
  tinLabel: { id: "crs.tin.label" },
  tinRequired: { id: "crs.tin.required" },
  tinValidation: { id: "crs.tin.validation" },
  min18: { id: "crs.tin.min18" },
});

export const countrySelectMessages = defineMessages({
  residenceCountryLabel: { id: "crs.residence_country.header" },
  residenceCountryPlaceholder: { id: "crs.residence_country.placeholder" },
  residenceCountryRequired: { id: "crs.residence_country.required" },
});
