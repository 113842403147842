import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import { Spinner } from "@lysaab/ui-2";
import { LysaCountry } from "@lysaab/shared";
import { LocalizationContext } from "../../state/LocalizationContext";
import { SignupId } from "@lysaab/lysa-onfido/data/signup";
import {
  OnfidoProcessPage,
  getOnfidoStatus,
  StatusResponsePoa,
  Steps,
} from "@lysaab/lysa-onfido";
import { initiate, confirmPoa } from "../../data/onfido";
import { SignupContext } from "../../state/SignupContext";
import {
  EventTracker,
  TrackerEvent,
} from "../../utils/eventTracker/EventTracker";
import { OnfidoEmailError } from "./OnfidoEmailError";

interface Props {
  signupId: SignupId;
  next: () => void;
  restart: () => void;
  goToId: () => void;
  goToStatus: () => void;
  onUserError: () => void;
  onFatalError: () => void;
  country: LysaCountry;
  // TODO: Enable line below again
  // allowedDocuments?: DocumentType[];
  allowedDocuments?: any[];
}

enum ProcessAction {
  WAIT,
  CONTINUE,
  SHOW_EMAIL_INFO,
}

const onfidoSteps: Steps = ["poa", "complete"];

export const OnfidoProcessPoa: React.VFC<Props> = ({
  signupId,
  next,
  restart,
  goToId,
  goToStatus,
  onUserError,
  onFatalError,
  allowedDocuments,
}) => {
  const signupContext = useContext(SignupContext);
  const [status, setStatus] = useState<ProcessAction>(
    signupContext.state.onfidoError
      ? ProcessAction.CONTINUE
      : ProcessAction.WAIT
  );
  const localizationContext = useContext(LocalizationContext);
  const gettingStatus = useRef(false);

  const initiateWithSignupId = useCallback(() => {
    EventTracker.track({ event: TrackerEvent.ONFIDO_INIT });
    return initiate(signupId);
  }, [signupId]);

  const onConfirmCb = useCallback(
    (data: any) => {
      return confirmPoa(signupId, data.poa.id);
    },
    [signupId]
  );

  const onfidoError = signupContext.state.onfidoError;

  const trackOnfidoEvent = (event: any) => {
    EventTracker.track({ event: TrackerEvent.ONFIDO_EVENT, message: event });
  };

  useEffect(() => {
    if (onfidoError || gettingStatus.current) {
      // If there's a fatal error, we don't want to run this check again
      setStatus(ProcessAction.CONTINUE);
      return;
    }

    if (!signupId) {
      onFatalError();
      return;
    }

    gettingStatus.current = true;
    getOnfidoStatus(signupId)
      .then((response) => {
        console.log("OnfidoProcessPoa response", response);

        if (!response) {
          // Something's broken. Abort!
          onFatalError();
          return;
        }

        const idStatus = response.find((resp) => resp.checkType === "ID");

        if (!idStatus) {
          // Have to do the ID step first (it really should have already been
          // completed by the user, so this should never happen)
          goToId();
          return;
        }

        const poaStatuses: StatusResponsePoa[] = response.filter(
          (response): response is StatusResponsePoa =>
            response.checkType === "POA_UTILITY_BILL" ||
            response.checkType === "POA_ID_CARD"
        );

        let poaStatus = poaStatuses[0];

        if (poaStatuses.length === 2) {
          poaStatus =
            poaStatuses.find(
              (status) =>
                status.status === "ONFIDO_PENDING" ||
                status.status === "ONFIDO_PENDING_MANUAL_REVIEW"
            ) || poaStatus;
        }

        if (
          // Haven't started the PoA check yet
          poaStatus === undefined ||
          // Rejected - need to redo
          poaStatus.status === "LYSA_MANUAL_REVIEW_REJECTED" ||
          poaStatus.status === "LYSA_CANCELED" ||
          poaStatus.status === "ONFIDO_FAILED_STARTED" ||
          poaStatus.status === "ONFIDO_FAILED_USER_ERROR" ||
          poaStatus.status === "ONFIDO_CONSIDER"
        ) {
          setStatus(ProcessAction.CONTINUE);
          return;
        }

        if (
          // The poa documents are being reviewed
          poaStatus.status === "LYSA_MANUAL_REVIEW" ||
          poaStatus.status === "ONFIDO_PENDING" ||
          poaStatus.status === "ONFIDO_PENDING_MANUAL_REVIEW"
        ) {
          goToStatus();
          return;
        }

        if (
          poaStatus.status === "LYSA_MANUAL_REVIEW_CLEAR" ||
          poaStatus.status === "ONFIDO_CLEAR"
        ) {
          // Already done with the PoA step
          next();
          return;
        }

        // Nothing to do but to start all over again
        onFatalError();
      })
      .catch(() => {
        // Nothing to do but to start all over again
        onFatalError();
      })
      .finally(() => {
        gettingStatus.current = false;
      });
  }, [signupId, onFatalError, onfidoError, goToId, goToStatus, next]);

  if (status === ProcessAction.WAIT) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  if (status === ProcessAction.CONTINUE) {
    return (
      <OnfidoProcessPage
        next={next}
        restart={restart}
        onUserError={onUserError}
        onFatalError={onFatalError}
        country={localizationContext.state.country || LysaCountry.GERMANY}
        locale={localizationContext.state.language}
        confirm={onConfirmCb}
        steps={onfidoSteps}
        allowedDocuments={allowedDocuments}
        getToken={initiateWithSignupId}
        signupId={signupId}
        track={trackOnfidoEvent}
      />
    );
  }

  // TODO: Does this ever happen?
  // status is SHOW_EMAIL_INFO
  return <OnfidoEmailError />;
};
