import { SignupId } from "@lysaab/lysa-onfido/data/signup";
import { trackerAPI } from "../../data/tracker";
import { CustomEventTrack } from "./CustomEventTracks";
import {
  EventTrack as NetsEventTrack,
  TrackerEvent as NetsTrackerEvent,
} from "@lysaab/lysa-nets";

const DEBOUNCE_INTERVAL = 500;

// If you add a new event here, you probably also need to add new types in CustomEventTracks.ts
export enum TrackerEvent {
  USER_AGENT = "USER_AGENT",
  EXPERIENCE = "EXPERIENCE",
  SITUATION = "SITUATION",
  RISK = "RISK",
  BACK_BUTTON = "BACK_BUTTON",
  DOWNLOAD = "DOWNLOAD",
  INVESTMENT_TYPE = "INVESTMENT_TYPE",
  UNLOAD_WARNING = "UNLOAD_WARNING",
  CONFIRM = "CONFIRM",
  SUBMITTED = "SUBMITTED",
  BANKID_INIT = "BANKID_INIT",
  BANKID_RESULT = "BANKID_RESULT",
  ERROR = "ERROR",
  ADVICE = "ADVICE",
  NAVIGATION = "NAVIGATION",
  PRIMARY_TAX_RESIDENCE_QUESTION = "PRIMARY_TAX_RESIDENCE_QUESTION",
  SOMEONE_ELSES_MONEY_QUESTION = "SOMEONE_ELSES_MONEY_QUESTION",
  FATCA_QUESTION = "FATCA_QUESTION",
  DONE = "DONE",
  ONFIDO_INIT = "ONFIDO_INIT",
  VERIFY_EMAIL = "VERIFY_EMAIL",
  VERIFY_EMAIL_CODE = "VERIFY_EMAIL_CODE",
  ONFIDO_EVENT = "ONFIDO_EVENT",
}

export interface BaseEventTrack {
  event: TrackerEvent | NetsTrackerEvent;
}

export type EventTrack = BaseEventTrack | CustomEventTrack | NetsEventTrack;

export interface EventTrackRequest extends BaseEventTrack {
  uri: string;
  seq: number;
  message?: string;
}

const parseMessage = (message: string | object | undefined) => {
  if (message && typeof message !== "string") {
    try {
      return JSON.stringify(message);
    } catch (e) {
      return "Unknown error";
    }
  }
  return message;
};

const isCustomEventTrack = (event: EventTrack): event is CustomEventTrack => {
  return typeof (event as CustomEventTrack).message !== "undefined";
};

class EventTrackerImplementation {
  private sequence = 0;
  public signupId: SignupId | undefined = undefined;
  private eventQueue: EventTrackRequest[] = [];
  private debounceInterval: NodeJS.Timeout | undefined = undefined;

  public setSignupId = (signupId: SignupId) => {
    this.signupId = signupId;
  };

  public track = (event: EventTrack) => {
    if (this.sequence === 0) {
      this.trackUserAgent();
    }

    this.sequence++;
    this.eventQueue.push({
      event: event.event,
      message: isCustomEventTrack(event)
        ? parseMessage(event.message)
        : undefined,
      seq: this.sequence,
      uri: window.location.pathname,
    });

    if (this.debounceInterval) {
      clearInterval(this.debounceInterval);
    }

    this.debounceInterval = setTimeout(this.sendEvents, DEBOUNCE_INTERVAL);
  };

  private sendEvents = () => {
    if (this.signupId && this.eventQueue.length > 0) {
      const queue = [...this.eventQueue];
      this.eventQueue = [];
      trackerAPI.postEvents(this.signupId, queue).catch(() => {
        console.warn("Failed sending tracking events", queue);
        this.eventQueue.push(...queue);
      });
    }
  };

  private trackUserAgent = () => {
    this.eventQueue.push({
      seq: 0,
      uri: window.location.pathname,
      event: TrackerEvent.USER_AGENT,
      message: navigator.userAgent,
    });
  };
}

export const EventTracker = new EventTrackerImplementation();
