import { Button } from "@lysaab/ui-2";
import React from "react";
import { FormattedMessage } from "react-intl";

interface Props {
  onClick: () => void;
}

export const ConfirmationNextButton = ({ onClick }: Props) => {
  return (
    <Button
      block
      onClick={onClick}
      data-test-id="confirm-next-button"
      label={<FormattedMessage id="confirmation.button.next" />}
    />
  );
};
