import React from "react";
import { MessageDescriptor, defineMessages, useIntl } from "react-intl";
import { LysaCountry } from "@lysaab/shared";
import { useLocalizationContext } from "../../state/LocalizationContext";
import { DescriptionModal } from "../descriptionModal/DescriptionModal";

type CountryMessageKey = "header" | "content";

const swedenMessages = defineMessages<CountryMessageKey>({
  header: {
    id: "sweden.crsFormFields.taxableInOtherCountry.yes.activeArea.link.header",
  },
  content: {
    id: "sweden.crsFormFields.taxableInOtherCountry.yes.activeArea.link.description",
  },
});
const denmarkMessages = defineMessages<CountryMessageKey>({
  header: {
    id: "denmark.tax.modal.header",
  },
  content: {
    id: "denmark.tax.modal.content",
  },
});
const germanyMessages = defineMessages<CountryMessageKey>({
  header: {
    id: "germany.tax.modal.header",
  },
  content: {
    id: "germany.tax.modal.content",
  },
});
const finlandMessages = defineMessages<CountryMessageKey>({
  header: {
    id: "finland.tax.modal.header",
  },
  content: {
    id: "finland.tax.modal.content",
  },
});
const spainMessages = defineMessages<CountryMessageKey>({
  header: {
    id: "sweden.crsFormFields.taxableInOtherCountry.yes.activeArea.link.header",
  },
  content: {
    id: "sweden.crsFormFields.taxableInOtherCountry.yes.activeArea.link.description",
  },
});

const messages: Record<
  LysaCountry,
  Record<CountryMessageKey, MessageDescriptor>
> = {
  [LysaCountry.SWEDEN]: swedenMessages,
  [LysaCountry.DENMARK]: denmarkMessages,
  [LysaCountry.GERMANY]: germanyMessages,
  [LysaCountry.FINLAND]: finlandMessages,
  [LysaCountry.SPAIN]: spainMessages,
};

interface Props {
  children: React.ReactNode;
}

export const TaxLink: React.FC<Props> = ({ children }) => {
  const intl = useIntl();
  const { state } = useLocalizationContext();

  if (typeof state.country === "undefined") {
    return null;
  }

  return (
    <DescriptionModal
      header={intl.formatMessage(messages[state.country].header)}
      content={intl.formatMessage(messages[state.country].content, {
        bold: (parts: (string | React.ReactNode)[]): React.ReactNode => (
          <b>{parts}</b>
        ),
        break: (parts: (string | React.ReactNode)[]): React.ReactNode => (
          <>
            {parts}
            <br />
            <br />
          </>
        ),
        newLine: (parts: (string | React.ReactNode)[]): React.ReactNode => (
          <>
            {parts}
            <br />
          </>
        ),
      })}
    >
      {children}
    </DescriptionModal>
  );
};
