import { LysaCountry } from "@lysaab/shared";
import { API, InvestmentType } from "@lysaab/ui-2";

export interface GetAllocationResponse {
  [isin: string]: number;
}

export function getAllocation(
  risk: number,
  type: InvestmentType = InvestmentType.BROAD,
  country: LysaCountry
) {
  const url = `/investments/allocations?risk=${risk}&investmentType=${type}&country=${country}`;
  return API.get<GetAllocationResponse>(url);
}
