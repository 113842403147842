// Like Partial but nested
export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export enum RiskImportance {
  MAXIMIZE = 10,
  MINIMIZE = 0,
  BOTH = 5,
}

export function isRiskImportance(value: unknown): value is RiskImportance {
  if (typeof value !== "number") {
    return false;
  }

  return Object.values(RiskImportance).some(
    (k) => typeof k === "number" && k === value
  );
}

export enum RiskReaction {
  SELL = 3,
  KEEP = 7,
  BUY = 10,
}

export function isRiskReaction(value: unknown): value is RiskReaction {
  if (typeof value !== "number") {
    return false;
  }

  return Object.values(RiskReaction).some(
    (k) => typeof k === "number" && k === value
  );
}

export enum RiskPropensity {
  BAD = 10,
  PRETTY_BAD = 8,
  GOOD = 6,
  PRETTY_GOOD = 4,
  TOO_RISKY = 2,
}

export function isRiskPropensity(value: unknown): value is RiskPropensity {
  if (typeof value !== "number") {
    return false;
  }

  return Object.values(RiskPropensity).some(
    (k) => typeof k === "number" && k === value
  );
}

export interface EligibilityFinancials {
  monthlyEarnings: number;
  monthlyPayments: number;
  liquidAssets: number;
  otherAssets: number;
  debts: number;
}

export function isValidEligibilityFinancials(
  data?: unknown
): data is EligibilityFinancials {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (
    !("monthlyEarnings" in data) ||
    typeof data.monthlyEarnings !== "number"
  ) {
    return false;
  }

  if (
    !("monthlyPayments" in data) ||
    typeof data.monthlyPayments !== "number"
  ) {
    return false;
  }

  if (!("liquidAssets" in data) || typeof data.liquidAssets !== "number") {
    return false;
  }

  if (!("otherAssets" in data) || typeof data.otherAssets !== "number") {
    return false;
  }

  if (!("debts" in data) || typeof data.debts !== "number") {
    return false;
  }

  return true;
}

export function getEligibilityFinancials(data: unknown): EligibilityFinancials {
  if (isValidEligibilityFinancials(data)) {
    return {
      debts: data.debts,
      liquidAssets: data.liquidAssets,
      monthlyEarnings: data.monthlyEarnings,
      monthlyPayments: data.monthlyPayments,
      otherAssets: data.otherAssets,
    };
  }

  throw new Error(
    "isValidEligibilityFinancials - EligibilityFinancials object"
  );
}

export function isEligibilityFinancialsUpdated(
  data: Partial<EligibilityFinancials>,
  oldData: Partial<EligibilityFinancials>
): boolean {
  const [value, oldValue] = [data, oldData].map((data) => {
    return getEligibilityFinancials(data);
  });

  return JSON.stringify(value) !== JSON.stringify(oldValue);
}

export enum EligibilityRiskAnswer {
  IMPORTANCE = "MOST_IMPORTANT",
  PROPENSITY = "EXPECTATION_SCENARIO",
  REACTION = "BIG_LOSS",
}

export interface EligibilityRiskAnswers {
  [EligibilityRiskAnswer.IMPORTANCE]: RiskImportance;
  [EligibilityRiskAnswer.REACTION]: RiskReaction;
  [EligibilityRiskAnswer.PROPENSITY]: RiskPropensity;
}

export function isValidEligibilityRiskAnswers(
  data?: unknown
): data is EligibilityRiskAnswers {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (
    !(EligibilityRiskAnswer.IMPORTANCE in data) ||
    !isRiskImportance(data[EligibilityRiskAnswer.IMPORTANCE])
  ) {
    return false;
  }

  if (
    !(EligibilityRiskAnswer.REACTION in data) ||
    !isRiskReaction(data[EligibilityRiskAnswer.REACTION])
  ) {
    return false;
  }

  if (
    !(EligibilityRiskAnswer.PROPENSITY in data) ||
    !isRiskPropensity(data[EligibilityRiskAnswer.PROPENSITY])
  ) {
    return false;
  }

  return true;
}

export function isEligibilityRiskAnswersUpdated(
  data: Partial<EligibilityRiskAnswers>,
  oldData: Partial<EligibilityRiskAnswers>
): boolean {
  const [value, oldValue] = [data, oldData].map((data) => {
    return getEligibilityRiskAnswers(data);
  });

  return JSON.stringify(value) !== JSON.stringify(oldValue);
}

export function getEligibilityRiskAnswers(
  data: unknown
): EligibilityRiskAnswers {
  if (isValidEligibilityRiskAnswers(data)) {
    return {
      [EligibilityRiskAnswer.IMPORTANCE]:
        data[EligibilityRiskAnswer.IMPORTANCE],
      [EligibilityRiskAnswer.REACTION]: data[EligibilityRiskAnswer.REACTION],
      [EligibilityRiskAnswer.PROPENSITY]:
        data[EligibilityRiskAnswer.PROPENSITY],
    };
  }

  throw new Error("getEligibilityRisk - not EligibilityRisk object");
}

export interface EligibilityPerson {
  financial: EligibilityFinancials;
  riskAnswers: EligibilityRiskAnswers;
}

export function isEligibilityPerson(data: unknown): data is EligibilityPerson {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (
    !("riskAnswers" in data) ||
    typeof data.riskAnswers !== "object" ||
    data.riskAnswers === null
  ) {
    return false;
  }

  if (
    !("financial" in data) ||
    typeof data.financial !== "object" ||
    data.financial === null
  ) {
    return false;
  }

  return true;
}

export function isValidEligibilityPerson(
  data?: unknown
): data is EligibilityPerson {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (!isEligibilityPerson(data)) {
    return false;
  }

  if (!isValidEligibilityFinancials(data.financial)) {
    return false;
  }

  if (!isValidEligibilityRiskAnswers(data.riskAnswers)) {
    return false;
  }

  return true;
}
