import { defineMessages, useIntl } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import { LysaCountry } from "@lysaab/shared";
import { FlagIcon } from "@lysaab/ui-2";
import "./CountrySelectItem.scss";

const messages = defineMessages<LysaCountry>({
  [LysaCountry.SWEDEN]: {
    id: "country-select.sweden",
  },
  [LysaCountry.DENMARK]: {
    id: "country-select.denmark",
  },
  [LysaCountry.FINLAND]: {
    id: "country-select.finland",
  },
  [LysaCountry.GERMANY]: {
    id: "country-select.germany",
  },
  [LysaCountry.SPAIN]: {
    id: "country-select.spain",
  },
});

interface CountryProps {
  country: LysaCountry;
  url: string;
}

export function CountrySelectItem({ country, url }: CountryProps) {
  const intl = useIntl();
  const location = useLocation();

  return (
    <Link
      to={{ pathname: url, search: location.search }}
      className="country-select-item"
      data-test-id={`signup-country-${country.toLocaleLowerCase()}`}
    >
      <span className="padding">
        <span className="content">
          <span className="flag">
            <FlagIcon code={country.toLocaleLowerCase()} size={"2x"} />
          </span>
          {intl.formatMessage(messages[country])}
        </span>
      </span>
    </Link>
  );
}
