import { useContext } from "react";
import { DocModalLink } from "@lysaab/ui-2";
import { LysaCountry } from "@lysaab/shared";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";
import { LocalizationContext } from "../state/LocalizationContext";

import "./Disclaimer.scss";

const messages = defineMessages({
  announcement: {
    id: "advice.disclaimer.modal.announcement",
  },
});

const docList: Record<LysaCountry, string> = {
  [LysaCountry.SWEDEN]: "legal/se/sv/modellantaganden.md",
  [LysaCountry.DENMARK]: "legal/all/en/model-assumptions.md",
  [LysaCountry.FINLAND]: "legal/all/en/model-assumptions.md",
  [LysaCountry.GERMANY]: "legal/de/de/model-assumptions.md",
  [LysaCountry.SPAIN]: "legal/all/en/model-assumptions.md",
};

export const Disclaimer = () => {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);
  const country = localizationContext.state.country;

  if (!country) {
    return null;
  }

  return (
    <p className="disclaimer">
      <FormattedMessage
        id="advice.disclaimer.text"
        values={{
          a: (linkText: React.ReactNode[]) => {
            return (
              <DocModalLink
                document={docList[country]}
                modalAnnouncement={intl.formatMessage(messages.announcement)}
                key="element-key"
              >
                {linkText}
              </DocModalLink>
            );
          },
        }}
      />
    </p>
  );
};
