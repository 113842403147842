import React, { useRef } from "react";
import { Form, LysaFormRef } from "@lysaab/ui-2";
import { DepositsQuestion } from "./questions/DepositsQuestion";
import { BankQuestion } from "./questions/BankQuestion";
import { EmploymentQuestion } from "../../../pages/kyc/EmploymentQuestion";
import { Header } from "../../../pages/kyc/Header";
import { MoneyOriginQuestion } from "../../../pages/kyc/MoneyOriginQuestion";
import { PurposeQuestion } from "../../../pages/kyc/PurposeQuestion";
import { DepositIntervalQuestion } from "../../../pages/kyc/DepositIntervalQuestion";
import { WithdrawalIntervalQuestion } from "../../../pages/kyc/WithdrawalIntervalQuestion";
import { NextButton } from "../../../pages/kyc/NextButton";

export const ROUTE = "/kyc";

interface Props {
  next: () => void;
}

export const Kyc: React.FC<Props> = ({ next }) => {
  const formRef = useRef<LysaFormRef>();

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={(event) => {
        event.preventDefault();

        if (formRef.current?.isValid) {
          next();
        }
      }}
    >
      <div>
        <Header />
        <section>
          <EmploymentQuestion />
          <DepositsQuestion />
          <MoneyOriginQuestion />
          <BankQuestion />
          <PurposeQuestion />
          <DepositIntervalQuestion />
          <WithdrawalIntervalQuestion />
        </section>
        <NextButton />
      </div>
    </Form>
  );
};
