import React, { useContext, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { Card, LysaFormRef, Form, WorldCountry, Button } from "@lysaab/ui-2";
import { CrsAddress } from "../../../pages/crs/CrsAddress";
import { CrsCountrySelect } from "../../../pages/crs/CrsCountrySelect";
import { CrsName } from "../../../pages/crs/CrsName";
import { SignupContext } from "../../../state/SignupContext";
import { LocalizationContext } from "../../../state/LocalizationContext";
import { CrsReportingCountriesNets } from "../../../pages/crs/CrsReportingCountriesNets";
import { crsMessages } from "../../../pages/crs/CrsMessages";
import { CrsReportingCountryValidation } from "../../../pages/crs/CrsReportingCountryValidation";

import "./Crs.scss";

interface Props {
  next: () => void;
}

export const Crs: React.FC<Props> = ({ next }) => {
  const signupContext = useContext(SignupContext);
  const localizationContext = useContext(LocalizationContext);
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();

  useEffect(() => {
    if (
      !signupContext.state.crsReportingCountries &&
      localizationContext.state.country
    ) {
      signupContext.setState({
        crsReportingCountries: [
          {
            tin: "",
            country:
              localizationContext.state.country.toString() as WorldCountry,
          },
        ],
      });
    }
  }, [signupContext, localizationContext.state.country]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (formRef.current?.isValid && signupContext.state.crsReportingCountries) {
      next();
    }
  };

  return (
    <div className="crs-dk">
      <Form lysaFormRef={formRef} onSubmit={onSubmit}>
        <h1>{intl.formatMessage(crsMessages.header)}</h1>

        <Card>
          <CrsReportingCountriesNets />
          <CrsReportingCountryValidation />
          <CrsCountrySelect />
          <CrsName />
          <CrsAddress />
        </Card>

        <p>{intl.formatMessage(crsMessages.buttonNextConfirmText)}</p>
        <Button
          type="submit"
          block
          label={intl.formatMessage(crsMessages.buttonNext)}
        />
      </Form>
    </div>
  );
};
