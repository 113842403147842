const MINIMUM_MONTHS_COVERAGE = 6;

export enum SituationValidationResult {
  INSUFFICIENT_BUFFER = "INSUFFICIENT_BUFFER",
  INSUFFICIENT_MEANS = "INSUFFICIENT_MEANS",
  RUNNING_OUT_OF_MEANS = "RUNNING_OUT_OF_MEANS",
  NO_INCOME = "NO_INCOME",
  VALID = "VALID",
}

export default class SituationUtils {
  static validate(
    liquidAssets: number,
    monthlyPayments: number,
    otherAssets: number,
    debts: number,
    monthlyEarnings: number
  ): SituationValidationResult {
    if (monthlyEarnings < 1) {
      return SituationValidationResult.NO_INCOME;
    }

    if (!this.hasSufficientBuffer(liquidAssets, monthlyPayments)) {
      return SituationValidationResult.INSUFFICIENT_BUFFER;
    }

    if (
      !this.hasSufficientMeans(
        liquidAssets,
        otherAssets,
        debts,
        monthlyEarnings
      )
    ) {
      return SituationValidationResult.INSUFFICIENT_MEANS;
    }

    if (
      this.isRunningOutOfMeans(liquidAssets, monthlyEarnings, monthlyPayments)
    ) {
      return SituationValidationResult.RUNNING_OUT_OF_MEANS;
    }

    return SituationValidationResult.VALID;
  }

  static hasSufficientBuffer(liquidAssets: number, monthlyPayments: number) {
    if (liquidAssets === 0) {
      return false;
    }

    if (monthlyPayments === 0) {
      return true;
    }

    const savingsBufferFactor = liquidAssets / monthlyPayments;
    if (savingsBufferFactor >= 1) {
      return true;
    }

    return false;
  }

  static hasSufficientMeans(
    liquidAssets: number,
    otherAssets: number,
    debts: number,
    monthlyEarnings: number
  ) {
    const netWorth = liquidAssets + otherAssets - debts;

    if (netWorth >= 0) {
      return true;
    }

    const monthlySalariesToRepayDebt = Math.abs(netWorth) / monthlyEarnings;
    if (monthlySalariesToRepayDebt <= 6) {
      return true;
    }

    return false;
  }

  static isRunningOutOfMeans(
    liquidAssets: number,
    monthlyEarnings: number,
    monthlyPayments: number
  ) {
    if (monthlyEarnings > monthlyPayments) {
      return false;
    }

    const monthlyNetDebt = Math.abs(monthlyEarnings - monthlyPayments);
    const monthsBeforeMonthlyDeptOutrunsAssets = liquidAssets / monthlyNetDebt;

    if (monthsBeforeMonthlyDeptOutrunsAssets >= MINIMUM_MONTHS_COVERAGE) {
      return false;
    } else {
      return true;
    }
  }
}
