import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  VoidFunctionComponent,
} from "react";
import cx from "classnames";
import {
  Card,
  Checkbox,
  RequiredValidator,
  Typography,
  Alternative,
  Button,
  Form,
  LysaFormRef,
  Spinner,
  ExpandableSneakPeakCard,
} from "@lysaab/ui-2";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import {
  getSuitabilityAssessment,
  GetSuitabilityAssessmentResponse,
} from "../../data/Investments";
import "./ConfirmEsgUpdate.scss";
import { getSuitabilityAssessmentModel } from "../advice/Advice";
import { SignupContext } from "../../state/SignupContext";
import { LocalizationContext } from "../../state/LocalizationContext";
import { useAdvice } from "../../state/AdviceContext";
import { getTaxonomyAltText } from "../sustainabilityImportant/questions/TaxonomyText";
import { getSFDRAltText } from "../sustainabilityImportant/questions/SFDRText";
import { getPAIAltText } from "../sustainabilityImportant/questions/PAIText";
import { EsgQuestions } from "../../data/types/SustainabilityQuestions";

export const ROUTE = "/confirm-esg-update";

const messages = defineMessages({
  sfdr: {
    id: "confirm-esg-update.title.sfdr",
  },
  taxonomy: {
    id: "confirm-esg-update.title.taxonomy",
  },
  pai: {
    id: "confirm-esg-update.title.pai",
  },
  checkbox: {
    id: "confirm-esg-update.checkbox.text",
  },
  checkboxError: {
    id: "confirm-esg-update.checkbox.error",
  },
  buttonOpenText: {
    id: "confirm-esg-update.info.button.open",
  },
  buttonCloseText: {
    id: "confirm-esg-update.info.button.close",
  },
});

interface Props {
  next: () => void;
}

export const ConfirmEsgUpdate: VoidFunctionComponent<Props> = ({ next }) => {
  const intl = useIntl();
  const formRef = useRef<LysaFormRef>();
  const signupContext = useContext(SignupContext);
  const advice = useAdvice();
  const localizationContext = useContext(LocalizationContext);
  const [accept, setAccept] = useState<boolean>(false);
  const [advise, setAdvise] = useState<GetSuitabilityAssessmentResponse>();

  useEffect(() => {
    const requestData = getSuitabilityAssessmentModel(
      signupContext,
      localizationContext,
      advice.input
    );

    if (requestData) {
      getSuitabilityAssessment(requestData)
        .then(setAdvise)
        .catch(() => {
          throw new Error("Advice - failed getSuitabilityAssessment");
        });
    } else {
      console.log("Advice - failed getSuitabilityAssessmentModel");
    }
  }, [localizationContext, signupContext, advice.input]);

  if (!advise?.esgResult) {
    return <Spinner />;
  }

  return (
    <Form
      lysaFormRef={formRef}
      className="confirm-esg-update"
      onSubmit={() => {
        if (formRef.current?.isValid) {
          next();
        }
      }}
    >
      <Typography type="h1">
        <FormattedMessage id="confirm-esg-update.title" />
      </Typography>
      <Typography type="body" className="confirm-esg-update--body">
        <FormattedMessage id="confirm-esg-update.body" />
      </Typography>
      <SustainabilityAnswers
        esg={advise.esgResult.esgAnswers}
        updatedEsg={advise.esgResult.esgBestMatch!}
      />
      <Checkbox<boolean>
        data-test-id-partial="accept-esg-preferences"
        checked={accept}
        alternative={
          {
            text: intl.formatMessage(messages.checkbox),
            value: true,
          } as Alternative<boolean>
        }
        onChange={() => setAccept(!accept)}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.checkboxError)),
        ]}
      />
      <Button
        block
        type="submit"
        data-test-id="confirmEsgUpdate-next-button"
        label={<FormattedMessage id="confirm-esg-update.button.text" />}
      />
      <ExpandableSneakPeakCard
        className="confirm-esg-update--info"
        buttonOpenText={intl.formatMessage(messages.buttonOpenText)}
        buttonCloseText={intl.formatMessage(messages.buttonCloseText)}
        fadeVisibleContent
      >
        <Typography type="h3">
          <FormattedMessage id="confirm-esg-update.info.title" />
        </Typography>
        <Typography type="body">
          <FormattedMessage
            id="confirm-esg-update.info.body"
            values={{
              bold: (parts: React.ReactNode[]) => <b>{parts}</b>,
              break: () => (
                <>
                  <br />
                  <br />
                </>
              ),
            }}
          />
        </Typography>
      </ExpandableSneakPeakCard>
    </Form>
  );
};

const SustainabilityAnswers: VoidFunctionComponent<{
  esg: EsgQuestions;
  updatedEsg: EsgQuestions;
}> = ({ esg, updatedEsg }) => {
  const intl = useIntl();

  return (
    <Card>
      <Typography type="h5">
        <FormattedMessage id="confirm-esg-update.answers.title" />
      </Typography>
      <div className="confirm-esg-update--grid-container">
        <span>
          <FormattedMessage {...messages.sfdr} />
        </span>
        <span
          className={cx({
            "confirm-esg-update--overridden": esg.sfdr !== updatedEsg.sfdr,
          })}
        >
          <Typography type="label">{getSFDRAltText(intl, esg.sfdr)}</Typography>
        </span>

        <span>
          <FormattedMessage {...messages.taxonomy} />
        </span>
        <span
          className={cx({
            "confirm-esg-update--overridden":
              esg.taxonomy !== updatedEsg.taxonomy,
          })}
        >
          <Typography type="label">
            {getTaxonomyAltText(intl, esg.taxonomy)}
          </Typography>
        </span>

        <span>
          <FormattedMessage {...messages.pai} />
        </span>
        <span
          className={cx({
            "confirm-esg-update--overridden": esg.pai !== updatedEsg.pai,
          })}
        >
          <Typography type="label">{getPAIAltText(intl, esg.pai)}</Typography>
        </span>
      </div>

      <hr className="confirm-esg-update--card-divider" />

      <Typography type="h5">
        <FormattedMessage id="confirm-esg-update.changedAnswers.title" />
      </Typography>
      <div className="confirm-esg-update--grid-container">
        <span>
          <FormattedMessage {...messages.sfdr} />
        </span>
        <span>
          <Typography type="label">
            {getSFDRAltText(intl, updatedEsg.sfdr)}
          </Typography>
        </span>

        <span>
          <FormattedMessage {...messages.taxonomy} />
        </span>
        <span>
          <Typography type="label">
            {getTaxonomyAltText(intl, updatedEsg.taxonomy)}
          </Typography>
        </span>

        <span>
          <FormattedMessage {...messages.pai} />
        </span>
        <span>
          <Typography type="label">
            {getPAIAltText(intl, updatedEsg.pai)}
          </Typography>
        </span>
      </div>
    </Card>
  );
};
