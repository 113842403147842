import React, {
  useContext,
  useEffect,
  useCallback,
  FunctionComponent,
} from "react";
import "./CookieConsent.scss";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { LocalStorage } from "../../data/localStorage";
import { Button } from "@lysaab/ui-2/components/buttons/Button";
import { LocalizationContext } from "../../state/LocalizationContext";
import { AnimateHeight, DocModalLink } from "@lysaab/ui-2";
import { LysaCountry } from "@lysaab/shared";
import { CookieConsentContext } from "./CookieConsentContext";

const messages = defineMessages({
  modalAnnouncement: {
    id: "cookieConsent.modalAnnouncement",
  },
});

const cookieDocuments: Record<LysaCountry, string> = {
  [LysaCountry.SWEDEN]: "legal/se/sv/kakor-1.md",
  [LysaCountry.DENMARK]: "legal/all/en/cookies.md",
  [LysaCountry.FINLAND]: "legal/all/en/cookies.md",
  [LysaCountry.GERMANY]: "legal/de/de/cookies.md",
  [LysaCountry.SPAIN]: "legal/all/en/cookies.md",
};

const CookieLinkButton: FunctionComponent<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = (props) => {
  return <button type="button" className="cookie-link-button" {...props} />;
};

export const CookieConsent: React.FC<{}> = () => {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);
  const { setState, state } = useContext(CookieConsentContext);

  useEffect(() => {
    if (
      LocalStorage.getItem(LocalStorage.Key.STORE_SETTINGS_COOKIE_BANNER_OK)
    ) {
      return;
    }

    setState({
      showCookieConsent: true,
    });
  }, [setState]);

  const bannerOk = useCallback(() => {
    LocalStorage.setItem(
      LocalStorage.Key.STORE_SETTINGS_COOKIE_BANNER_OK,
      "true"
    );
    setState({ showCookieConsent: false });
  }, [setState]);

  if (!localizationContext.state.country) {
    return null;
  }

  return (
    <div className="baked-dough-consent">
      <AnimateHeight isOpen={state.showCookieConsent}>
        <article>
          <p>
            <FormattedMessage
              id="cookieConsent.paragraph"
              values={{
                link: (
                  <DocModalLink
                    document={
                      cookieDocuments[localizationContext.state.country]
                    }
                    modalAnnouncement={intl.formatMessage(
                      messages.modalAnnouncement
                    )}
                    component={CookieLinkButton}
                  >
                    <FormattedMessage id="cookieConsent.link" />
                  </DocModalLink>
                ),
              }}
            />
          </p>
          <Button
            size="small"
            onClick={bannerOk}
            data-test-id="accept-cookies-button"
            label={<FormattedMessage id="cookieConsent.button.accept" />}
          />
        </article>
      </AnimateHeight>
    </div>
  );
};
