import { defineMessages } from "react-intl";
import { LysaCountry } from "@lysaab/shared";

export const ContactEmailAddresses = defineMessages<LysaCountry>({
  [LysaCountry.SWEDEN]: { id: "contact.email.se" },
  [LysaCountry.DENMARK]: { id: "contact.email.dk" },
  [LysaCountry.FINLAND]: { id: "contact.email.fi" },
  [LysaCountry.GERMANY]: { id: "contact.email.de" },
  [LysaCountry.SPAIN]: { id: "contact.email.es" },
});

export const ContactPhoneNumbers = defineMessages<LysaCountry>({
  [LysaCountry.SWEDEN]: { id: "contact.phone.se" },
  [LysaCountry.DENMARK]: { id: "contact.phone.dk" },
  [LysaCountry.FINLAND]: { id: "contact.phone.fi" },
  [LysaCountry.GERMANY]: { id: "contact.phone.de" },
  [LysaCountry.SPAIN]: { id: "contact.phone.es" },
});
