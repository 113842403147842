import React from "react";
import { useIntl } from "react-intl";
import { messages } from "./InvestmentBasisText";
import { Typography } from "@lysaab/ui-2";
import { AccountQuestions } from "../../../data/types/AccountQuestions";

interface Props {
  accountQuestions: AccountQuestions;
}

export const Horizon: React.FC<Props> = ({ accountQuestions }) => {
  const intl = useIntl();

  return (
    <div>
      <Typography type="h2">
        {intl.formatMessage(messages.suitabilityHorizonHeader)}
      </Typography>
      <Typography>
        {intl.formatMessage(messages.suitabilityHorizonText, {
          length: intl.formatMessage(
            messages[
              `suitabilityHorizonLength${accountQuestions.savingsHorizon}`
            ]
          ),
          probability: intl.formatMessage(
            messages[
              `suitabilityHorizonProbability${accountQuestions.needEarlier}`
            ]
          ),
        })}
      </Typography>
    </div>
  );
};
