import React, { useContext } from "react";
import {
  MaxLengthValidator,
  RequiredValidator,
  FirstNameInput,
  LastNameInput,
} from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { SignupContext } from "../../state/SignupContext";

const messages = defineMessages({
  firstNameLabel: { id: "crs.first_name.label" },
  firstNameRequired: { id: "crs.first_name.required" },
  firstNameLength: { id: "crs.first_name.length" },
  lastNameLabel: { id: "crs.last_name.label" },
  lastNameRequired: { id: "crs.last_name.required" },
  lastNameLength: { id: "crs.last_name.length" },
});

export const CrsName: React.VFC = () => {
  const intl = useIntl();
  const signupContext = useContext(SignupContext);

  return (
    <>
      <FirstNameInput
        label={intl.formatMessage(messages.firstNameLabel)}
        value={signupContext.state.crsFirstName || ""}
        onChange={(value) => signupContext.setState({ crsFirstName: value })}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.firstNameRequired)),
          new MaxLengthValidator(
            200,
            intl.formatMessage(messages.firstNameLength)
          ),
        ]}
      />
      <LastNameInput
        label={intl.formatMessage(messages.lastNameLabel)}
        value={signupContext.state.crsLastName || ""}
        onChange={(value) => signupContext.setState({ crsLastName: value })}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.lastNameRequired)),
          new MaxLengthValidator(
            200,
            intl.formatMessage(messages.lastNameLength)
          ),
        ]}
      />
    </>
  );
};
