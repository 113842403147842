import {
  BaseValidator,
  Button,
  RequiredValidator,
  TextInput,
  ValidationResponse,
  VALIDATION_STATE,
} from "@lysaab/ui-2";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import "./IntroSubmitButton.scss";

export class InviteCodeValidator extends BaseValidator {
  protected name = "InviteCodeValidator";

  getId() {
    return this.getBaseId();
  }

  validate(value: string): Promise<ValidationResponse> {
    if (value.length === 0) {
      return Promise.resolve({
        status: VALIDATION_STATE.SUCCESS,
        hideError: true,
      });
    }

    return new Promise<ValidationResponse>((resolve) => {
      if (value === "lysa2021launch") {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
          hideError: true,
        });
      }
    });
  }
}

export const IntroSubmitButton: React.VFC = () => {
  const [inviteCode, setInviteCode] = useState("");
  const intl = useIntl();

  return (
    <>
      <TextInput
        label={intl.formatMessage({ id: "spain.intro.invite-code.label" })}
        value={inviteCode}
        onChange={setInviteCode}
        validators={[
          new RequiredValidator(
            intl.formatMessage({ id: "spain.intro.invite-code.required" })
          ),
          new InviteCodeValidator(
            intl.formatMessage({ id: "spain.intro.invite-code.invalid" })
          ),
        ]}
      />

      <Button
        block
        type="submit"
        className="spain-intro-submit-button"
        label={<FormattedMessage id="spain.intro.get-started" />}
      />
    </>
  );
};
