import React, { FunctionComponent, useContext } from "react";
import { FormattedNumber } from "react-intl";
import { LocalizationContext } from "../state/LocalizationContext";

interface Props {
  number: number;
  minDecimals?: number;
  maxDecimals?: number;
}

export const Currency: FunctionComponent<Props> = ({
  number,
  minDecimals = 0,
  maxDecimals = 0,
}) => {
  const localizationContext = useContext(LocalizationContext);
  return (
    <FormattedNumber
      // eslint-disable-next-line react/style-prop-object
      style="currency"
      value={number}
      currency={localizationContext.state.currency}
      minimumFractionDigits={minDecimals}
      maximumFractionDigits={maxDecimals}
    />
  );
};
