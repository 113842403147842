import React, { useContext, FunctionComponent, useEffect } from "react";
import { SignupContext } from "../state/SignupContext";
import { Route, RouteProps, useHistory } from "react-router-dom";
import { useCountryUrl } from "../hooks/useCountryUrl";

interface Props {
  children: React.ReactNode;
}

export const DirectAccessGuard: FunctionComponent<Props> = ({ children }) => {
  const signupContext = useContext(SignupContext);
  const history = useHistory();
  const countryUrl = useCountryUrl();

  useEffect(() => {
    if (typeof signupContext.state.email === "undefined") {
      history.push(countryUrl);
    }
  }, [countryUrl, history, signupContext.state.email]);

  return <React.Fragment>{children}</React.Fragment>;
};

export const NoDirectAccessRoute: FunctionComponent<
  RouteProps & { children: React.ReactNode }
> = ({ children, ...props }) => (
  <Route {...props}>
    <DirectAccessGuard>{children}</DirectAccessGuard>
  </Route>
);
