import { defineMessages, IntlShape } from "react-intl";
import { SFDRImportance } from "../../../data/types/SustainabilityQuestions";

const sfdrQuestionMessages = defineMessages({
  question: { id: "esg.sfdr.header" },
});

const sfdrAltMessages = defineMessages<SFDRImportance>({
  [SFDRImportance.NONE]: {
    id: "esg.sfdr.alt.NONE",
  },
  [SFDRImportance.LEAST_25]: {
    id: "esg.sfdr.alt.LEAST_25",
  },
  [SFDRImportance.LEAST_50]: {
    id: "esg.sfdr.alt.LEAST_50",
  },
  [SFDRImportance.LEAST_75]: {
    id: "esg.sfdr.alt.LEAST_75",
  },
});

export function getSFDRQuestionText(intl: IntlShape) {
  return intl.formatMessage(sfdrQuestionMessages.question);
}

export function getSFDRAltText(intl: IntlShape, key: SFDRImportance) {
  return intl.formatMessage(sfdrAltMessages[key]);
}
