import { BrowserRouter } from "react-router-dom";
import { Localization } from "./Localization";
import { LocalizationContextProvider } from "./state/LocalizationContext";
import { AccessabilityTabber } from "@lysaab/ui-2";
import { FlashOverlay, Refocus, ScrollToTop } from "@lysaab/ui-2";
import { PepContextProvider } from "@lysaab/lysa-pep";
import { OnfidoContextProvider } from "@lysaab/lysa-onfido";
import { CookieConsent } from "./components/cookieConsent/CookieConsent";
import { TopLevelRoutes } from "./TopLevelRoutes";
import "./App.scss";
import { AdviceContextProvider } from "./state/AdviceContext";
import { CookieConsentContextProvider } from "./components/cookieConsent/CookieConsentContext";

const App = () => {
  return (
    <AdviceContextProvider>
      <LocalizationContextProvider>
        <CookieConsentContextProvider>
          <BrowserRouter>
            <AccessabilityTabber />
            <Localization>
              <CookieConsent />
              <FlashOverlay>
                <Refocus />
                <ScrollToTop />
                <OnfidoContextProvider>
                  <PepContextProvider>
                    <div className="lysa-signup">
                      <TopLevelRoutes />
                    </div>
                  </PepContextProvider>
                </OnfidoContextProvider>
              </FlashOverlay>
            </Localization>
          </BrowserRouter>
        </CookieConsentContextProvider>
      </LocalizationContextProvider>
    </AdviceContextProvider>
  );
};

export default App;
