import {
  MaxLengthValidator,
  RequiredValidator,
  StreetAddressInput,
  PostalCodeInput,
  CityInput,
} from "@lysaab/ui-2";
import { useContext } from "react";
import { useIntl } from "react-intl";
import { addressMessages } from "../../../pages/crs/CrsMessages";
import { SignupContext } from "../../../state/SignupContext";

export const CrsAddress: React.VFC = () => {
  const intl = useIntl();
  const signupContext = useContext(SignupContext);

  return (
    <>
      <StreetAddressInput
        label={intl.formatMessage(addressMessages.streetLabel)}
        value={signupContext.state.crsStreet || ""}
        onChange={(value) => signupContext.setState({ crsStreet: value })}
        validators={[
          new RequiredValidator(
            intl.formatMessage(addressMessages.streetRequired)
          ),
          new MaxLengthValidator(
            300,
            intl.formatMessage(addressMessages.streetLength)
          ),
        ]}
      />
      <PostalCodeInput
        label={intl.formatMessage(addressMessages.postCodeLabel)}
        value={signupContext.state.crsPostCode || ""}
        onChange={(value) => signupContext.setState({ crsPostCode: value })}
        validators={[
          new RequiredValidator(
            intl.formatMessage(addressMessages.postCodeRequired)
          ),
        ]}
      />
      <CityInput
        label={intl.formatMessage(addressMessages.cityLabel)}
        value={signupContext.state.crsCity || ""}
        onChange={(value) => signupContext.setState({ crsCity: value })}
        validators={[
          new RequiredValidator(
            intl.formatMessage(addressMessages.cityRequired)
          ),
          new MaxLengthValidator(
            300,
            intl.formatMessage(addressMessages.cityLength)
          ),
        ]}
      />
    </>
  );
};
