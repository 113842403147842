import React, { useReducer, createContext, FunctionComponent } from "react";

export interface CookieConsentState {
  showCookieConsent: boolean;
}

export interface CookieConsentContextProps {
  state: CookieConsentState;
  setState: (newState: Partial<CookieConsentState>) => void;
}

export const CookieConsentContext = createContext<CookieConsentContextProps>(
  {} as CookieConsentContextProps
);

CookieConsentContext.displayName = "CookieConsentContext";

function stateReducer(
  state: CookieConsentState,
  newState: Partial<CookieConsentState>
) {
  return { ...state, ...newState };
}

export const CookieConsentContextProvider: FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, {
    showCookieConsent: false,
  });

  return (
    <CookieConsentContext.Provider value={{ state, setState }}>
      {children}
    </CookieConsentContext.Provider>
  );
};
