import React, {
  FunctionComponent,
  ReactNode,
  useContext,
  useEffect,
} from "react";
import { useHistory } from "react-router-dom";
import { SignupContext } from "../../state/SignupContext";

interface Props {
  children: ReactNode;
  crsRoute: string;
}

export const CrsAnswersAccessGuard: FunctionComponent<Props> = ({
  children,
  crsRoute,
}) => {
  const signupContext = useContext(SignupContext);
  const history = useHistory();
  const crsResidenceIsAlsoTaxReportingCountry =
    signupContext.state.crsReportingCountries?.some((reportingCountry) => {
      return (
        reportingCountry.country === signupContext.state.crsResidenceCountry
      );
    }) === true;

  useEffect(() => {
    if (!crsResidenceIsAlsoTaxReportingCountry) {
      history.replace(crsRoute);
    }
  }, [
    crsResidenceIsAlsoTaxReportingCountry,
    crsRoute,
    history,
    signupContext.state,
  ]);

  return <React.Fragment>{children}</React.Fragment>;
};
