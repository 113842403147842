import React from "react";
import { FormattedMessage } from "react-intl";

export const ConfirmationHeader = () => {
  return (
    <h1>
      <FormattedMessage id="confirmation.header" />
    </h1>
  );
};
