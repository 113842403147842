import "core-js";
/**
 * Intl prolyfills. These can be loaded async, but it won't work without alot of work.
 * We would have to block rendering until everything is loaded for browsers which need it
 * to prevent errors while the polyfills are loaded. We would also have to handle potential
 * network errors while loading.
 *
 * As of Chrome 87 almost all these polyfills are loaded for all browsers which also makes this
 * unnecessary
 */
import "@formatjs/intl-locale/polyfill";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en";
import "@formatjs/intl-pluralrules/locale-data/sv";
import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/en-DK";
import "@formatjs/intl-numberformat/locale-data/en-FI";
import "@formatjs/intl-numberformat/locale-data/en-SE";
import "@formatjs/intl-numberformat/locale-data/sv";
/**
 * End intl polyfills
 */

import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/browser";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import * as serviceWorker from "./serviceWorker";
import { makeCypressProxy } from "./makeCypressProxy";

if (window.Cypress) {
  makeCypressProxy();
}

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://a9a5d5ab511544a18025249e10332c48@o339122.ingest.sentry.io/5244479",
    integrations: [
      new CaptureConsoleIntegration({
        // array of methods that should be captured
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ["error"],
      }),
    ],
    maxValueLength: 15000,
    environment: process.env.NODE_ENV,
  });
}

const root = createRoot(document.getElementById("root")!);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
