import { API } from "@lysaab/ui-2";
import { Isin } from "./Investments";

export enum FundType {
  STOCKS = "STOCKS",
  BONDS = "BONDS",
}

export interface Fund {
  depotId: string;
  fundShareClasses: Instrument[];
  largePositions: LargePosition[];
  remainingPositions: SmallPosition[];
  remainingPositionsPercent: number;
  fundType: FundType;
  isin?: Isin;
}

export interface BasePosition {
  instruments: Instrument[];
}

export interface LargePosition extends BasePosition {
  percent: number;
}

export type SmallPosition = BasePosition;

export interface Instrument {
  isin: Isin;
  name: string;
}

export function getHoldings() {
  return API.get<Fund[]>("/funds/data/summary");
}
