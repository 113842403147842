import { LysaCountry } from "@lysaab/shared";
import { API } from "@lysaab/ui-2";

export function postFunnelPageview(
  stage: string,
  country: LysaCountry,
  isFromApp: boolean
) {
  return API.post("/cts/funnel", {
    funnel: isFromApp ? `Signup LysaApp ${country}` : `Signup ${country}`,
    stage: stage,
  });
}
