import React, { useContext } from "react";
import { LysaCountry } from "@lysaab/shared";
import { FormattedMessage, useIntl } from "react-intl";
import { ContactEmailAddresses, ContactPhoneNumbers } from "../Contact";
import { LocalizationContext } from "../state/LocalizationContext";
import { DocModalLink, LysaLink, StoryFooter } from "@lysaab/ui-2";

const privacyPolicyDocs = {
  [LysaCountry.SWEDEN]: "legal/se/sv/personuppgiftspolicy.md",
  [LysaCountry.DENMARK]: "legal/all/en/privacy-note.md",
  [LysaCountry.FINLAND]: "legal/all/en/privacy-note.md",
  [LysaCountry.GERMANY]: "legal/de/de/privacy-note.md",
  [LysaCountry.SPAIN]: "legal/all/en/privacy-note.md",
};

export function ContactFooter() {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);

  return (
    <StoryFooter
      contactInfoText={
        <FormattedMessage
          id="footer.contact"
          values={{
            break: () => <br />,
            phone: () => {
              const phoneNumber = intl.formatMessage(
                ContactPhoneNumbers[
                  localizationContext.state.country || LysaCountry.SWEDEN
                ]
              );
              return (
                <LysaLink href={`tel:${phoneNumber.replace(/\s+/g, "")}`}>
                  {phoneNumber}
                </LysaLink>
              );
            },
            email: () => {
              const email = intl.formatMessage(
                ContactEmailAddresses[
                  localizationContext.state.country || LysaCountry.SWEDEN
                ]
              );
              return <LysaLink href={`mailto:${email}`}>{email}</LysaLink>;
            },
          }}
        />
      }
      privacyPolicyText={
        <FormattedMessage
          id="footer.privacyPolicy"
          values={{
            a: (linkText: React.ReactNode[]) => {
              return (
                <DocModalLink
                  document={
                    privacyPolicyDocs[
                      localizationContext.state.country || LysaCountry.SWEDEN
                    ]
                  }
                  modalAnnouncement={intl.formatMessage({
                    id: "footer.modalAnnouncement",
                  })}
                  key="element-key-pp"
                >
                  {linkText}
                </DocModalLink>
              );
            },
          }}
        />
      }
    />
  );
}
