import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { NewIcon, Typography } from "@lysaab/ui-2";
import "./IntroInfo.scss";
interface ListItemProps {
  title: ReactNode;
  text?: ReactNode;
}

const ListItem: React.VFC<ListItemProps> = ({ title, text }) => (
  <div className="list-item">
    <div className="list-item-icon">
      <NewIcon.Check primaryColor="var(--lysa-text-primary)" />
    </div>
    <div>
      <Typography type="body">{title}</Typography>
      {text && (
        <Typography type="body-small" className="small-text">
          {text}
        </Typography>
      )}
    </div>
  </div>
);

export const IntroInfo: React.VFC = () => (
  <div className="intro-info-germany">
    <Typography type="h3">
      <FormattedMessage id="germany.intro.info.title" />
    </Typography>
    <Typography type="body">
      <FormattedMessage id="germany.intro.info.text" />
    </Typography>
    <div className="box">
      <Typography type="h5">
        <FormattedMessage id="germany.intro.info.alternative" /> 1
        <span className="recommended">
          <FormattedMessage id="germany.intro.info.recommended" />
        </span>
      </Typography>
      <ListItem title={<FormattedMessage id="germany.intro.info.steuerID" />} />
      <ListItem
        title={<FormattedMessage id="germany.intro.info.id" />}
        text={<FormattedMessage id="germany.intro.info.id.text" />}
      />
    </div>
    <div className="divider">
      <Typography type="body" variant="secondary">
        <span>
          <FormattedMessage id="germany.intro.info.or" />
        </span>
      </Typography>
    </div>
    <div className="box">
      <Typography type="h5">
        <FormattedMessage id="germany.intro.info.alternative" /> 2
      </Typography>
      <ListItem title={<FormattedMessage id="germany.intro.info.steuerID" />} />
      <ListItem title={<FormattedMessage id="germany.intro.info.passport" />} />
      <ListItem
        title={<FormattedMessage id="germany.intro.info.utilityBill" />}
        text={<FormattedMessage id="germany.intro.info.utilityBill.text" />}
      />
    </div>
  </div>
);
