import React, {
  useContext,
  FunctionComponent,
  ReactNode,
  useEffect,
} from "react";
import { useHistory } from "react-router-dom";
import SituationUtils, {
  SituationValidationResult,
} from "../../utils/SituationUtils";
import { SignupContext } from "../../state/SignupContext";

interface Props {
  children: ReactNode;
  situationRoute: string;
}

export const SituationAnswersAccessGuard: FunctionComponent<Props> = ({
  children,
  situationRoute,
}) => {
  const signupContext = useContext(SignupContext);
  const history = useHistory();

  useEffect(() => {
    const situationValidationResult = SituationUtils.validate(
      signupContext.state.financial.liquidAssets,
      signupContext.state.financial.monthlyPayments,
      signupContext.state.financial.otherAssets,
      signupContext.state.financial.debts,
      signupContext.state.financial.monthlyEarnings
    );
    if (situationValidationResult !== SituationValidationResult.VALID) {
      history.replace(situationRoute);
    }
  }, [history, signupContext.state, situationRoute]);

  return <React.Fragment>{children}</React.Fragment>;
};
