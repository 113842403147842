import React, { useContext, useEffect } from "react";
import { Card, Icon, Button } from "@lysaab/ui-2";
import "./RiskWarning.scss";
import { useHistory } from "react-router-dom";
import { SignupContext } from "../../state/SignupContext";
import { FormattedMessage } from "react-intl";
import { useCountryUrl } from "../../hooks/useCountryUrl";
import { useAdvice } from "../../state/AdviceContext";

export const ROUTE = "/risk-warning";

interface Props {
  next: () => void;
}

export const RiskWarning: React.FC<Props> = ({ next }) => {
  const signupContext = useContext(SignupContext);
  const history = useHistory();
  const countryUrl = useCountryUrl();
  const advice = useAdvice();

  const risk = signupContext.state.allocationSelectedRisk || 0;
  const advisedRisk = advice.result.advicedRisk || 0;

  const stocks = risk;
  const bonds = 100 - risk;
  const stocksAdviced = advisedRisk;
  const bondsAdviced = 100 - advisedRisk;

  useEffect(() => {
    if (typeof signupContext.state.allocationSelectedRisk === "undefined") {
      history.push(countryUrl);
    }
  }, [countryUrl, history, signupContext.state.allocationSelectedRisk]);

  const internalNext = () => {
    signupContext.setState({
      allocationForcedRisk: undefined,
    });
    advice.setResult({
      takenRisk: risk,
    });
    next();
  };

  const internalNextLysa = () => {
    // Need to store it in state to handle back/forward navigation
    signupContext.setState({
      allocationForcedRisk: advisedRisk,
    });
    advice.setResult({
      takenRisk: advisedRisk,
    });
    next();
  };

  return (
    <div className="risk-warning-page">
      <h1>
        <FormattedMessage id="riskwarning.headline" />
      </h1>
      <section>
        <Card>
          <Icon.Error size={48} color="#FDDE4A" />
          <p className="risk-warning-message">
            <FormattedMessage
              id="riskwarning.text"
              values={{
                stocks: stocks,
                bonds: bonds,
                stocksAdviced: stocksAdviced,
                bondsAdviced: bondsAdviced,
                b: (text: React.ReactNode[]) => <strong>{text}</strong>,
              }}
            />
          </p>
        </Card>
      </section>
      <Button
        onClick={internalNext}
        block
        label={<FormattedMessage id="riskwarning.buttons.next" />}
      />

      <Button
        variant="secondary"
        onClick={internalNextLysa}
        block
        label={<FormattedMessage id="riskwarning.buttons.lysasuggestion" />}
      />
      <Button
        variant="secondary"
        onClick={() => history.goBack()}
        block
        label={<FormattedMessage id="riskwarning.buttons.back" />}
      />
    </div>
  );
};
