import { Button } from "@lysaab/ui-2";
import React from "react";
import { FormattedMessage } from "react-intl";
import "./IntroSubmitButton.scss";

export const IntroSubmitButton: React.VFC = () => (
  <Button
    block
    type="submit"
    className="intro-submit-button"
    label={<FormattedMessage id="intro.getstarted" />}
  />
);
