import React, { useContext } from "react";
import { FormattedNumberParts } from "react-intl";
import { LocalizationContext } from "../../state/LocalizationContext";
import cx from "classnames";
import "./Amount.scss";

interface Props {
  amount: number;
  decimals?: number;
  showSign?: boolean;
}

export function Amount({ amount, decimals = 0, showSign = false }: Props) {
  const { currency } = useContext(LocalizationContext).state;

  return (
    <div className="amount">
      <span className="amount-value">
        <FormattedNumberParts
          value={amount}
          currency={currency}
          // eslint-disable-next-line react/style-prop-object
          style="currency"
          minimumFractionDigits={decimals}
          maximumFractionDigits={decimals}
          signDisplay={showSign ? "exceptZero" : "auto"}
        >
          {(parts) => (
            <span>
              {parts.map((part, idx) => {
                if (part.type === "currency") {
                  return (
                    <span
                      className={cx("amount-currency", {
                        "amount-currency__prefix":
                          idx === 0 ||
                          ((parts[0].type === "minusSign" ||
                            parts[0].type === "plusSign") &&
                            idx === 1),
                        "amount-currency__suffix": idx === parts.length - 1,
                      })}
                      key={part.type + part.value + idx}
                    >
                      {part.value}
                    </span>
                  );
                } else {
                  return (
                    <span key={part.type + part.value + idx}>{part.value}</span>
                  );
                }
              })}
            </span>
          )}
        </FormattedNumberParts>
      </span>
    </div>
  );
}
