import {
  BaseValidator,
  ValidationResponse,
  VALIDATION_STATE,
} from "@lysaab/ui-2";

export class BooleanValidator<Boolean> extends BaseValidator {
  protected name = "BooleanValidator";

  getId() {
    return this.getBaseId();
  }

  validate(value: Boolean): Promise<ValidationResponse> {
    return new Promise((resolve) => {
      if (value) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({ status: VALIDATION_STATE.FAILED, message: this.error });
      }
    });
  }
}
