import { defineMessages, IntlShape } from "react-intl";
import { PAIImportance } from "../../../data/types/SustainabilityQuestions";

const paiQuestionMessages = defineMessages({
  question: { id: "esg.pai.header" },
});

export const paiAltMessages = defineMessages<PAIImportance>({
  [PAIImportance.NOT_IMPORTANT]: {
    id: "esg.pai.alt.NOT_IMPORTANT",
  },
  [PAIImportance.IMPORTANT]: {
    id: "esg.pai.alt.IMPORTANT",
  },
});

export function getPAIQuestionText(intl: IntlShape) {
  return intl.formatMessage(paiQuestionMessages.question);
}

export function getPAIAltText(intl: IntlShape, key: PAIImportance) {
  return intl.formatMessage(paiAltMessages[key]);
}
