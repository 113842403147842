import { LysaCountry } from "@lysaab/shared";
import { Icon } from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { CountrySelectCard } from "../../components/countrySelectCard/CountrySelectCard";
import { ROUTES } from "../../TopLevelRoutes";
import "./CountrySelectPage.scss";

export const COUNTRY_SELECT_PAGE_URL = "/country-select";

const messages = defineMessages({
  header: {
    id: "country-select-page.header",
  },
});

export function CountrySelectPage() {
  const intl = useIntl();

  return (
    <div className="country-select-page">
      <header>
        <Icon.Logo />
      </header>
      <div className="center-wrapper">
        <CountrySelectCard
          header={intl.formatMessage(messages.header)}
          items={[
            { country: LysaCountry.SWEDEN, url: ROUTES[LysaCountry.SWEDEN] },
            { country: LysaCountry.FINLAND, url: ROUTES[LysaCountry.FINLAND] },
            { country: LysaCountry.DENMARK, url: ROUTES[LysaCountry.DENMARK] },
            { country: LysaCountry.GERMANY, url: ROUTES[LysaCountry.GERMANY] },
            // TODO: Enable when we go live in Spain
            // { country: LysaCountry.SPAIN, url: ROUTES[LysaCountry.SPAIN] },
          ]}
        />
      </div>
    </div>
  );
}
