import React from "react";
import { useIntl } from "react-intl";
import { messages } from "./InvestmentBasisText";
import {
  EligibilityRiskAnswer,
  EligibilityRiskAnswers,
  isValidEligibilityRiskAnswers,
} from "../../../data/types/Eligibility";
import { Typography } from "@lysaab/ui-2";

interface Props {
  eligibilityRiskAnswers: EligibilityRiskAnswers;
}

export const Risk: React.FC<Props> = ({ eligibilityRiskAnswers }) => {
  const intl = useIntl();

  if (!isValidEligibilityRiskAnswers(eligibilityRiskAnswers)) {
    return null;
  }

  return (
    <div>
      <Typography type="h2">
        {intl.formatMessage(messages.suitabilityRiskHeader)}
      </Typography>
      <Typography>
        {intl.formatMessage(messages.suitabilityRiskText, {
          important: intl.formatMessage(
            messages[
              `suitabilityRiskImportant${
                eligibilityRiskAnswers[EligibilityRiskAnswer.IMPORTANCE]
              }`
            ]
          ),
          riskPropensity: intl.formatMessage(
            messages[
              `suitabilityRiskPropensity${
                eligibilityRiskAnswers[EligibilityRiskAnswer.PROPENSITY]
              }`
            ]
          ),
          reaction: intl.formatMessage(
            messages[
              `suitabilityRiskReaction${
                eligibilityRiskAnswers[EligibilityRiskAnswer.REACTION]
              }`
            ]
          ),
        })}
      </Typography>
    </div>
  );
};
