import { useRouteMatch } from "react-router-dom";
import { ROUTES } from "../TopLevelRoutes";

const NO_MATCH_URL = "/";

export function useCountryUrl() {
  const match = useRouteMatch<{ country: string }>({ path: "/:country" });
  const country = match?.params.country;

  if (!country || !Object.values(ROUTES).includes(`/${country}`)) {
    return NO_MATCH_URL;
  }
  return `/${country}`;
}
