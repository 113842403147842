import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import { useIntl, defineMessages } from "react-intl";
import { Spinner } from "@lysaab/ui-2";
import { LysaCountry } from "@lysaab/shared";
import { getStatus, OnboardingStatus } from "../../data/signup";
import { LocalizationContext } from "../../state/LocalizationContext";
import { SignupId } from "@lysaab/lysa-onfido/data/signup";
import {
  OnfidoProcessPage,
  formatOnfidoMessage,
  getOnfidoStatus,
  StatusResponsePoa,
} from "@lysaab/lysa-onfido";
import { initiate, confirmId } from "../../data/onfido";
import { SignupContext } from "../../state/SignupContext";
import {
  EventTracker,
  TrackerEvent,
} from "../../utils/eventTracker/EventTracker";
import { OnfidoEmailError } from "./OnfidoEmailError";

const messages = defineMessages({
  noDriversLicense: {
    id: "signup.onfido.doc_select.button_id_detail",
  },
});

interface Props {
  signupId: SignupId;
  next: () => void;
  done: () => void;
  restart: () => void;
  onUserError: () => void;
  onFatalError: () => void;
  country: LysaCountry;
  // TODO: Enable line below again
  // allowedDocuments?: DocumentType[];
  allowedDocuments?: any[];
}

enum ProcessAction {
  WAIT,
  CONTINUE,
  SHOW_EMAIL_INFO,
}

export const OnfidoProcessId: React.VFC<Props> = ({
  signupId,
  next,
  done,
  restart,
  onUserError,
  onFatalError,
  allowedDocuments,
}) => {
  const signupContext = useContext(SignupContext);
  const [status, setStatus] = useState<ProcessAction>(
    signupContext.state.onfidoError
      ? ProcessAction.CONTINUE
      : ProcessAction.WAIT
  );
  const localizationContext = useContext(LocalizationContext);
  const gettingStatus = useRef(false);
  const { formatMessage } = useIntl();

  const initiateWithSignupId = useCallback(() => {
    EventTracker.track({ event: TrackerEvent.ONFIDO_INIT });
    return initiate(signupId);
  }, [signupId]);

  const onConfirmCb = useCallback(() => {
    return confirmId(signupId);
  }, [signupId]);

  const onfidoError = signupContext.state.onfidoError;

  const trackOnfidoEvent = (event: any) => {
    EventTracker.track({ event: TrackerEvent.ONFIDO_EVENT, message: event });
  };

  const internalNext = useCallback(() => {
    setStatus(ProcessAction.WAIT);

    getOnfidoStatus(signupId)
      .then((onfidoResponse) => {
        const poaStatuses: StatusResponsePoa[] = onfidoResponse.filter(
          (response): response is StatusResponsePoa =>
            response.checkType === "POA_UTILITY_BILL" ||
            response.checkType === "POA_ID_CARD"
        );

        let poaStatus = poaStatuses[0];

        if (poaStatuses.length === 2) {
          poaStatus =
            poaStatuses.find(
              (status) =>
                status.status === "ONFIDO_PENDING" ||
                status.status === "ONFIDO_PENDING_MANUAL_REVIEW"
            ) || poaStatus;
        }

        if (
          !poaStatus ||
          poaStatus.status === "ONFIDO_CONSIDER" ||
          poaStatus.status === "ONFIDO_FAILED_STARTED" ||
          poaStatus.status === "ONFIDO_FAILED_USER_ERROR"
        ) {
          next();
          return;
        } else if (
          poaStatus.status === "ONFIDO_CLEAR" ||
          poaStatus.status === "ONFIDO_PENDING" ||
          poaStatus.status === "ONFIDO_PENDING_MANUAL_REVIEW"
        ) {
          done();
          return;
        }
      })
      .catch(() => {
        onFatalError();
      });
  }, [next, done, signupId, onFatalError]);

  useEffect(() => {
    if (onfidoError || gettingStatus.current) {
      // If there's a fatal error, we don't want to run this check again
      setStatus(ProcessAction.CONTINUE);
      return;
    }

    if (!signupId) {
      onFatalError();
      return;
    }

    gettingStatus.current = true;
    Promise.all([getStatus(signupId), getOnfidoStatus(signupId)])
      .then(([response, onfidoResponse]) => {
        if (!response) {
          // Something's broken. Abort!
          onFatalError();
          return;
        }

        // TODO: See if we can remove all this.
        if (
          // First time coming here
          response.status === OnboardingStatus.NOT_SIGNED ||
          // Coming back after failure
          response.status === OnboardingStatus.ONFIDO_FAILED_DOCUMENT ||
          response.status === OnboardingStatus.ONFIDO_FAILED_DOCUMENT_TYPE ||
          response.status === OnboardingStatus.ONFIDO_FAILED_PHOTO ||
          // NOT_FOUND is handled inside <OnfidoProcessPage>
          response.status === OnboardingStatus.NOT_FOUND ||
          // User didn't complete the onfido steps. Let the user try again
          response.status === OnboardingStatus.ONFIDO_PENDING_USER
        ) {
          // All good, carry on
          setStatus(ProcessAction.CONTINUE);
          return;
        }

        const idStatus = onfidoResponse.find(
          (response) => response.checkType === "ID"
        );

        const poaStatus: StatusResponsePoa | undefined = onfidoResponse.find(
          (response): response is StatusResponsePoa =>
            response.checkType === "POA_UTILITY_BILL" ||
            response.checkType === "POA_ID_CARD"
        );

        if (
          // Haven't started the ID check yet
          !idStatus ||
          // Something's wrong, let the customer try again
          idStatus.status === "LYSA_CANCELED" ||
          idStatus.status === "ONFIDO_DOCUMENT_FAILED_TYPE" ||
          idStatus.status === "ONFIDO_FAILED_USER_ERROR" ||
          idStatus.status === "ONFIDO_FAILED_STARTED" ||
          idStatus.status === "ONFIDO_CONSIDER" ||
          idStatus.status === "LYSA_MANUAL_REVIEW_REJECTED"
        ) {
          setStatus(ProcessAction.CONTINUE);
          return;
        }

        if (
          idStatus.status === "ONFIDO_CLEAR" ||
          idStatus.status === "LYSA_MANUAL_REVIEW_CLEAR" ||
          idStatus.status === "LYSA_MANUAL_REVIEW" ||
          idStatus.status === "ONFIDO_PENDING" ||
          idStatus.status === "ONFIDO_PENDING_MANUAL_REVIEW"
        ) {
          if (
            !poaStatus ||
            poaStatus.status === "ONFIDO_CONSIDER" ||
            poaStatus.status === "ONFIDO_FAILED_STARTED" ||
            poaStatus.status === "ONFIDO_FAILED_USER_ERROR"
          ) {
            next();
            return;
          } else if (
            poaStatus.status === "ONFIDO_CLEAR" ||
            poaStatus.status === "ONFIDO_PENDING" ||
            poaStatus.status === "ONFIDO_PENDING_MANUAL_REVIEW"
          ) {
            done();
            return;
          }
        }

        if (
          response.status === OnboardingStatus.ONFIDO_PENDING ||
          response.status === OnboardingStatus.ONFIDO_PENDING_MANUAL_REVIEW ||
          response.status === OnboardingStatus.PENDING_MANUAL_REVIEW ||
          response.status === OnboardingStatus.PENDING_CREATION ||
          response.status === OnboardingStatus.COMPLETE
        ) {
          // User probably pressed "back" while waiting for answer from onfido
          // Or navigated directly to this page
          setStatus(ProcessAction.SHOW_EMAIL_INFO);
          return;
        }

        // Nothing to do but to start all over again
        onFatalError();
      })
      .catch(() => {
        // Nothing to do but to start all over again
        onFatalError();
      })
      .finally(() => {
        gettingStatus.current = false;
      });
  }, [signupId, onFatalError, onfidoError, next, done]);

  if (status === ProcessAction.WAIT) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  if (status === ProcessAction.CONTINUE) {
    return (
      <OnfidoProcessPage
        next={internalNext}
        restart={restart}
        onUserError={onUserError}
        onFatalError={onFatalError}
        country={localizationContext.state.country || LysaCountry.GERMANY}
        locale={localizationContext.state.language}
        phrases={{
          doc_select: {
            button_id_detail: formatOnfidoMessage(
              formatMessage,
              messages.noDriversLicense
            ),
          },
        }}
        confirm={onConfirmCb}
        steps={["document", "face"]}
        allowedDocuments={allowedDocuments}
        getToken={initiateWithSignupId}
        signupId={signupId}
        track={trackOnfidoEvent}
      />
    );
  }

  // status is SHOW_EMAIL_INFO
  return <OnfidoEmailError />;
};
