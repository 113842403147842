import React, { FunctionComponent } from "react";
import { FormattedNumber } from "react-intl";

interface Props {
  number: number;
  minDecimals?: number;
  maxDecimals?: number;
}

export const Percent: FunctionComponent<Props> = ({
  number,
  minDecimals,
  maxDecimals,
}) => {
  return (
    <FormattedNumber
      // eslint-disable-next-line react/style-prop-object
      style="percent"
      value={number}
      minimumFractionDigits={minDecimals}
      maximumFractionDigits={maxDecimals}
    />
  );
};
