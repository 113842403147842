import { Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";

export const OnfidoEmailError: React.VFC = () => {
  return (
    <div className="signup-onfido-process-page">
      <h1>
        <FormattedMessage id="signup.onfido.process.error.header" />
      </h1>
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <FormattedMessage id="signup.onfido.process.error.message" />
      </Snackbar>
    </div>
  );
};
