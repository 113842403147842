import React from "react";
import { useIntl } from "react-intl";
import { messages } from "./InvestmentBasisText";
import { Typography } from "@lysaab/ui-2";

export const Experience: React.FC = () => {
  const intl = useIntl();

  return (
    <div>
      <Typography type="h2">
        {intl.formatMessage(messages.suitabilityExperienceHeader)}
      </Typography>
      <Typography>
        {intl.formatMessage(messages.suitabilityExperienceText)}
      </Typography>
    </div>
  );
};
