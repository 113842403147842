import { Server, Response } from "miragejs";

export function makeCypressProxy() {
  return new Server({
    environment: "test",
    routes() {
      const methods: ("get" | "put" | "patch" | "post" | "delete")[] = [
        "get",
        "put",
        "patch",
        "post",
        "delete",
      ];

      methods.forEach((method) => {
        this.urlPrefix = "http://localhost:9876";

        this[method]("/*", async (schema, request) => {
          if (!window.handleFromCypress) {
            return;
          }

          try {
            const [status, headers, body] = await window.handleFromCypress(
              request
            );

            return new Response(status, headers, body);
          } catch {
            // Deliberately left empty
          }
        });
      });
    },
  });
}