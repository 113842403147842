import React, { useContext, FunctionComponent, useRef } from "react";
import { Card, Form, LysaFormRef, Icon, Button } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { SignupContext } from "../../../state/SignupContext";
import { IntroDocsParagraph } from "../../../components/intro/IntroDocsParagraph";
import { IntroEmailSection } from "../../../components/intro/IntroEmailSection";
import { IntroHeader } from "../../../components/intro/IntroHeader";
import { LocalizationContext } from "../../../state/LocalizationContext";
import { initiate, saveInterested } from "../../../data/signup";
import "./Intro.scss";
import { Link } from "react-router-dom";
import { EventTracker } from "../../../utils/eventTracker/EventTracker";
import { useQuery } from "../../../hooks/useQuery";

interface Query {
  from?: string;
}

interface Props {
  next: () => void;
}

export const Intro: FunctionComponent<Props> = ({ next }) => {
  const signupContext = useContext(SignupContext);
  const localizationContext = useContext(LocalizationContext);
  const formRef = useRef<LysaFormRef>();
  const query = useQuery<Query>();

  const isFromApp = query.from === "lysa-app";

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={(event) => {
        event.preventDefault();
        if (
          formRef.current?.isValid &&
          signupContext.state.email &&
          localizationContext.state.country
        ) {
          signupContext.setState({ isFromApp });
          saveInterested(
            signupContext.state.email,
            localizationContext.state.language,
            localizationContext.state.country
          );
          initiate(
            signupContext.state.email,
            localizationContext.state.country
          ).then(({ signupId }) => {
            EventTracker.setSignupId(signupId);
            next();
          });
        }
      }}
    >
      <div className="signup-intro-sweden">
        <IntroHeader />
        <p>
          <FormattedMessage id="sweden.intro.paragraph" />
        </p>
        <Card>
          <ol>
            <li>
              <div>
                <Icon.Chart />
              </div>
              <div>
                <FormattedMessage id="sweden.intro.list.proposal" />
              </div>
            </li>
            <li>
              <div>
                <Icon.Add />
              </div>
              <div>
                <FormattedMessage id="sweden.intro.list.openaccount" />
              </div>
            </li>
            <li>
              <div>
                <Icon.Deposit />
              </div>
              <div>
                <FormattedMessage id="sweden.intro.list.deposit" />
              </div>
            </li>
          </ol>
        </Card>

        <IntroEmailSection />

        <Button
          block
          type="submit"
          data-test-id="intro-next-button"
          label={<FormattedMessage id="sweden.intro.getstarted" />}
        />
        {!isFromApp && (
          <Button
            variant="secondary"
            block
            type="button"
            onClick={() => {
              window.location.href = "https://company-signup.lysa.se/";
            }}
            label={<FormattedMessage id="sweden.intro.company" />}
          />
        )}
        {!isFromApp && (
          <Button
            variant="secondary"
            block
            component={Link}
            to="/"
            label={<FormattedMessage id="sweden.intro.country-select" />}
          />
        )}
        <IntroDocsParagraph />
      </div>
    </Form>
  );
};
