import {
  API,
  BankIdInitResponse,
  BankIdStandardResponses,
  OrderRef,
  encode,
} from "@lysaab/ui-2";
import { SignupId } from "./signup";

export function initSigning(signupId: SignupId) {
  return API.post<BankIdInitResponse>("/bankid/onboarding", {
    signupId,
  });
}

export function pollSigning(orderRef: OrderRef, signupId: SignupId) {
  return API.get<BankIdStandardResponses>(
    `/bankid/onboarding/${orderRef}?signupId=${signupId}`,
    true
  );
}

export const pollQrCode = (orderRef?: OrderRef) => {
  if (!orderRef) {
    return Promise.resolve(undefined);
  }

  return API.get<{ code: string }>(
    encode`/bankid/onboarding/qr/${orderRef}`,
    true
  );
};
