import React from "react";
import { FormattedMessage } from "react-intl";
import { Card, Typography, NewIcon } from "@lysaab/ui-2";
import "./Intro.scss";

export const IntroList: React.VFC = () => (
  <div className="intro-list-germany">
    <Typography type="body">
      <FormattedMessage id="germany.intro.paragraph" />
    </Typography>
    <Card className="card">
      <ol>
        <li>
          <div>
            <NewIcon.Increase primaryColor="var(--lysa-text-primary)" />
          </div>
          <div>
            <FormattedMessage id="germany.intro.list.proposal" />
          </div>
        </li>
        <li>
          <div>
            <NewIcon.Plus primaryColor="var(--lysa-text-primary)" />
          </div>
          <div>
            <FormattedMessage id="germany.intro.list.openaccount" />
          </div>
        </li>
        <li>
          <div>
            <NewIcon.Profile primaryColor="var(--lysa-text-primary)" />
          </div>
          <div>
            <FormattedMessage id="germany.intro.list.identify" />
          </div>
        </li>
        <li>
          <div>
            <NewIcon.Deposit primaryColor="var(--lysa-text-primary)" />
          </div>
          <div>
            <FormattedMessage id="germany.intro.list.deposit" />
          </div>
        </li>
      </ol>
    </Card>
  </div>
);
