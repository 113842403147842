import React, { VoidFunctionComponent, useRef, useContext } from "react";
import { LysaCountry } from "@lysaab/shared";
import { Button, Form, LysaFormRef } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { LocalizationContext } from "../../state/LocalizationContext";
import { SustainabilityPreferenceQuestion } from "./SustainabilityPreferenceQuestion";
import { useAdvice } from "../../state/AdviceContext";
import { SustainabilityImportance } from "../../data/types/SustainabilityQuestions";

interface Props {
  next: () => void;
}

export const ROUTE = "/sustainability-preference";

export const SustainabilityPreference: VoidFunctionComponent<Props> = ({
  next,
}) => {
  const formRef = useRef<LysaFormRef>();
  const localizationContext = useContext(LocalizationContext);
  const advice = useAdvice();
  const country = localizationContext.state.country;

  const handleSubmit = () => {
    if (formRef.current?.isValid) {
      if (
        advice.input.sustainability === SustainabilityImportance.IMPORTANT &&
        country !== LysaCountry.SWEDEN
      ) {
        return;
      }
      next();
    }
  };

  return (
    <Form lysaFormRef={formRef} onSubmit={handleSubmit}>
      <div>
        <SustainabilityPreferenceQuestion />
        <Button
          block
          type="submit"
          data-test-id="sustainabilityPreference-next-button"
          label={<FormattedMessage id="sustainability-preference.next" />}
        />
      </div>
    </Form>
  );
};
