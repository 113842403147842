import React from "react";
import { IntlShape, useIntl } from "react-intl";
import { messages } from "./InvestmentBasisText";
import {
  EligibilityFinancials,
  isValidEligibilityFinancials,
} from "../../../data/types/Eligibility";
import { Typography } from "@lysaab/ui-2";
import {
  Currency,
  useLocalizationContext,
} from "../../../state/LocalizationContext";

interface Props {
  eligibilityFinancials: EligibilityFinancials;
}

export const Economy: React.FC<Props> = ({ eligibilityFinancials }) => {
  const intl = useIntl();
  const currency = useLocalizationContext().state.currency;

  return (
    <div>
      <Typography type="h2">
        {intl.formatMessage(messages.suitabilitySituationHeader)}
      </Typography>
      <Typography>
        {getEconomyText(intl, currency, eligibilityFinancials)}
      </Typography>
    </div>
  );
};

function getEconomyText(
  intl: IntlShape,
  currency: Currency,
  eligibilityFinancials: EligibilityFinancials
) {
  if (!isValidEligibilityFinancials(eligibilityFinancials)) {
    return intl.formatMessage(messages.suitabilitySituationText);
  }

  return intl.formatMessage(messages.suitabilitySituationText, {
    monthlyEarnings: intl.formatNumber(eligibilityFinancials.monthlyEarnings, {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }),
    monthlyExpenses: intl.formatNumber(eligibilityFinancials.monthlyPayments, {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }),
    liquidAssets: intl.formatNumber(eligibilityFinancials.liquidAssets, {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }),
    otherAssets: intl.formatNumber(eligibilityFinancials.otherAssets, {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }),
    debt: intl.formatNumber(eligibilityFinancials.debts, {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }),
  });
}
