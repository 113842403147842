import React, { useCallback, useContext, useMemo } from "react";
import {
  signingMessages,
  pendingMessages,
  failedMessages,
} from "./BankIDMessages";
import {
  useParams,
  useHistory,
  useLocation,
  generatePath,
  matchPath,
} from "react-router-dom";
import { initSigning, pollQrCode, pollSigning } from "../../../data/bankid";
import { ROUTES } from "../../Router";
import {
  openBankidApp,
  useBankId,
  OrderRef,
  BankIDStatus,
  PendingHintCode,
  FailedHintCode,
  useDisablePollInBackground,
  BankIdCompletedResponse,
  BankIdFailedResponse,
} from "@lysaab/ui-2";
import { preventUnload } from "../../../components/intro/IntroEmailSection";
import {
  EventTracker,
  TrackerEvent,
} from "../../../utils/eventTracker/EventTracker";
import { SignupId } from "../../../data/signup";
import { SignupContext } from "../../../state/SignupContext";
import { parse, stringify } from "query-string";
import { Location } from "history";
import { IntlShape, useIntl } from "react-intl";
import * as Sentry from "@sentry/browser";

interface Match {
  signupId: SignupId;
  orderRef?: OrderRef;
}

interface SearchParams {
  at?: string;
}

export const useSignBankId = () => {
  const history = useHistory();
  const location = useLocation();
  const signupContext = useContext(SignupContext);
  const isLysaApp = signupContext.state.isFromApp ? true : false;
  const urlParams = getParamsFromUrl(location);
  const { autoStartToken, orderRef, signupId } = urlParams;

  const onComplete = useCallback(
    (response: BankIdCompletedResponse) => {
      EventTracker.track({
        event: TrackerEvent.BANKID_RESULT,
        message: {
          status: response.status,
        },
      });
      history.replace(generatePath(ROUTES.DONE, { signupId }));
    },
    [history, signupId]
  );

  const onPollError = useCallback(
    (error: unknown) => {
      Sentry.captureException(error, {
        extra: {
          bankId: "pollSignError",
        },
      });
      history.replace(generatePath(ROUTES.SIGN, { signupId }));
    },
    [history, signupId]
  );

  const onFailed = useCallback((response: BankIdFailedResponse) => {
    EventTracker.track({
      event: TrackerEvent.BANKID_RESULT,
      message: {
        status: response.status,
        hintCode: response.hintCode,
      },
    });
  }, []);

  const qrCodePollFn = useCallback(() => pollQrCode(orderRef), [orderRef]);

  const pollFnRaw = useMemo(() => {
    return orderRef && signupId
      ? () => pollSigning(orderRef, signupId)
      : undefined;
  }, [orderRef, signupId]);
  const pollFn = useDisablePollInBackground(pollFnRaw);

  const { initiate, pollStatus, ...rest } = useBankId({
    onComplete,
    onPollError,
    onFailed,
    initPollFn: initSigning,
    pollFn,
    qrCodePollFn,
  });

  const startSign = useCallback(
    (signupId: SignupId) => {
      if (pollStatus === "IDLE" || pollStatus === "FAILED") {
        window.removeEventListener("beforeunload", preventUnload);

        initiate(signupId)
          .then((response) => {
            if (response) {
              EventTracker.track({
                event: TrackerEvent.BANKID_INIT,
                message: {
                  status: response.status,
                  hintCode: response.hintCode,
                  orderRef: response.orderRef,
                },
              });

              history.push(
                getUrlWithParams(
                  location,
                  response.orderRef,
                  signupId,
                  response.autoStartToken
                )
              );

              if (isLysaApp) {
                openBankidApp(response.autoStartToken, isLysaApp);
              }
            }
          })
          .catch((error: unknown) => {
            Sentry.captureException(error, {
              extra: {
                bankId: "initSignError",
              },
            });
            // If we get an error while initiating, we still want to transition
            // to the next step so BankIDStatus can show the error message.
            history.push(generatePath(ROUTES.SIGN, { signupId }));
          });
      }
    },
    [history, initiate, isLysaApp, location, pollStatus]
  );

  return { startSign, pollStatus, autoStartToken, signupId, ...rest };
};

type Props = ReturnType<typeof useSignBankId>;

export const Sign: React.FunctionComponent<Props> = ({
  autoStartToken,
  setOpenOnOtherDevice,
  startSign,
  latestResponse,
  qrCode,
}) => {
  const intl = useIntl();
  const { signupId } = useParams<Match>();

  return (
    <BankIDStatus
      autoStartToken={autoStartToken}
      setOpenOnOtherDevice={setOpenOnOtherDevice}
      qrCode={qrCode}
      retry={() => {
        startSign(signupId);
      }}
      response={latestResponse}
      getMessages={getMessages(intl)}
      getPendingMessages={getPendingMessages(intl)}
      getFailedMessages={getFailedMessages(intl)}
    />
  );
};

function getMessages(intl: IntlShape) {
  return () => {
    return {
      qrInfo1: intl.formatMessage(signingMessages.qrInfo1),
      qrInfo2: intl.formatMessage(signingMessages.qrInfo2),
      qrInfo3: intl.formatMessage(signingMessages.qrInfo3),
      buttonOpen: intl.formatMessage(signingMessages.buttonOpen),
      buttonErrorHeader: intl.formatMessage(signingMessages.buttonErrorHeader),
      buttonRetry: intl.formatMessage(signingMessages.buttonRetry),
      buttonClose: intl.formatMessage(signingMessages.buttonClose),
      buttonOtherDevice: intl.formatMessage(signingMessages.buttonOtherDevice),
    };
  };
}

function getPendingMessages(intl: IntlShape) {
  return (hintCode: PendingHintCode) =>
    intl.formatMessage(pendingMessages[hintCode]);
}

function getFailedMessages(intl: IntlShape) {
  return (hintCode: FailedHintCode) =>
    intl.formatMessage(failedMessages[hintCode]);
}

function getUrlWithParams(
  location: Location,
  orderRef: OrderRef | undefined,
  signupId: SignupId | undefined,
  autoStartToken: string
) {
  const search = parse(location.search) as SearchParams;
  search.at = autoStartToken;

  return {
    pathname: generatePath(ROUTES.SIGN, { orderRef, signupId }),
    search: stringify(search as Record<string, any>, { skipEmptyString: true }),
  };
}

function getParamsFromUrl(location: Location) {
  const match = matchPath<Match>(location.pathname, {
    path: ROUTES.SIGN,
  });

  let signupId;
  let orderRef;

  if (match) {
    signupId = match.params.signupId;
    orderRef = match.params.orderRef;
  }

  const search = parse(location.search) as SearchParams;
  return {
    autoStartToken: search.at,
    signupId,
    orderRef,
  };
}
