import { Button } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";

export const NextButton = () => (
  <Button
    block
    type="submit"
    data-test-id="kyc-next-button"
    label={<FormattedMessage id="kyc.button.next" />}
  />
);
