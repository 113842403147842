import React, { useContext, useState } from "react";
import { Header } from "./Header";
import { Economy } from "./Economy";
import { Risk } from "./Risk";
import { Horizon } from "./Horizon";
import { ESG } from "./ESG";
import { Advice } from "./Advise";
import { Experience } from "./Experience";
import { Modal } from "../../../components/modal/Modal";
import { FormattedMessage, useIntl } from "react-intl";
import {
  EventTracker,
  TrackerEvent,
} from "../../../utils/eventTracker/EventTracker";
import { useAdvice } from "../../../state/AdviceContext";
import { SignupContext } from "../../../state/SignupContext";
import {
  getEligibilityFinancials,
  getEligibilityRiskAnswers,
} from "../../../data/types/Eligibility";
import { getValidAccountQuestions } from "../../../data/types/AccountQuestions";
import { EsgResult } from "../../../data/Investments";
import { messages } from "./InvestmentBasisText";
import { LysaLinkButton } from "@lysaab/ui-2";

interface Props {
  esgResult?: EsgResult;
}

export const InvestmentBasisLink: React.FC<Props> = ({ esgResult }) => {
  const [showModal, setShowModal] = useState(false);
  const intl = useIntl();
  const signupContext = useContext(SignupContext);
  const advice = useAdvice();
  const eligibilityFinancials = getEligibilityFinancials(
    signupContext.state.financial
  );
  const eligibilityRiskAnswers = getEligibilityRiskAnswers(advice.input);
  const accountQuestions = getValidAccountQuestions(advice.input);

  if (typeof advice.result.advicedRisk === "undefined") {
    return null;
  }

  return (
    <>
      <LysaLinkButton
        onClick={(ev) => {
          ev.preventDefault();
          EventTracker.track({
            event: TrackerEvent.DOWNLOAD,
            message: "Suitability PDF",
          });
          setShowModal(true);
        }}
      >
        <FormattedMessage id="suitability_pdf_link.show" />
      </LysaLinkButton>
      <Modal
        header={intl.formatMessage(messages.suitabilityHeader)}
        showModal={showModal}
        onClose={() => setShowModal(false)}
      >
        <>
          <Header />
          <Experience />
          <Economy eligibilityFinancials={eligibilityFinancials} />
          <Risk eligibilityRiskAnswers={eligibilityRiskAnswers} />
          <Horizon accountQuestions={accountQuestions} />
          <ESG accountQuestions={accountQuestions} esgResult={esgResult} />
          <Advice advisedRisk={advice.result.advicedRisk} />
        </>
      </Modal>
    </>
  );
};
