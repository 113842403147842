import { FormattedMessage, IntlShape, defineMessages } from "react-intl";
import { SustainabilityPreference } from "../../data/types/SustainabilityQuestions";
import { DescriptionModal } from "../../components/descriptionModal/DescriptionModal";

const sustainabilityPreferenceQuestionMessages = defineMessages({
  question: { id: "esg.preference.header" },
});

export function getSustainabilityPreferenceQuestionText(intl: IntlShape) {
  return intl.formatMessage(sustainabilityPreferenceQuestionMessages.question);
}

const sustainabilityPreferenceAltMessages =
  defineMessages<SustainabilityPreference>({
    [SustainabilityPreference.NONE]: {
      id: "esg.preference.alt.NONE.header",
    },
    [SustainabilityPreference.SPECIFIC]: {
      id: "esg.preference.alt.SPECIFIC.header",
    },
  });
export function getSustainabilityPreferenceAltText(
  intl: IntlShape,
  key: SustainabilityPreference
) {
  return intl.formatMessage(sustainabilityPreferenceAltMessages[key]);
}

const alternativeDescriptionMessages = defineMessages<SustainabilityPreference>(
  {
    [SustainabilityPreference.NONE]: {
      id: "esg.preference.alt.NONE.description",
    },
    [SustainabilityPreference.SPECIFIC]: {
      id: "esg.preference.alt.SPECIFIC.description",
    },
  }
);
export function getSustainabilityPreferenceDescriptionText(
  intl: IntlShape,
  key: SustainabilityPreference
) {
  return intl.formatMessage(alternativeDescriptionMessages[key]);
}

const linkMessages = defineMessages({
  text: {
    id: "esg.preference.alt.NONE.link.header",
  },
  description: {
    id: "esg.preference.alt.NONE.link.description",
  },
});

const alternativeExpandDescriptionNoneMessage = defineMessages({
  item1: {
    id: "esg.preference.alt.NONE.expandDescription.item1",
    values: undefined,
  },
  item2: {
    id: "esg.preference.alt.NONE.expandDescription.item2",
    values: {
      link: (parts: React.ReactNode[]) => (
        <DescriptionModal
          header={<FormattedMessage {...linkMessages.text} />}
          content={
            <FormattedMessage
              {...linkMessages.description}
              values={{
                bold: (
                  parts: (string | React.ReactNode)[]
                ): React.ReactNode => <b>{parts}</b>,
                break: (
                  parts: (string | React.ReactNode)[]
                ): React.ReactNode => (
                  <>
                    {parts}
                    <br />
                    <br />
                  </>
                ),
                newLine: (
                  parts: (string | React.ReactNode)[]
                ): React.ReactNode => (
                  <>
                    {parts}
                    <br />
                  </>
                ),
              }}
            />
          }
        >
          {parts}
        </DescriptionModal>
      ),
    },
  },
});
const alternativeExpandDescriptionSpecificMessage = defineMessages({
  item1: {
    id: "esg.preference.alt.SPECIFIC.expandDescription.item1",
    values: undefined,
  },
});
const alternativeExpandDescriptionMessages = {
  [SustainabilityPreference.NONE]: alternativeExpandDescriptionNoneMessage,
  [SustainabilityPreference.SPECIFIC]:
    alternativeExpandDescriptionSpecificMessage,
};
export function getSustainabilityPreferenceExpandDescriptionText(
  intl: IntlShape,
  key: SustainabilityPreference
): React.ReactNode[] {
  return Object.values(alternativeExpandDescriptionMessages[key]).map(
    (message) => intl.formatMessage<React.ReactNode>(message, message.values)
  );
}
