import { useContext, useMemo, useState } from "react";
import { LysaCountry } from "@lysaab/shared";
import { InvestmentType, DocModal, Card, LysaLinkButton } from "@lysaab/ui-2";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { LocalizationContext } from "../../state/LocalizationContext";
import { DocumentInformation, getLatestDocuments } from "../../data/documents";
import { useAdvice } from "../../state/AdviceContext";
import "./ConfirmationTextSection.scss";

const messages = defineMessages({
  modalHeader: {
    id: "confirmation.docs.header",
  },
  placeholder: {
    id: "docs.placeholder",
  },
  later: {
    id: "docs.later",
  },
  accountName: {
    id: "docs.account.name",
  },
  modalAnnouncement: {
    id: "component.docmodal.announcement",
  },
  [InvestmentType.BROAD]: {
    id: "confirmation.docs.investment.BROAD",
  },
  [InvestmentType.SUSTAINABLE]: {
    id: "confirmation.docs.investment.SUSTAINABLE",
  },
});

const docList: Record<LysaCountry, Array<Array<string>>> = {
  [LysaCountry.SWEDEN]: [
    ["legal/se/sv/customer-agreement.md"],
    ["legal/se/sv/placeringsriktlinjer.md"],
    ["legal/se/sv/avtal-om-isk.md"],
    ["legal/se/sv/allmänna-villkor-isk.md"],
    ["legal/se/sv/account-terms.md"],
    ["legal/se/sv/förhandsinformation-om-investeringssparkonto.md"],
    ["legal/se/sv/bästa-orderutförande-1.md"],
    ["legal/se/sv/depositor-information.md"],
    [
      "legal/se/sv/information-om-lysa-v-2021-1.md",
      "legal/se/sv/information-om-lysa-v-2021-2.md",
      "legal/se/sv/information-om-lysa-v-2022-1.md",
      "legal/se/sv/information-om-lysa-v-2023-1.md",
    ],
    [
      "legal/se/sv/information-om-egenskaper-och-risker-med-finansiella-instrument.md",
      "legal/se/sv/information-om-egenskaper-och-risker-med-finansiella-instrument-v-2024-1.md",
    ],
  ],
  [LysaCountry.DENMARK]: [
    ["legal/all/en/portfolio-management-agreement.md"],
    ["legal/all/en/investment-guidelines.md"],
    ["legal/all/en/investment-account-agreement.md"],
    ["legal/all/en/general-terms-and-conditions-for-investment-accounts.md"],
    ["legal/all/en/best-execution-policy-1.md"],
    ["legal/dk/en/depositor-information.md"],
    [
      "legal/all/en/information-about-lysa-v-2021-2.md",
      "legal/all/en/information-about-lysa-v-2024-1.md",
    ],
    [
      "legal/all/en/information-regarding-characteristics-and-risks-relating-to-financial-instruments.md",
      "legal/all/en/information-regarding-characteristics-and-risks-relating-to-financial-instruments-v-2024-1.md",
    ],
    [
      "legal/dk/en/information-regarding-withdrawal-of-funds-through-nemkonto.md",
    ],
    ["legal/dk/en/tax-reporting-agreement.md"],
  ],
  [LysaCountry.FINLAND]: [
    ["legal/all/en/portfolio-management-agreement.md"],
    ["legal/all/en/investment-guidelines.md"],
    ["legal/all/en/investment-account-agreement.md"],
    ["legal/all/en/general-terms-and-conditions-for-investment-accounts.md"],
    ["legal/all/en/best-execution-policy-1.md"],
    ["legal/fi/en/depositor-information.md"],
    [
      "legal/all/en/information-about-lysa-v-2021-2.md",
      "legal/all/en/information-about-lysa-v-2024-1.md",
    ],
    [
      "legal/all/en/information-regarding-characteristics-and-risks-relating-to-financial-instruments.md",
      "legal/all/en/information-regarding-characteristics-and-risks-relating-to-financial-instruments-v-2024-1.md",
    ],
  ],
  [LysaCountry.GERMANY]: [
    ["legal/de/de/portfolio-management-agreement.md"],
    ["legal/de/de/investment-guidelines.md"],
    ["legal/de/de/investment-account-agreement.md"],
    ["legal/de/de/general-terms-and-conditions-for-investment-accounts.md"],
    ["legal/de/de/best-execution-policy-1.md"],
    ["legal/de/de/depositor-information.md"],
    [
      "legal/de/de/information-about-lysa-v-2021-1.md",
      "legal/de/de/information-about-lysa-v-2024-1.md",
    ],
    [
      "legal/de/de/information-regarding-characteristics-and-risks-relating-to-financial-instruments.md",
      "legal/de/de/information-regarding-characteristics-and-risks-relating-to-financial-instruments-v-2024-1.md",
    ],
  ],
  [LysaCountry.SPAIN]: [
    ["legal/all/en/portfolio-management-agreement.md"],
    ["legal/all/en/investment-guidelines.md"],
    ["legal/all/en/investment-account-agreement.md"],
    ["legal/all/en/general-terms-and-conditions-for-investment-accounts.md"],
    ["legal/all/en/best-execution-policy-1.md"],
    ["legal/es/en/depositor-information.md"],
    [
      "legal/all/en/information-about-lysa-v-2021-1.md",
      "legal/all/en/information-about-lysa-v-2024-1.md",
    ],
    [
      "legal/all/en/information-regarding-characteristics-and-risks-relating-to-financial-instruments.md",
      "legal/all/en/information-regarding-characteristics-and-risks-relating-to-financial-instruments-v-2024-1.md",
    ],
  ],
};

const managementInformation: Record<LysaCountry, string[]> = {
  [LysaCountry.SWEDEN]: ["legal/se/sv/customer-agreement.md"],
  [LysaCountry.DENMARK]: ["legal/all/en/portfolio-management-agreement.md"],
  [LysaCountry.FINLAND]: ["legal/all/en/portfolio-management-agreement.md"],
  [LysaCountry.GERMANY]: ["legal/de/de/portfolio-management-agreement.md"],
  [LysaCountry.SPAIN]: ["legal/all/es/portfolio-management-agreement.md"],
};

const depositorInformation: Record<LysaCountry, string[]> = {
  [LysaCountry.SWEDEN]: ["legal/se/sv/depositor-information.md"],
  [LysaCountry.DENMARK]: ["legal/dk/en/depositor-information.md"],
  [LysaCountry.FINLAND]: ["legal/fi/en/depositor-information.md"],
  [LysaCountry.GERMANY]: ["legal/de/de/depositor-information.md"],
  [LysaCountry.SPAIN]: ["legal/es/en/depositor-information.md"],
};

interface Props {
  documentsInformation: DocumentInformation[];
}

export const ConfirmationTextSection: React.VFC<Props> = ({
  documentsInformation,
}) => {
  const localizationContext = useContext(LocalizationContext);
  const {
    result: { takenRisk, investmentType },
  } = useAdvice();
  const [showDoc, setShowDoc] = useState<string>();
  const intl = useIntl();

  const country = localizationContext.state.country;

  const latestDocuments = useMemo(
    () =>
      country
        ? getLatestDocuments(documentsInformation, ...docList[country])
        : [],
    [country, documentsInformation]
  );

  if (typeof takenRisk === "undefined") {
    throw new Error("Missing required data");
  }

  const varValues = {
    SIGNING_DATE: intl.formatDate(new Date()),
    CUSTOMER_NAME: intl.formatMessage(messages.later),
    ACCOUNT_NAME: intl.formatMessage(messages.accountName),
    STOCKS: "" + intl.formatNumber(takenRisk / 100, { style: "percent" }),
    BONDS:
      "" + intl.formatNumber((100 - takenRisk) / 100, { style: "percent" }),
    INVESTMENT_TYPE: intl.formatMessage(
      messages[investmentType || InvestmentType.BROAD]
    ),
    CURRENCY: localizationContext.state.currency,
  };

  if (!country) {
    return null;
  }

  const managementDoc = getLatestDocuments(
    documentsInformation,
    managementInformation[country]
  )[0];
  const guaranteeDoc = getLatestDocuments(
    documentsInformation,
    depositorInformation[country]
  )[0];

  return (
    <>
      <DocModal
        header={intl.formatMessage(messages.modalHeader)}
        active={!!showDoc}
        onRequestClose={() => setShowDoc(undefined)}
        variableValues={varValues}
        docName={showDoc}
        selectPlaceholder={intl.formatMessage(messages.placeholder)}
        docList={latestDocuments}
        modalAnnouncement={intl.formatMessage(messages.modalAnnouncement)}
      />
      <section>
        <Card>
          <h3>
            <FormattedMessage id="confirmation.document.title" />
          </h3>
          <div>
            <LysaLinkButton
              onClick={(ev) => {
                ev.preventDefault();
                setShowDoc(managementDoc);
              }}
            >
              <FormattedMessage id="confirmation.confirm.link" />
            </LysaLinkButton>
          </div>
          <div>
            <LysaLinkButton
              onClick={(ev) => {
                ev.preventDefault();
                setShowDoc(guaranteeDoc);
              }}
            >
              <FormattedMessage id="confirmation.confirm.guaranteelink" />
            </LysaLinkButton>
          </div>
        </Card>

        <Card className="confirmation-text">
          <h3>
            <FormattedMessage id="confirmation.confirm" />
          </h3>
          <ul>
            <li>
              <FormattedMessage id="confirmation.confirm.1" />
            </li>
            <li>
              <FormattedMessage id="confirmation.confirm.2" />
            </li>
            <li>
              <FormattedMessage id="confirmation.confirm.3" />
            </li>
            <li>
              <FormattedMessage id="confirmation.confirm.4" />
            </li>
          </ul>
        </Card>
      </section>
    </>
  );
};
