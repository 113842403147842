import { useContext, useRef } from "react";
import { HiddenInput } from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { crsMessages } from "./CrsMessages";
import { SignupContext } from "../../state/SignupContext";
import { BooleanValidator } from "../../components/validators/BooleanValidator";

export const CrsReportingCountryValidation = () => {
  const intl = useIntl();
  const signupContext = useContext(SignupContext);
  const validatorRef = useRef<HTMLDivElement>(null);
  const crsResidenceIsAlsoTaxReportingCountry =
    signupContext.state.crsReportingCountries?.some((reportingCountry) => {
      return (
        reportingCountry.country === signupContext.state.crsResidenceCountry
      );
    }) === true;
  return (
    <div ref={validatorRef}>
      <HiddenInput
        label={intl.formatMessage(crsMessages.needToAddReportingCountry)}
        value={crsResidenceIsAlsoTaxReportingCountry}
        validators={[
          new BooleanValidator(
            intl.formatMessage(crsMessages.needToAddReportingCountry)
          ),
        ]}
        scrollToRef={validatorRef}
      />
    </div>
  );
};
