import React, { useRef, useContext, useState, useEffect } from "react";
import {
  Card,
  Form,
  LysaFormRef,
  Slider,
  MoneyInput,
  MinValidator,
  MaxValidator,
  SegmentedSelect,
  InvestmentType,
  Alternative,
  ExpandableSneakPeakCard,
  AllocationBarIntl,
  Button,
  Typography,
} from "@lysaab/ui-2";
import { LysaCountry } from "@lysaab/shared";
import { AccountType } from "../confirmation/ConfirmationHelpers";
import { EditAllocationForecast } from "./EditAllocationForecast";
import { EditAllocationPositions } from "./EditAllocationPositions";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";
import { SignupContext } from "../../state/SignupContext";
import { HorizonWarning } from "./HorizonWarning";
import { LocalizationContext } from "../../state/LocalizationContext";
import { Disclaimer } from "../../components/Disclaimer";
import "./EditAllocation.scss";
import { useHistory } from "react-router-dom";
import { useCountryUrl } from "../../hooks/useCountryUrl";
import { useAdvice } from "../../state/AdviceContext";
import { DEFAULT_VALUES } from "./DefaultValues";
import { FormattedPercentage } from "../../components/formattedPercentage/FormattedPercentage";
import { SavingsHorizonLength } from "../../data/types/HorizonQuestions";
import { FeesInformation } from "../../components/feesInformation/FeesInformation";

const messages = defineMessages({
  allocationBarStocks: {
    id: "editallocationpositions.card.bar.stocks",
  },
  allocationBarBonds: {
    id: "editallocationpositions.card.bar.bonds",
  },
  navForecast: {
    id: "editallocation.navigation.forecast",
  },
  navFees: {
    id: "editallocation.navigation.fees",
  },
  navPositions: {
    id: "editallocation.navigation.positions",
  },
  validatorMinInvestment: {
    id: "editallocation.validator.min-investment",
  },
  validatorMaxInvestment: {
    id: "editallocation.validator.max-investment",
  },
  toggleShowPositions: {
    id: "editallocationpositions.toggleshow",
  },
  toggleHidePositions: {
    id: "editallocationpositions.togglehide",
  },
  investmentAlternativeBroad: {
    id: "editallocation.investmentType.alternative.broad",
  },
  investmentAlternativeSustainable: {
    id: "editallocation.investmentType.alternative.sustainable",
  },
});

export const ROUTE = "/edit-allocation";

interface Props {
  next: () => void;
  nextRiskWarning: () => void;
  allowInvestmentTypeChange?: boolean;
}

export const EditAllocation: React.FC<Props> = ({
  next,
  nextRiskWarning,
  allowInvestmentTypeChange = false,
}) => {
  const intl = useIntl();
  const signupContext = useContext(SignupContext);
  const localizationContext = useContext(LocalizationContext);
  const advice = useAdvice();
  const [showHorizonWarning, setShowHorizonWarning] = useState(
    advice.input.savingsHorizon === SavingsHorizonLength.SHORT &&
      (signupContext.state.allocationSelectedRisk ?? 0) >= 50
  );
  const history = useHistory();
  const countryUrl = useCountryUrl();
  const formRef = useRef<LysaFormRef>();
  const [monthly, setMonthly] = useState(
    DEFAULT_VALUES[localizationContext.state.currency].monthly
  );
  const [horizon, setHorizon] = useState(5);

  const advisedRisk = advice.result.advicedRisk;
  const investmentType = advice.result.investmentType;
  const risk = signupContext.state.allocationSelectedRisk;
  const currency = localizationContext.state.currency;
  const accountType =
    localizationContext.state.country === LysaCountry.SWEDEN
      ? AccountType.ISK_SWE
      : AccountType.VP;

  useEffect(() => {
    if (
      typeof advisedRisk === "undefined" ||
      typeof investmentType === "undefined"
    ) {
      history.replace(countryUrl);
    }
  }, [countryUrl, history, advisedRisk, investmentType]);

  useEffect(() => {
    if (typeof signupContext.state.allocationSelectedRisk === "undefined") {
      signupContext.setState({ allocationSelectedRisk: advisedRisk });
    }
  }, [currency, signupContext, advisedRisk]);

  const persistData = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (
      formRef.current &&
      formRef.current.isValid &&
      typeof risk !== "undefined" &&
      typeof advisedRisk !== "undefined" &&
      (advice.input.savingsHorizon === SavingsHorizonLength.SHORT
        ? risk <= 50
        : true)
    ) {
      advice.setResult({ takenRisk: risk });

      if (Math.abs(risk - advisedRisk) > 15) {
        nextRiskWarning();
        return;
      }

      next();
    }
  };

  const investmentTypeAlternatives: Alternative<InvestmentType>[] = [
    {
      text: intl.formatMessage(messages.investmentAlternativeBroad),
      value: InvestmentType.BROAD,
    },
    {
      text: intl.formatMessage(messages.investmentAlternativeSustainable),
      value: InvestmentType.SUSTAINABLE,
    },
  ];

  if (
    typeof risk === "undefined" ||
    typeof advisedRisk === "undefined" ||
    typeof investmentType === "undefined"
  ) {
    return null;
  }

  return (
    <div className="edit-allocation-page">
      <Form lysaFormRef={formRef} onSubmit={persistData}>
        <Card>
          <div className="editallocation-header">
            <Typography type="h3" component="h2">
              <FormattedMessage id="editallocation.editallocation" />
            </Typography>
            <Button
              variant="secondary"
              size="small"
              inline
              onClick={() =>
                signupContext.setState({ allocationSelectedRisk: advisedRisk })
              }
              className={
                signupContext.state.allocationSelectedRisk === advisedRisk
                  ? "reset-hidden"
                  : ""
              }
              label={<FormattedMessage id="editallocation.button.reset" />}
            />
          </div>
          <div className="allocations">
            <strong>
              <FormattedMessage id="editallocation.text.lysassuggestion" />
            </strong>

            <AllocationBarIntl
              messages={{
                stocks: (
                  <>
                    <span className="allocation-bar-value">
                      <FormattedPercentage value={advisedRisk} />
                    </span>
                    <span className="allocation-bar-name">
                      {intl.formatMessage(messages.allocationBarStocks)}
                    </span>
                  </>
                ),
                bonds: (
                  <>
                    <span className="allocation-bar-name">
                      {intl.formatMessage(messages.allocationBarBonds)}
                    </span>
                    <span className="allocation-bar-value">
                      <FormattedPercentage value={100 - advisedRisk} />
                    </span>
                  </>
                ),
              }}
              risk={advisedRisk}
            />

            <Slider
              label={
                <>
                  <strong>
                    {risk === advisedRisk ? (
                      <FormattedMessage id="editallocation.text.targetallocation" />
                    ) : (
                      <FormattedMessage id="editallocation.text.targetallocation.new" />
                    )}
                  </strong>
                  {showHorizonWarning && <HorizonWarning />}
                  <div className="allocation-slider-value-wrapper">
                    <div>
                      <span className="allocation-bar-value">
                        <FormattedPercentage value={risk} />
                      </span>
                      <span className="allocation-bar-name">
                        {intl.formatMessage(messages.allocationBarStocks)}
                      </span>
                    </div>
                    <div>
                      <span className="allocation-bar-name">
                        {intl.formatMessage(messages.allocationBarBonds)}
                      </span>
                      <span className="allocation-bar-value">
                        <FormattedPercentage value={100 - risk} />
                      </span>
                    </div>
                  </div>
                </>
              }
              min={0}
              max={100}
              value={signupContext.state.allocationSelectedRisk ?? 0}
              onChange={(value) => {
                if (
                  advice.input.savingsHorizon === SavingsHorizonLength.SHORT &&
                  value > 50
                ) {
                  setShowHorizonWarning(true);
                  signupContext.setState({ allocationSelectedRisk: 50 });
                } else {
                  setShowHorizonWarning(false);
                  signupContext.setState({ allocationSelectedRisk: value });
                }
              }}
              risk
            />
          </div>
        </Card>
        <Card>
          <h3>
            <FormattedMessage id="editallocation.header" />
          </h3>
          <h4>
            <FormattedMessage id="editallocation.header.example-investment" />
          </h4>
          <MoneyInput
            locale={localizationContext.getLocale()}
            currency={localizationContext.state.currency}
            value={signupContext.state.allocationInvestment.toString()}
            onChange={(value) =>
              signupContext.setState({
                allocationInvestment: parseFloat(value) || 0,
              })
            }
            placeholder={intl.formatNumber(
              DEFAULT_VALUES[localizationContext.state.currency]
                .defaultInvestment
            )}
            validators={[
              new MinValidator(
                DEFAULT_VALUES[
                  localizationContext.state.currency
                ].minInvestment,
                intl.formatMessage(messages.validatorMinInvestment, {
                  min: intl.formatNumber(
                    DEFAULT_VALUES[localizationContext.state.currency]
                      .minInvestment,
                    {
                      style: "currency",
                      currency: localizationContext.state.currency,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }
                  ),
                })
              ),
              new MaxValidator(
                DEFAULT_VALUES[
                  localizationContext.state.currency
                ].maxInvestment,
                intl.formatMessage(messages.validatorMaxInvestment, {
                  max: intl.formatNumber(
                    DEFAULT_VALUES[localizationContext.state.currency]
                      .maxInvestment,
                    {
                      style: "currency",
                      currency: localizationContext.state.currency,
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }
                  ),
                })
              ),
            ]}
          />
          <EditAllocationForecast
            chosenAllocation={risk.toString()}
            monthly={monthly}
            setMonthly={setMonthly}
            horizon={horizon}
            setHorizon={setHorizon}
          />
          {allowInvestmentTypeChange && (
            <SegmentedSelect
              alternatives={investmentTypeAlternatives}
              onChange={(value) =>
                advice.setResult({ investmentType: value.value })
              }
              value={{
                text: "",
                value: advice.result.investmentType || InvestmentType.BROAD,
              }}
            />
          )}
        </Card>

        <Button
          block
          type="submit"
          label={<FormattedMessage id="editallocation.button.next" />}
        />

        <FeesInformation
          risk={risk}
          investmentType={investmentType}
          accountType={accountType}
        />

        <ExpandableSneakPeakCard
          buttonOpenText={intl.formatMessage(messages.toggleShowPositions)}
          buttonCloseText={intl.formatMessage(messages.toggleHidePositions)}
        >
          <h4>
            <FormattedMessage id="editallocationpositions.header" />
          </h4>
          <EditAllocationPositions chosenAllocation={risk.toString()} />
        </ExpandableSneakPeakCard>
      </Form>
      <Disclaimer />
    </div>
  );
};
