import { InvestmentType, RiskIndicator, Typography } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import "./InvestmentTypeIndicator.scss";

interface Props {
  investmentType: InvestmentType;
  advicedRisk: number;
}

export const InvestmentTypeIndicator: React.VFC<Props> = ({
  investmentType,
  advicedRisk,
}) => {
  return (
    <div className="investment-type-indicator">
      <Typography className="label" type="label-large">
        <FormattedMessage id="investmentType.label" />
      </Typography>
      <div
        className="investment-type-indicator-type"
        data-test-id="advice-investment-type"
      >
        {investmentType === InvestmentType.BROAD && (
          <Typography className="label" type="label-large">
            <FormattedMessage id="investmentType.broad" />
          </Typography>
        )}

        {investmentType === InvestmentType.SUSTAINABLE && (
          <Typography className="label" type="label-large">
            <FormattedMessage id="investmentType.sustainable" />
          </Typography>
        )}

        <div>
          <RiskIndicator risk={advicedRisk} investmentType={investmentType} />
        </div>
      </div>
    </div>
  );
};
