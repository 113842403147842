import { Currency } from "../../state/LocalizationContext";

interface AllocationConfig {
  maxInvestment: number;
  minInvestment: number;
  defaultInvestment: number;
  monthly: number;
}

export const DEFAULT_VALUES: { [key in Currency]: AllocationConfig } = {
  [Currency.DKK]: {
    defaultInvestment: 10_000,
    maxInvestment: 100_000_000_000,
    minInvestment: 1_000,
    monthly: 200,
  },
  [Currency.EUR]: {
    defaultInvestment: 1_000,
    maxInvestment: 10_000_000_000,
    minInvestment: 100,
    monthly: 20,
  },
  [Currency.SEK]: {
    defaultInvestment: 10_000,
    maxInvestment: 100_000_000_000,
    minInvestment: 1_000,
    monthly: 200,
  },
};
