import React from "react";
import { IntlShape, useIntl } from "react-intl";
import { messages } from "./InvestmentBasisText";
import { EsgResult } from "../../../data/Investments";
import { Typography } from "@lysaab/ui-2";
import {
  AccountQuestions,
  isValidAccountQuestions,
} from "../../../data/types/AccountQuestions";
import {
  isSustainabilityImportantNone,
  isSustainabilityImportantSpecific,
} from "../../../data/types/SustainabilityQuestions";
import { getSFDRAltText } from "../../sustainabilityImportant/questions/SFDRText";
import { getTaxonomyAltText } from "../../sustainabilityImportant/questions/TaxonomyText";

interface Props {
  accountQuestions: AccountQuestions;
  esgResult?: EsgResult;
}

export const ESG: React.FC<Props> = ({ accountQuestions, esgResult }) => {
  const intl = useIntl();

  return (
    <div>
      <Typography type="h2">
        {intl.formatMessage(messages.suitabilityEsgHeader)}
      </Typography>
      <Typography>{getEsgText(intl, accountQuestions)}</Typography>
      {esgResult?.esgBestMatch && (
        <Typography>
          {intl.formatMessage(messages.updatedEsg)}
          {getSfdrText(intl, esgResult)}
          {getTaxonomyText(intl, esgResult)}
        </Typography>
      )}
    </div>
  );
};

function getEsgText(
  intl: IntlShape,
  accountQuestions: AccountQuestions
): string {
  if (!isValidAccountQuestions(accountQuestions)) {
    return "";
  }

  const sustainabilityMessage = intl.formatMessage(
    messages[`sustainabilityImportance${accountQuestions.sustainability}`]
  );

  if (isSustainabilityImportantNone(accountQuestions)) {
    const esgMessage = messages.sustainabilityImportantNone;
    const sustainabilityPreferenceMessage = intl.formatMessage(
      messages[
        `sustainabilityPreference${accountQuestions.sustainabilityPreference}`
      ]
    );
    return intl.formatMessage(esgMessage, {
      sustainability: sustainabilityMessage,
      sustainabilityPreference: sustainabilityPreferenceMessage,
    });
  }

  if (isSustainabilityImportantSpecific(accountQuestions)) {
    const esgMessage = messages.sustainabilityImportantSpecific;
    const sustainabilityPreferenceMessage = intl.formatMessage(
      messages[
        `sustainabilityPreference${accountQuestions.sustainabilityPreference}`
      ]
    );

    const paiMessage = intl.formatMessage(
      messages[`pai${accountQuestions.pai}`]
    );
    const taxonomyMessage = intl.formatMessage(
      messages[`taxonomy${accountQuestions.taxonomy}`]
    );
    const sfdrMessage = intl.formatMessage(
      messages[`sfdr${accountQuestions.sfdr}`]
    );

    return intl.formatMessage(esgMessage, {
      sustainability: sustainabilityMessage,
      sustainabilityPreference: sustainabilityPreferenceMessage,
      pai: paiMessage,
      taxonomy: taxonomyMessage,
      sfdr: sfdrMessage,
    });
  }

  const esgMessage = messages.sustainabilityNotImportant;

  return intl.formatMessage(esgMessage, {
    sustainability: sustainabilityMessage,
  });
}

function getSfdrText(
  intl: IntlShape,
  { esgAnswers, esgBestMatch }: EsgResult
): string {
  if (esgBestMatch === undefined || esgAnswers.sfdr === esgBestMatch.sfdr) {
    return "";
  }

  return intl.formatMessage(messages.updatedEsgInsert, {
    type: intl.formatMessage(messages.sfdr),
    answer: getSFDRAltText(intl, esgAnswers.sfdr),
    updatedAnswer: getSFDRAltText(intl, esgBestMatch.sfdr),
  });
}

function getTaxonomyText(
  intl: IntlShape,
  { esgAnswers, esgBestMatch }: EsgResult
): string {
  if (
    esgBestMatch === undefined ||
    esgAnswers.taxonomy === esgBestMatch.taxonomy
  ) {
    return "";
  }
  return intl.formatMessage(messages.updatedEsgInsert, {
    type: intl.formatMessage(messages.taxonomy),
    answer: getTaxonomyAltText(intl, esgAnswers.taxonomy),
    updatedAnswer: getTaxonomyAltText(intl, esgBestMatch.taxonomy),
  });
}
