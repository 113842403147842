import { useContext } from "react";
import { useRouteMatch } from "react-router-dom";
import { SignupId } from "../data/signup";
import { SignupContext } from "../state/SignupContext";

export const useSignupId = (ROUTES: Record<string, string>) => {
  const signupContext = useContext(SignupContext);

  const rejectSignupId = useRouteMatch<{ signupId: SignupId }>({
    path: ROUTES.ONFIDO_STATUS,
  })?.params.signupId;

  const introSignupId = useRouteMatch<{ signupId: SignupId }>({
    path: ROUTES.ONFIDO_INTRO,
  })?.params.signupId;

  const processIdSignupId = useRouteMatch<{ signupId: SignupId }>({
    path: ROUTES.ONFIDO_PROCESS_ID,
  })?.params.signupId;

  const poaIntroSignupId = useRouteMatch<{ signupId: SignupId }>({
    path: ROUTES.ONFIDO_POA_INTRO,
  })?.params.signupId;

  const processPoaSignupId = useRouteMatch<{ signupId: SignupId }>({
    path: ROUTES.ONFIDO_PROCESS_POA,
  })?.params.signupId;

  const doneSignupId = useRouteMatch<{ signupId: SignupId }>({
    path: ROUTES.DONE,
  })?.params.signupId;

  const signupId =
    rejectSignupId ||
    introSignupId ||
    processIdSignupId ||
    poaIntroSignupId ||
    processPoaSignupId ||
    doneSignupId ||
    signupContext.state.signupId ||
    ("" as SignupId);

  return signupId;
};
