import {
  EmailInput,
  EmailValidator,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
} from "@lysaab/ui-2";
import React, { useContext } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { SignupContext } from "../../state/SignupContext";
import {
  EventTracker,
  TrackerEvent,
} from "../../utils/eventTracker/EventTracker";

const messages = defineMessages({
  email: { id: "intro.email.label" },
  emailRequired: { id: "intro.email.required" },
  emailValidation: { id: "intro.email.validator" },
  emailPlaceholder: { id: "intro.email.placeholder" },
  emailSuggestion: { id: "intro.email.suggestion" },
});

export const IntroEmailSection = () => {
  const intl = useIntl();
  const signupContext = useContext(SignupContext);

  return (
    <section>
      <EmailInput
        label={intl.formatMessage(messages.email)}
        value={signupContext.state.email ?? ""}
        onChange={(value) => {
          if (!signupContext.state.email) {
            window.addEventListener("beforeunload", preventUnload);
          }
          signupContext.setState({ email: value });
        }}
        placeholder={intl.formatMessage(messages.emailPlaceholder)}
        disabled={!!signupContext.state.signedEmail}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.emailRequired)),
          new EmailValidator(intl.formatMessage(messages.emailValidation)),
        ]}
        suggestionMessage={intl.formatMessage(messages.emailSuggestion, {
          // This replacement is made inside <EmailInput>, so pass it along
          suggestion: "{suggestion}",
        })}
        data-test-id="email-input"
      />
      {signupContext.state.signedEmail && (
        <Snackbar type={SNACKBAR_TYPES.SUCCESS}>
          {/* This will happen if the user is redirected back, to try again */}
          <FormattedMessage id="intro.email.disabled" />
        </Snackbar>
      )}
    </section>
  );
};

export function preventUnload(event: BeforeUnloadEvent) {
  EventTracker.track({ event: TrackerEvent.UNLOAD_WARNING });
  event.preventDefault();
  event.returnValue = "";
}
