import { useContext, useEffect, useState } from "react";
import { SignupContext } from "../state/SignupContext";
import { useQuery } from "../hooks/useQuery";
import { Plausible } from "../utils/Plausible";
import { ReferralStorage } from "../data/referralStorage";
import { useLocation } from "react-router-dom";
import { generateAffiliateCookie, AffiliateNetwork } from "../data/affiliate";
import { Modal } from "./modal/Modal";
import { FormattedMessage } from "react-intl";
import { Typography } from "@lysaab/ui-2";

interface Query {
  inviteId?: string;
  inbjudan?: string;
  email?: string;
}

const checkIfBadAffiliate = async (value: string) => {
  const response = await fetch("https://affiliatelist.lysa.se", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ value: value.toUpperCase() }),
  });

  if (!response.ok) {
    return;
  }

  const data = await response.json();

  if (data.ok) {
    return true;
  } else {
    return false;
  }
};

export function ReferralHandler() {
  const location = useLocation();
  const signupContext = useContext(SignupContext);
  const query = useQuery<Query>();
  const [showBadAffiliate, setShowBadAffiliate] = useState<boolean>(false);

  const handleAffiliate = async (params: URLSearchParams, pathname: string) => {
    const adtractionId = params.get("at_gd");
    const lysaCampaignId = params.get("campaign");
    const financeAdsId = params.get("s_id");

    if (adtractionId) {
      if (pathname === "/dk") {
        generateAffiliateCookie(adtractionId, AffiliateNetwork.ADTRACTION_DK);
        // The ID from Adtraction changes so we cannot know who the ID belongs to that is why we send in just "ADTRACTION" instead
        Plausible.setProp("campaign", AffiliateNetwork.ADTRACTION_DK);
      } else if (pathname === "/fi") {
        generateAffiliateCookie(adtractionId, AffiliateNetwork.ADTRACTION_FI);
        // The ID from Adtraction changes so we cannot know who the ID belongs to that is why we send in just "ADTRACTION" instead
        Plausible.setProp("campaign", AffiliateNetwork.ADTRACTION_FI);
      } else {
        //Should only be sweden
        generateAffiliateCookie(adtractionId, AffiliateNetwork.ADTRACTION);
        // The ID from Adtraction changes so we cannot know who the ID belongs to that is why we send in just "ADTRACTION" instead
        Plausible.setProp("campaign", AffiliateNetwork.ADTRACTION);
      }
    } else if (financeAdsId) {
      generateAffiliateCookie(financeAdsId, AffiliateNetwork.FINANCEADS);
      Plausible.setProp("campaign", AffiliateNetwork.FINANCEADS);
    } else if (lysaCampaignId) {
      const isInList = await checkIfBadAffiliate(lysaCampaignId.toString());

      if (!isInList) {
        setShowBadAffiliate(false);
        generateAffiliateCookie(lysaCampaignId, AffiliateNetwork.LYSA_CAMPAIGN);
        Plausible.setProp("campaign", lysaCampaignId);
      } else {
        setShowBadAffiliate(true);
      }
    }
  };

  // Campaign invite
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    handleAffiliate(params, location.pathname);
  }, [location]);

  // Personal invite
  useEffect(() => {
    if (query.inviteId && !signupContext.state.inviteId) {
      signupContext.setState({ inviteId: query.inviteId });
      Plausible.setProp("campaign", "INVITED");
      ReferralStorage.setInvite(query.inviteId);
    } else if (query.inbjudan && !signupContext.state.inviteId) {
      signupContext.setState({ inviteId: query.inbjudan });
      Plausible.setProp("campaign", "INVITED");
      ReferralStorage.setInvite(query.inbjudan);
    } else if (!signupContext.state.inviteId) {
      const storedInvite = ReferralStorage.getInvite();
      if (storedInvite) {
        signupContext.setState({ inviteId: storedInvite.invite });
        Plausible.setProp("campaign", "INVITED");
      }
    }

    if (query.email && !signupContext.state.email) {
      /* If user flags mail as spam/abuse or unsubscribe, MailChimp will replace it with a “random generated” email and this hinders users from using this email unintentionally */
      if (query.email.indexOf("@forgotten.mailchimp.com") > -1) {
        return;
      }
      signupContext.setState({ email: query.email });
    }
  }, [query.email, query.inbjudan, query.inviteId, signupContext]);

  if (showBadAffiliate) {
    return (
      <Modal
        showModal={showBadAffiliate}
        onClose={() => setShowBadAffiliate(false)}
        header={<FormattedMessage id="bad-affiliate.title" />}
        closeOnOverlayClick
      >
        <FormattedMessage id="bad-affiliate.text" />
        <Typography>
          <a href="mailto:kontakt@lysa.se">
            <FormattedMessage id="bad-affiliate.contact" />
          </a>{" "}
          <FormattedMessage id="bad-affiliate.contact.text" />
        </Typography>
      </Modal>
    );
  } else {
    return null;
  }
}
