import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  Card,
  Button,
  InvestmentType,
  AllocationBarIntl,
  SNACKBAR_TYPES,
  FlashContext,
  Spinner,
  Snackbar,
  ServerError,
} from "@lysaab/ui-2";
import { useIntl, FormattedMessage, defineMessages } from "react-intl";
import { SignupContext, SignupContextProps } from "../../state/SignupContext";

import "./Advice.scss";
import { Disclaimer } from "../../components/Disclaimer";
import {
  GetSuitabilityAssessmentRequest,
  getSuitabilityAssessment,
  GetSuitabilityAssessmentResponse,
} from "../../data/Investments";
import {
  LocalizationContextProps,
  LocalizationContext,
} from "../../state/LocalizationContext";
import { useCountryUrl } from "../../hooks/useCountryUrl";
import {
  EventTracker,
  TrackerEvent,
} from "../../utils/eventTracker/EventTracker";
import { InvestmentTypeIndicator } from "../../components/investmentTypeIndicator/InvestmentTypeIndicator";
import { AdviceInput, useAdvice } from "../../state/AdviceContext";
import { InvestmentBasisLink } from "./investmentBasis/InvestmentBasisLink";
import {
  getAccountQuestions,
  isValidAccountQuestions,
} from "../../data/types/AccountQuestions";
import {
  EligibilityRiskAnswer,
  getEligibilityRiskAnswers,
  isValidEligibilityRiskAnswers,
} from "../../data/types/Eligibility";

export const EDIT_ALLOCATION_ROUTE = "/edit-allocation/";
export const EDIT_ALLOCATION_FEES_ROUTE = "/edit-allocation?fees";

export const ROUTE = "/advice";

const messages = defineMessages({
  allocationBarStocks: { id: "advice.bar.stocks" },
  allocationBarBonds: { id: `advice.bar.fixed_income` },
  retry: { id: "advice.retry" },
  assessmentErrorFlash: { id: "advice.assessment.error.flash" },
});

enum State {
  INITIAL,
  LOADING,
  DONE,
}

interface Props {
  next: () => void;
}

export const Advice: React.FC<Props> = ({ next }) => {
  const intl = useIntl();
  const signupContext = useContext(SignupContext);
  const localizationContext = useContext(LocalizationContext);
  const adviceContext = useAdvice();
  const { pushFlash } = useContext(FlashContext);
  const history = useHistory();
  const [loadingState, setLoadingState] = useState(State.INITIAL);
  const countryUrl = useCountryUrl();
  const [adviceText, setAdviceText] = useState<string>();
  const [advice, setAdvice] = useState<GetSuitabilityAssessmentResponse>();
  const [retryMsg, setRetryMsg] = useState<string>();

  const allocationBarLabels = {
    stocks: (
      <>
        <span className="allocation-bar-value">
          {intl.formatNumber((adviceContext.result.advicedRisk || 0) / 100, {
            style: "percent",
          })}
        </span>
        <span className="allocation-bar-name">
          {intl.formatMessage(messages.allocationBarStocks)}
        </span>
      </>
    ),
    bonds: (
      <>
        <span className="allocation-bar-name">
          {intl.formatMessage(messages.allocationBarBonds)}
        </span>
        <span className="allocation-bar-value">
          {intl.formatNumber(
            (100 - (adviceContext.result.advicedRisk || 0)) / 100,
            { style: "percent" }
          )}
        </span>
      </>
    ),
  };

  useEffect(() => {
    if (loadingState !== State.INITIAL) {
      return;
    }

    const requestData = getSuitabilityAssessmentModel(
      signupContext,
      localizationContext,
      adviceContext.input
    );

    if (!requestData) {
      history.push(countryUrl);
      return;
    }

    setLoadingState(State.LOADING);
    getSuitabilityAssessment(requestData)
      .then((advice) => {
        adviceContext.setResult({
          advicedRisk: advice.advisedRisk,
          investmentType: advice.investmentType,
        });
        setAdviceText(advice.declaration);
        setAdvice(advice);
        setLoadingState(State.DONE);
        EventTracker.track({
          event: TrackerEvent.ADVICE,
          message: { advisedRisk: advice.advisedRisk },
        });
      })
      .catch((error: ServerError<unknown>) => {
        EventTracker.track({
          event: TrackerEvent.ERROR,
          message: error.toString(),
        });
        // We don't get a status when the network is down
        if (!error.status || error.status >= 500) {
          setRetryMsg(intl.formatMessage(messages.retry));
        } else {
          pushFlash({
            type: SNACKBAR_TYPES.ERROR,
            text: intl.formatMessage(messages.assessmentErrorFlash),
          });

          history.push(countryUrl);
          return;
        }
      });
  }, [
    history,
    loadingState,
    localizationContext,
    pushFlash,
    countryUrl,
    signupContext,
    adviceContext,
    intl,
  ]);

  return (
    <div className="advice-page">
      <h1>
        <FormattedMessage id="advice.header" />
      </h1>
      {retryMsg ? (
        <div>
          <Snackbar type={SNACKBAR_TYPES.ERROR}>{retryMsg}</Snackbar>
          <Button
            onClick={() => {
              setRetryMsg(undefined);
              setLoadingState(State.INITIAL);
            }}
            block
            label={<FormattedMessage id="advice.button.retry" />}
          />
        </div>
      ) : adviceContext.result.advicedRisk &&
        adviceContext.result.investmentType ? (
        <>
          <section className="allocation-proposal">
            <h2>
              <FormattedMessage id="allocation.card.allocationbar.header" />
            </h2>
            <AllocationBarIntl
              risk={adviceContext.result.advicedRisk}
              messages={allocationBarLabels}
              data-test-id="allocation-bar"
            />
            <InvestmentTypeIndicator
              investmentType={adviceContext.result.investmentType}
              advicedRisk={adviceContext.result.advicedRisk}
            />

            <div className="allocation-proposal-investment-type">
              {adviceContext.result.investmentType === InvestmentType.BROAD ? (
                <FormattedMessage
                  id="advice.card.investmenttype.broad.body"
                  values={{
                    nbr: (text: React.ReactNode[]) => {
                      if (typeof text === "string") {
                        return intl
                          .formatNumber(Number.parseInt(text, 10))
                          .replace(/ /g, "&nbsp;");
                      }

                      return text;
                    },
                  }}
                />
              ) : (
                <FormattedMessage
                  id="advice.card.investmenttype.sustainable.body"
                  values={{
                    nbr: (text: React.ReactNode[]) => {
                      if (typeof text === "string") {
                        return intl
                          .formatNumber(Number.parseInt(text, 10))
                          .replace(/ /g, "&nbsp;");
                      }

                      return text;
                    },
                  }}
                />
              )}
            </div>
          </section>

          <section>
            <Button
              onClick={() => {
                adviceContext.setResult({
                  takenRisk: adviceContext.result.advicedRisk,
                });
                next();
              }}
              block
              data-test-id="advice-next"
              label={<FormattedMessage id="advice.button.next" />}
            />

            <Button
              variant="secondary"
              block
              type="button"
              onClick={() => history.push(countryUrl + EDIT_ALLOCATION_ROUTE)}
              label={<FormattedMessage id="advice.button.change.allocation" />}
            />
            <Button
              variant="secondary"
              block
              type="button"
              onClick={() =>
                history.push(countryUrl + EDIT_ALLOCATION_FEES_ROUTE)
              }
              label={<FormattedMessage id="advice.button.details" />}
            />
          </section>
          <section>
            <Card className="box-margin">
              <h4>
                <FormattedMessage id="advice.declaration.heading" />
              </h4>
              <p data-test-id="advice-text">{adviceText}</p>
            </Card>
          </section>
          <div className="pdf-link">
            {typeof advice !== "undefined" && (
              <InvestmentBasisLink esgResult={advice.esgResult} />
            )}
          </div>
          <Disclaimer />
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export function getSuitabilityAssessmentModel(
  signupContext: SignupContextProps,
  localizationContext: LocalizationContextProps,
  adviceInput: AdviceInput
): GetSuitabilityAssessmentRequest | undefined {
  const accountQuestions = getAccountQuestions(adviceInput);
  const eligibilityRiskAnswers = getEligibilityRiskAnswers(adviceInput);

  if (
    typeof localizationContext.state.country === "undefined" ||
    typeof signupContext.state.email === "undefined" ||
    !isValidAccountQuestions(accountQuestions) ||
    !isValidEligibilityRiskAnswers(eligibilityRiskAnswers)
  ) {
    return;
  }

  return {
    country: localizationContext.state.country,
    language: localizationContext.state.language,
    tracingReference: "optional",
    financial: signupContext.state.financial,
    riskAnswers: [
      eligibilityRiskAnswers[EligibilityRiskAnswer.IMPORTANCE],
      eligibilityRiskAnswers[EligibilityRiskAnswer.PROPENSITY],
      eligibilityRiskAnswers[EligibilityRiskAnswer.REACTION],
    ],
    ...accountQuestions,
  };
}
