import React from "react";
import { useIntl } from "react-intl";
import { messages } from "./InvestmentBasisText";
import { Typography } from "@lysaab/ui-2";

interface Props {
  advisedRisk: number;
}

export const Advice: React.FC<Props> = ({ advisedRisk }) => {
  const intl = useIntl();

  return (
    <div>
      <Typography type="h2">
        {intl.formatMessage(messages.suitabilityAdviceHeader)}
      </Typography>
      <Typography>
        {intl.formatMessage(messages.suitabilityAdviceText, {
          stocks: advisedRisk / 100,
          bonds: (100 - advisedRisk) / 100,
        })}
      </Typography>
    </div>
  );
};
