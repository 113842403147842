import React, { VoidFunctionComponent } from "react";
import { RadioGroupCard, RequiredValidator } from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { ExpandableContent } from "../../components/expandableContent/ExpandableContent";
import { useAdvice } from "../../state/AdviceContext";
import {
  getSustainabilityPreferenceAltText,
  getSustainabilityPreferenceDescriptionText,
  getSustainabilityPreferenceExpandDescriptionText,
  getSustainabilityPreferenceQuestionText,
} from "./SustainabilityPreferenceText";
import {
  SustainabilityImportance,
  SustainabilityPreference,
} from "../../data/types/SustainabilityQuestions";

export const SustainabilityPreferenceQuestion: VoidFunctionComponent = () => {
  const intl = useIntl();
  const advice = useAdvice();

  if (advice.input.sustainability !== SustainabilityImportance.IMPORTANT) {
    return null;
  }

  const alternatives = Object.values(SustainabilityPreference).map(
    (key: SustainabilityPreference) => {
      return {
        header: getSustainabilityPreferenceAltText(intl, key),
        description: getSustainabilityPreferenceDescriptionText(intl, key),
        expandableContent: (
          <ExpandableContent>
            <ul>
              {Object.values(
                getSustainabilityPreferenceExpandDescriptionText(intl, key)
              ).map((item, index) => {
                return (
                  <li key={`selection-expandable-content-${index}`}>{item}</li>
                );
              })}
            </ul>
          </ExpandableContent>
        ),
        value: key,
      };
    }
  );

  return (
    <RadioGroupCard
      data-test-id="sustainability-preference-choice"
      legend={<h1>{getSustainabilityPreferenceQuestionText(intl)}</h1>}
      alternatives={alternatives}
      value={advice.input.sustainabilityPreference}
      onChange={(sustainabilityPreference) =>
        advice.setInput({ sustainabilityPreference })
      }
      validators={[
        new RequiredValidator(
          intl.formatMessage({ id: "esg.preference.required" })
        ),
      ]}
    />
  );
};
