export enum SustainabilityImportance {
  NOT_IMPORTANT = "NOT_IMPORTANT",
  IMPORTANT = "IMPORTANT",
}

export enum SustainabilityPreference {
  NONE = "NONE",
  SPECIFIC = "SPECIFIC",
}

export enum PAIImportance {
  NOT_IMPORTANT = "NOT_IMPORTANT",
  IMPORTANT = "IMPORTANT",
}

export enum SFDRImportance {
  NONE = "NONE",
  LEAST_25 = "LEAST_25",
  LEAST_50 = "LEAST_50",
  LEAST_75 = "LEAST_75",
}

export enum TaxonomyImportance {
  NONE = "NONE",
  LEAST_25 = "LEAST_25",
  LEAST_50 = "LEAST_50",
  LEAST_75 = "LEAST_75",
}

export const isSustainabilityImportance = (
  value?: unknown
): value is SustainabilityImportance => {
  return (
    typeof value === "string" &&
    Object.values(SustainabilityImportance).some((v) => value === v)
  );
};

export const isSustainabilityPreference = (
  value?: unknown
): value is SustainabilityPreference => {
  return (
    typeof value === "string" &&
    Object.values(SustainabilityPreference).some((v) => value === v)
  );
};

export const isPAIImportance = (value: unknown): value is PAIImportance => {
  return (
    typeof value === "string" &&
    Object.values(PAIImportance).some((v) => value === v)
  );
};

export const isSFDRImportance = (value: unknown): value is SFDRImportance => {
  return (
    typeof value === "string" &&
    Object.values(SFDRImportance).some((v) => value === v)
  );
};

export const isTaxonomyImportance = (
  value?: unknown
): value is TaxonomyImportance => {
  return (
    typeof value === "string" &&
    Object.values(TaxonomyImportance).some((v) => value === v)
  );
};

/** Sustainability **/

export interface SustainabilityQuestionsNotImportant {
  sustainability: SustainabilityImportance.NOT_IMPORTANT;
}

export interface SustainabilityQuestionsImportantNone {
  sustainability: SustainabilityImportance.IMPORTANT;
  sustainabilityPreference: SustainabilityPreference.NONE;
}

export interface SustainabilityQuestionsImportantSpecific extends EsgQuestions {
  sustainability: SustainabilityImportance.IMPORTANT;
  sustainabilityPreference: SustainabilityPreference.SPECIFIC;
}

export interface EsgQuestions {
  pai: PAIImportance;
  sfdr: SFDRImportance;
  taxonomy: TaxonomyImportance;
}

export function isValidEsgQuestions(data: unknown): data is EsgQuestions {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (!("pai" in data) || !isPAIImportance(data.pai)) {
    return false;
  }

  if (!("sfdr" in data) || !isSFDRImportance(data.sfdr)) {
    return false;
  }

  if (!("taxonomy" in data) || !isTaxonomyImportance(data.taxonomy)) {
    return false;
  }

  return true;
}

export function isEsgQuestionsUpdate(
  currentQuestions: Partial<SustainabilityQuestions>,
  previousQuestions: Partial<SustainabilityQuestions>
): boolean {
  const [current, previous] = [currentQuestions, previousQuestions].map(
    (esgQuestions) => {
      if (isSustainabilityImportantSpecific(esgQuestions)) {
        return JSON.stringify({
          pai: esgQuestions.pai,
          sfdr: esgQuestions.sfdr,
          taxonomy: esgQuestions.taxonomy,
        });
      }
      return JSON.stringify({});
    }
  );

  return current !== previous;
}

export type SustainabilityQuestions =
  | SustainabilityQuestionsNotImportant
  | SustainabilityQuestionsImportantNone
  | SustainabilityQuestionsImportantSpecific;

export function isSustainabilityNotImportant(
  data: unknown
): data is SustainabilityQuestionsNotImportant {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (
    !("sustainability" in data) ||
    data.sustainability !== SustainabilityImportance.NOT_IMPORTANT
  ) {
    return false;
  }

  return true;
}

export function isSustainabilityImportantNone(
  data: unknown
): data is SustainabilityQuestionsImportantNone {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (
    !("sustainability" in data) ||
    data.sustainability !== SustainabilityImportance.IMPORTANT
  ) {
    return false;
  }

  if (
    !("sustainabilityPreference" in data) ||
    data.sustainabilityPreference !== SustainabilityPreference.NONE
  ) {
    return false;
  }

  return true;
}

export function isSustainabilityImportantSpecific(
  data: unknown
): data is SustainabilityQuestionsImportantSpecific {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (
    !("sustainability" in data) ||
    data.sustainability !== SustainabilityImportance.IMPORTANT
  ) {
    return false;
  }

  if (
    !("sustainabilityPreference" in data) ||
    data.sustainabilityPreference !== SustainabilityPreference.SPECIFIC
  ) {
    return false;
  }

  return true;
}

export function isValidSustainabilityNotImportant(
  data: unknown
): data is SustainabilityQuestionsNotImportant {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (
    !("sustainability" in data) ||
    data.sustainability !== SustainabilityImportance.NOT_IMPORTANT
  ) {
    return false;
  }

  return true;
}

export function isValidSustainabilityImportantNone(
  data: unknown
): data is SustainabilityQuestionsImportantNone {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (
    !("sustainability" in data) ||
    data.sustainability !== SustainabilityImportance.IMPORTANT
  ) {
    return false;
  }

  if (
    !("sustainabilityPreference" in data) ||
    data.sustainabilityPreference !== SustainabilityPreference.NONE
  ) {
    return false;
  }

  return true;
}

export function isValidSustainabilityImportantSpecific(
  data: unknown
): data is SustainabilityQuestionsImportantSpecific {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (
    !("sustainability" in data) ||
    data.sustainability !== SustainabilityImportance.IMPORTANT
  ) {
    return false;
  }

  if (
    !("sustainabilityPreference" in data) ||
    data.sustainabilityPreference !== SustainabilityPreference.SPECIFIC
  ) {
    return false;
  }

  if (!isValidEsgQuestions(data)) {
    return false;
  }

  return true;
}

export function isSustainabilityUpdated(
  data: Partial<SustainabilityQuestions>,
  oldData: Partial<SustainabilityQuestions>
): boolean {
  const [sustainability, oldSustainability] = [data, oldData].map(
    (sustainabilityQuestions) => {
      if (isSustainabilityNotImportant(sustainabilityQuestions)) {
        return {
          sustainability: sustainabilityQuestions.sustainability,
        };
      }

      if (isSustainabilityImportantNone(sustainabilityQuestions)) {
        return {
          sustainability: sustainabilityQuestions.sustainability,
          sustainabilityPreference:
            sustainabilityQuestions.sustainabilityPreference,
        };
      }

      if (isSustainabilityImportantSpecific(sustainabilityQuestions)) {
        return {
          sustainability: sustainabilityQuestions.sustainability,
          sustainabilityPreference:
            sustainabilityQuestions.sustainabilityPreference,
          pai: sustainabilityQuestions.pai,
          sfdr: sustainabilityQuestions.sfdr,
          taxonomy: sustainabilityQuestions.taxonomy,
        };
      }

      return {};
    }
  );

  return JSON.stringify(sustainability) !== JSON.stringify(oldSustainability);
}

export function isValidSustainability(
  data: unknown
): data is SustainabilityQuestions {
  return (
    isValidSustainabilityNotImportant(data) ||
    isValidSustainabilityImportantNone(data) ||
    isValidSustainabilityImportantSpecific(data)
  );
}

export function getValidSustainability(data: unknown): SustainabilityQuestions {
  if (!isValidSustainability(data)) {
    throw new Error(
      "getValidSustainability - data didn't fulfill requirements for being SustainabilityQuestions"
    );
  }

  if (isValidSustainabilityNotImportant(data)) {
    return {
      sustainability: data.sustainability,
    };
  }

  if (isValidSustainabilityImportantNone(data)) {
    return {
      sustainability: data.sustainability,
      sustainabilityPreference: data.sustainabilityPreference,
    };
  }

  if (isValidSustainabilityImportantSpecific(data)) {
    return {
      sustainability: data.sustainability,
      sustainabilityPreference: data.sustainabilityPreference,
      pai: data.pai,
      sfdr: data.sfdr,
      taxonomy: data.taxonomy,
    };
  }

  throw new Error("getAccountQuestions - failed to match any sustainability");
}
