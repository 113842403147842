import React, { useEffect, useContext } from "react";
import { IntlProvider, defineMessages, useIntl } from "react-intl";
import svMessages from "./locales/sv.json";
import enMessages from "./locales/en.json";
import deMessages from "./locales/de.json";
import { Language, LocalizationContext } from "./state/LocalizationContext";
import { Helmet } from "react-helmet";
import { useActivityTracker } from "./hooks/useActivityTracker";
import { enGB, sv, de } from "date-fns/esm/locale";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { ErrorBoundary } from "@lysaab/ui-2";
import * as Sentry from "@sentry/browser";

/**
 * Setup locales for all datepickers. We use the country code
 * as the key for the datepicker locale data
 */
registerLocale(Language.SWEDISH, sv);
registerLocale(Language.ENGLISH, enGB);
registerLocale(Language.GERMAN, de);

declare global {
  interface Window {
    setLanguage?: (lang: Language) => void;
  }
}

const messages = defineMessages({
  title: { id: "title" },
  description: { id: "description" },
  errorBoundaryHeader: { id: "error.boundary.header" },
  errorBoundaryText: { id: "error.boundary.text" },
});

const languagePacks: Record<Language, unknown> = {
  [Language.ENGLISH]: enMessages,
  [Language.SWEDISH]: svMessages,
  [Language.GERMAN]: deMessages,
};

const DEFAULT_LOCALE = "en";

const ApplicationBase: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);
  useActivityTracker();

  return (
    <ErrorBoundary
      header={intl.formatMessage(messages.errorBoundaryHeader)}
      text={intl.formatMessage(messages.errorBoundaryText)}
      onCatch={(error, errorInfo) => {
        Sentry.withScope((scope) => {
          if (errorInfo) {
            // @ts-expect-error Could probably type this better in the ErrorBoundary
            scope.setExtras(errorInfo);
          }
          Sentry.captureException(error);
        });
      }}
    >
      <Helmet
        title={intl.formatMessage(messages.title)}
        meta={[
          {
            name: "description",
            content: intl.formatMessage(messages.description),
          },
        ]}
        htmlAttributes={{
          lang: localizationContext.state.language,
        }}
      />
      {children}
    </ErrorBoundary>
  );
};

export const Localization: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const localizationContext = useContext(LocalizationContext);

  useEffect(() => {
    /**
     * Change datepicker locale when language is changed
     */
    setDefaultLocale(localizationContext.state.language);
  }, [localizationContext.state.language]);

  useEffect(() => {
    window.setLanguage = (lang: Language) => {
      localizationContext.setState({ language: lang });
    };
    return () => {
      delete window.setLanguage;
    };
  }, [localizationContext]);

  return (
    <IntlProvider
      locale={localizationContext.getLocale()}
      defaultLocale={DEFAULT_LOCALE}
      messages={languagePacks[localizationContext.state.language] as any}
    >
      <ApplicationBase>{children}</ApplicationBase>
    </IntlProvider>
  );
};
