import { API, encode } from "@lysaab/ui-2";

export enum AffiliateNetwork {
  ADTRACTION = "ADTRACTION", // Swedish adtraction
  ADTRACTION_DK = "ADTRACTION_DK",
  ADTRACTION_FI = "ADTRACTION_FI",
  LYSA_CAMPAIGN = "LYSA_CAMPAIGN",
  FINANCEADS = "FINANCEADS",
}

export function generateAffiliateCookie(
  trackerId: string,
  network: AffiliateNetwork
) {
  return API.get(
    encode`/affiliate/tracker-id?trackerId=${trackerId}&affiliateNetwork=${network}`
  );
}
