import React, { FunctionComponent, useContext, useState } from "react";
import { AccountQuestions } from "../data/types/AccountQuestions";
import { EligibilityRiskAnswers } from "../data/types/Eligibility";
import { InvestmentType } from "@lysaab/ui-2";

export type AdviceState = {
  input: AdviceInput;
  result: AdviceResult;
};

export type AdviceInput = Partial<AccountQuestions> &
  Partial<EligibilityRiskAnswers> & {};

export interface AdviceResult {
  /** Investment type */
  investmentType?: InvestmentType;

  /** Advice */
  takenRisk?: number;
  advicedRisk?: number;
}

const defaultState: AdviceState = {
  input: {},
  result: {},
};

export interface AdviceContextProps {
  input: AdviceInput;
  result: AdviceResult;
  setInput(newInput: Partial<AdviceInput>): void;
  setResult(newResult: Partial<AdviceResult>): void;
}

const AdviceContext = React.createContext<AdviceContextProps | undefined>(
  undefined
);

export const AdviceContextProvider: FunctionComponent<
  React.PropsWithChildren<unknown>
> = React.memo(({ children }) => {
  const [{ input, result }, setState] = useState(defaultState);

  function setInput(input: Partial<AdviceInput>) {
    setState((prev) => ({
      input: { ...prev.input, ...input } as AdviceInput,
      result: {},
    }));
  }

  function setResult(result: Partial<AdviceResult>) {
    setState((prev) => ({
      input: { ...prev.input },
      result: { ...prev.result, ...result },
    }));
  }

  return (
    <AdviceContext.Provider
      value={{
        input,
        result,
        setInput,
        setResult,
      }}
    >
      {children}
    </AdviceContext.Provider>
  );
});

export function useAdvice(): AdviceContextProps {
  const context = useContext(AdviceContext);

  if (!context) {
    throw new Error("useAdvice must be used within a AdviceContextProvider");
  }

  return context;
}
