import { useContext } from "react";
import { RequiredValidator, CountrySelect, WorldCountry } from "@lysaab/ui-2";
import { SignupContext } from "../../../state/SignupContext";
import { useIntl } from "react-intl";
import { LocalizationContext } from "../../../state/LocalizationContext";
import { countrySelectMessages } from "../../../pages/crs/CrsMessages";

export const CrsCountrySelect: React.VFC = () => {
  const intl = useIntl();
  const signupContext = useContext(SignupContext);
  const localizationContext = useContext(LocalizationContext);

  return (
    <CountrySelect
      language={localizationContext.state.language}
      label={intl.formatMessage(countrySelectMessages.residenceCountryLabel)}
      placeholder={intl.formatMessage(
        countrySelectMessages.residenceCountryPlaceholder
      )}
      value={signupContext.state.crsResidenceCountry}
      onChange={({ value }) => {
        signupContext.setState({
          crsResidenceCountry: value,
        });
      }}
      validators={[
        new RequiredValidator(
          intl.formatMessage(countrySelectMessages.residenceCountryRequired)
        ),
      ]}
      omitList={[WorldCountry.UNITED_STATES_OF_AMERICA]}
    />
  );
};
