import { Button } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import "./IntroSubmitButton.scss";

export const IntroSubmitButton: React.VFC = () => {
  return (
    <Button
      block
      type="submit"
      className="germany-intro-submit-button"
      label={<FormattedMessage id="germany.intro.get-started" />}
    />
  );
};
