interface Step {
  step: number;
  stop: number;
}

export function generateTranslatedArray(intervals: Step[]) {
  if (intervals === undefined || intervals.length === 0) {
    throw new Error("Parameter is undefined or without items");
  }
  let res = [0];
  let intervalIndex = 0;
  let intervalLength = intervals.length;
  let sum = 0;
  for (intervalIndex = 0; intervalIndex < intervalLength; intervalIndex++) {
    let interval = intervals[intervalIndex];
    let step = interval.step;
    let stop = interval.stop;
    while (sum < stop) {
      sum += step;
      res.push(sum);
    }
  }
  return res;
}

export const moneyList = generateTranslatedArray([
  {
    step: 10000,
    stop: 1000000
  },
  {
    step: 50000,
    stop: 6000000
  },
  {
    step: 100000,
    stop: 10000000
  }
]);
