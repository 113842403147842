import { LysaCountry } from "@lysaab/shared";
import { CardList } from "@lysaab/ui-2";
import React from "react";
import { CountrySelectItem } from "./countrySelectItem/CountrySelectItem";

interface Item {
  country: LysaCountry;
  url: string;
}

interface Props {
  items: Item[];
  header: string;
}

export function CountrySelectCard({ items, header }: Props) {
  return (
    <div className="country-select">
      <h1>{header}</h1>
      <CardList>
        {items.map((item) => {
          return (
            <CountrySelectItem
              country={item.country}
              url={item.url}
              key={item.country}
            />
          );
        })}
      </CardList>
    </div>
  );
}
