import { defineMessages, IntlShape } from "react-intl";
import { TaxonomyImportance } from "../../../data/types/SustainabilityQuestions";

const taxonomyQuestionMessages = defineMessages({
  question: { id: "esg.taxonomy.header" },
});

const taxonomyAltMessages = defineMessages<TaxonomyImportance>({
  [TaxonomyImportance.NONE]: {
    id: "esg.taxonomy.alt.NONE",
  },
  [TaxonomyImportance.LEAST_25]: {
    id: "esg.taxonomy.alt.LEAST_25",
  },
  [TaxonomyImportance.LEAST_50]: {
    id: "esg.taxonomy.alt.LEAST_50",
  },
  [TaxonomyImportance.LEAST_75]: {
    id: "esg.taxonomy.alt.LEAST_75",
  },
});

export function getTaxonomyQuestionText(intl: IntlShape) {
  return intl.formatMessage(taxonomyQuestionMessages.question);
}

export function getTaxonomyAltText(intl: IntlShape, key: TaxonomyImportance) {
  return intl.formatMessage(taxonomyAltMessages[key]);
}
