import { Card, Icon } from "@lysaab/ui-2";
import React from "react";
import { FormattedMessage } from "react-intl";

export const IntroList: React.VFC = () => (
  <div className="intro-list-denmark">
    <p>
      <FormattedMessage id="denmark.intro.paragraph" />
    </p>
    <Card>
      <ol>
        <li>
          <div>
            <Icon.Chart />
          </div>
          <div>
            <FormattedMessage id="denmark.intro.list.proposal" />
          </div>
        </li>
        <li>
          <div>
            <Icon.Add />
          </div>
          <div>
            <FormattedMessage id="denmark.intro.list.openaccount" />
          </div>
        </li>
        <li>
          <div>
            <Icon.User />
          </div>
          <div>
            <FormattedMessage id="denmark.intro.list.identify" />
          </div>
        </li>
        <li>
          <div>
            <Icon.Deposit />
          </div>
          <div>
            <FormattedMessage id="denmark.intro.list.deposit" />
          </div>
        </li>
      </ol>
    </Card>
  </div>
);
