import React, { useContext, useRef, useEffect } from "react";
import { LysaCountry } from "@lysaab/shared";
import {
  Card,
  Button,
  LysaFormRef,
  Form,
  DanishTin,
  FinnishTin,
  SwedishTin,
  AllTinTypes,
  GermanTin,
  WorldCountry,
  SpanishTin,
} from "@lysaab/ui-2";
import { SignupContext } from "../../state/SignupContext";
import { LocalizationContext } from "../../state/LocalizationContext";
import "./Crs.scss";
import { CrsReportingCountries } from "./CrsReportingCountries";
import { CrsCountrySelect } from "./CrsCountrySelect";
import { CrsName } from "./CrsName";
import { CrsAddress } from "./CrsAddress";
import { CrsBirthday } from "./CrsBirthday";
import { useIntl } from "react-intl";
import { crsMessages } from "./CrsMessages";

interface Props {
  birthdayField?: boolean;
  next: () => void;
}

export const ROUTE = "/crs";

const countryTinParserMap: Record<LysaCountry, AllTinTypes> = {
  [LysaCountry.DENMARK]: DanishTin,
  [LysaCountry.FINLAND]: FinnishTin,
  [LysaCountry.SWEDEN]: SwedishTin,
  [LysaCountry.GERMANY]: GermanTin,
  [LysaCountry.SPAIN]: SpanishTin,
};

export const Crs: React.FC<Props> = ({ birthdayField = false, next }) => {
  const signupContext = useContext(SignupContext);
  const localizationContext = useContext(LocalizationContext);
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();

  useEffect(() => {
    if (
      !signupContext.state.crsReportingCountries &&
      localizationContext.state.country
    ) {
      signupContext.setState({
        crsReportingCountries: [
          {
            tin: "",
            country:
              localizationContext.state.country.toString() as WorldCountry,
          },
        ],
      });
    }
  }, [signupContext, localizationContext.state.country]);

  return (
    <div className="crs">
      <Form
        lysaFormRef={formRef}
        onSubmit={(event) => {
          event.preventDefault();

          let crsBirthday = signupContext.state.crsBirthday;

          if (!birthdayField) {
            const firstCountry = signupContext.state.crsReportingCountries?.[0];

            const tinParserCountry =
              firstCountry?.country || localizationContext.state.country;
            const TinParser = tinParserCountry
              ? countryTinParserMap[tinParserCountry as LysaCountry]
              : undefined;

            if (TinParser && firstCountry?.tin) {
              crsBirthday = new TinParser(firstCountry.tin).getBirthDate();
              signupContext.setState({ crsBirthday });
            }

            if (!crsBirthday) {
              console.error(
                "Could not parse birthday from tin",
                firstCountry?.tin
              );
            }
          }

          if (
            formRef.current?.isValid &&
            signupContext.state.crsReportingCountries &&
            crsBirthday
          ) {
            next();
          }
        }}
      >
        <h1>{intl.formatMessage(crsMessages.header)}</h1>

        <Card>
          <CrsReportingCountries validateAge={!birthdayField} />
          <CrsCountrySelect />
          <CrsName />
          <CrsAddress />
          {birthdayField && <CrsBirthday />}
        </Card>
        <p>{intl.formatMessage(crsMessages.buttonNextConfirmText)}</p>
        <Button
          type="submit"
          block
          label={intl.formatMessage(crsMessages.buttonNext)}
        />
      </Form>
    </div>
  );
};
