import React from "react";
import { useIntl } from "react-intl";
import { messages } from "./InvestmentBasisText";
import { Typography } from "@lysaab/ui-2";

export const Header: React.FC = () => {
  const intl = useIntl();

  return (
    <div>
      <Typography>{intl.formatMessage(messages.suitabilityIntro)}</Typography>

      <Typography>{intl.formatMessage(messages.suitabilityP2)}</Typography>
    </div>
  );
};
