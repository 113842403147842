import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Typography } from "@lysaab/ui-2";
import { useLocalizationContext } from "../../state/LocalizationContext";
import { GetFeesResponse } from "../../data/fees";
import { simulateFutureWorthAndFee as _simulateFutureWorthAndFee } from "./feeHelpers";
import { FormattedPercentage } from "../formattedPercentage/FormattedPercentage";
import { Amount } from "../amount/Amount";

/*
 * COMPATIBILITY CODE
 * Below are some typing and function wrapping to make the component code compatible with
 * the same code in lysa-web-app.
 * The component is copy paste from lysa-web-app, and to not having to change any busines logic
 * when copy-pasting over to signup-customer we make it compatible by wrapping functions and extending types.
 *
 * When copying code from lysa-web-app to signup-customer this block will need to be kept and some import paths
 * needs to be adjusted.
 */
type SimulationParams = Parameters<typeof _simulateFutureWorthAndFee>[0] & {
  yearlyInsuranceFeePercentage?: number;
  yearlyInsuranceRiskFeePercentage?: number;
};

const simulateFutureWorthAndFee = (
  params: SimulationParams
): ReturnType<typeof _simulateFutureWorthAndFee> => {
  return _simulateFutureWorthAndFee(params);
};

interface DummyInvestmentAccount {
  worth: number;
}

interface DummySavingsAccount {
  totalBalance: number;
}

export interface DummyAllAccountResponse {
  investmentAccounts: DummyInvestmentAccount[];
  savingsAccounts: DummySavingsAccount[];
}

type GetFeesResponseWithDummies = GetFeesResponse & {
  rebateExpiry?: string;
  cost: GetFeesResponse["cost"] & {
    insurancePremium?: number;
    insuranceRiskPremium?: number;
  };
};
/*
 * END OF COMPATIBILITY CODE
 */

interface Props {
  horizonYears: number;
  amount: number;
  monthly: number;
  expectedYield: number;
  accounts: DummyAllAccountResponse;
  fees: GetFeesResponseWithDummies;
}

export const FeesInformationSimulation: FC<Props> = ({
  horizonYears,
  amount,
  monthly,
  expectedYield,
  accounts,
  fees,
}) => {
  const intl = useIntl();
  const localizationContext = useLocalizationContext();

  if (!localizationContext.state.country) {
    return null;
  }

  const customerAum =
    accounts.investmentAccounts.reduce(
      (totalWorth, account) => totalWorth + account.worth,
      0
    ) +
    accounts.savingsAccounts.reduce(
      (totalWorth, account) => totalWorth + account.totalBalance,
      0
    );

  // We don't care about fees.future because we make our own calculations.
  // So we use fees.cost, which is in percentage, like 0.12%
  // fees.rebase is expressed in basis points, so we divide it by 100 to get it
  // in percentage instead
  const fundManagementPercentage = fees.cost.fundManagement;
  const fundsPercentage = fees.cost.fundAssets;
  const transactionsPercentage = fees.cost.transactionFees;
  const insurancePercentage = fees.cost.insurancePremium ?? 0;
  const insuranceRiskPercentage = fees.cost.insuranceRiskPremium ?? 0;

  const simulated = simulateFutureWorthAndFee({
    initialInvestment: amount,
    monthlyInvestment: monthly,
    years: horizonYears,
    expectedYearlyYield: expectedYield,
    yearlyFundFeePercentage: fundsPercentage,
    yearlyTransactionFeePercentage: transactionsPercentage,
    yearlyLysaFundManagementFeePercentage: fundManagementPercentage,
    yearlyInsuranceFeePercentage: insurancePercentage,
    yearlyInsuranceRiskFeePercentage: insuranceRiskPercentage,
    country: localizationContext.state.country,
    customerAum,
    discountExpiry: fees.rebateExpiry ? new Date(fees.rebateExpiry) : undefined,
  });
  const lysaMinPercentage = fundManagementPercentage + simulated.minLysaFee;
  const lysaMaxPercentage = fundManagementPercentage + simulated.maxLysaFee;
  const minTotalPercentage =
    lysaMinPercentage +
    fundsPercentage +
    transactionsPercentage +
    insurancePercentage +
    insuranceRiskPercentage;
  const maxTotalPercentage =
    lysaMaxPercentage +
    fundsPercentage +
    transactionsPercentage +
    insurancePercentage +
    insuranceRiskPercentage;
  const lysaCost =
    simulated.totalFees.discretionaryFee +
    simulated.totalFees.lysaFundManagementFee;
  const fundsCost = simulated.totalFees.fundFee;
  const transactionsCost = simulated.totalFees.transactionFee;
  const worthWithoutFees = simulated.worth + simulated.fee;

  return (
    <>
      <section className="feeRows">
        <h4>
          <FormattedMessage id="feesInformation.card.expectedValue.header" />
        </h4>
        <div className="feeRow">
          <div className="label">
            <Typography type="label">
              <FormattedMessage id="feesInformation.card.expectedValue.includingFees" />
            </Typography>
          </div>
          <Amount amount={simulated.worth} />
        </div>
        <div className="feeRow">
          <div className="label">
            <Typography type="label">
              <FormattedMessage id="feesInformation.card.expectedValue.excludingFees" />
            </Typography>
          </div>
          <Amount amount={worthWithoutFees} />
        </div>
        <h4>
          <FormattedMessage
            id="feesInformation.card.expectedFees.header"
            values={{
              horizonYears: intl.formatNumber(horizonYears, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }),
            }}
          />
        </h4>
        <div className="feeRow">
          <div className="label">
            <Typography type="label">
              <FormattedMessage id="feesInformation.card.expectedFees.lysa" />
              {": "}
              {lysaMinPercentage === lysaMaxPercentage ? (
                <FormattedPercentage value={lysaMinPercentage} decimals={3} />
              ) : (
                <>
                  <FormattedPercentage value={lysaMinPercentage} decimals={3} />
                  {" - "}
                  <FormattedPercentage value={lysaMaxPercentage} decimals={3} />
                </>
              )}
            </Typography>
            <Typography type="body-small" className="explanation">
              <FormattedMessage id="feesInformation.card.expectedFees.lysa.explanation" />
            </Typography>
          </div>
          <div className="value">
            <Amount amount={lysaCost} />
          </div>
        </div>
        <div className="feeRow">
          <div className="label">
            <Typography type="label">
              <FormattedMessage id="feesInformation.card.expectedFees.funds" />
              {": "}
              <FormattedPercentage value={fundsPercentage} decimals={3} />
            </Typography>
            <Typography type="body-small" className="explanation">
              <FormattedMessage id="feesInformation.card.expectedFees.funds.explanation" />
            </Typography>
          </div>
          <div className="value">
            <Amount amount={fundsCost} />
          </div>
        </div>
        <div className="feeRow">
          <div className="label">
            <Typography type="label">
              <FormattedMessage id="feesInformation.card.expectedFees.transactions" />
              {": "}
              <FormattedPercentage
                value={transactionsPercentage}
                decimals={3}
              />
            </Typography>
            <Typography type="body-small" className="explanation">
              <FormattedMessage id="feesInformation.card.expectedFees.transactions.explanation" />
            </Typography>
          </div>
          <Typography type="label" className="value">
            <Amount amount={transactionsCost} />
          </Typography>
        </div>
        <div className="feeRow">
          <div className="label">
            <Typography type="label">
              <FormattedMessage id="feesInformation.card.expectedFees.total" />
              {": "}
              {minTotalPercentage === maxTotalPercentage ? (
                <FormattedPercentage value={minTotalPercentage} decimals={3} />
              ) : (
                <>
                  <FormattedPercentage
                    value={minTotalPercentage}
                    decimals={3}
                  />
                  {" - "}
                  <FormattedPercentage
                    value={maxTotalPercentage}
                    decimals={3}
                  />
                </>
              )}
            </Typography>
          </div>
          <div className="value">
            <Amount amount={simulated.fee} />
          </div>
        </div>
      </section>
      <section className="feeExplanation">
        <Typography type="body-small">
          <FormattedMessage id="feesInformation.card.disclaimer.newIsk" />
        </Typography>
      </section>
    </>
  );
};
