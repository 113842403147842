import { LysaCountry } from "@lysaab/shared";
import {
  AnimateHeight,
  Button,
  Form,
  LysaFormRef,
  Snackbar,
  SNACKBAR_TYPES,
} from "@lysaab/ui-2";
import React, { VoidFunctionComponent, useRef, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { useAdvice } from "../../state/AdviceContext";
import { LocalizationContext } from "../../state/LocalizationContext";
import { SustainabilityQuestion } from "./SustainabilityQuestion";
import { SustainabilityImportance } from "../../data/types/SustainabilityQuestions";

interface Props {
  next: () => void;
}

export const ROUTE = "/sustainability";

export const Sustainability: VoidFunctionComponent<Props> = ({ next }) => {
  const formRef = useRef<LysaFormRef>();
  const localizationContext = useContext(LocalizationContext);
  const advice = useAdvice();
  const country = localizationContext.state.country;

  const handleSubmit = () => {
    if (formRef.current?.isValid) {
      if (
        advice.input.sustainability === SustainabilityImportance.IMPORTANT &&
        country !== LysaCountry.SWEDEN
      ) {
        return;
      }
      next();
    }
  };

  const showNotAvailableWarning =
    advice.input.sustainability === SustainabilityImportance.IMPORTANT &&
    country !== LysaCountry.SWEDEN;

  return (
    <Form lysaFormRef={formRef} onSubmit={handleSubmit}>
      <div>
        <SustainabilityQuestion />
        <AnimateHeight isOpen={showNotAvailableWarning}>
          {showNotAvailableWarning && (
            <Snackbar type={SNACKBAR_TYPES.ERROR}>
              <FormattedMessage id="sustainability.notAvailableWarning" />
            </Snackbar>
          )}
        </AnimateHeight>
        <Button
          block
          type="submit"
          data-test-id="sustainability-next-button"
          label={<FormattedMessage id="sustainability.next" />}
        />
      </div>
    </Form>
  );
};
