import { LysaCountry } from "@lysaab/shared";
import { InvestmentType, API } from "@lysaab/ui-2";
import { Language } from "../state/LocalizationContext";
import { EsgQuestions } from "./types/SustainabilityQuestions";
import { AccountQuestions } from "./types/AccountQuestions";
import {
  EligibilityFinancials,
  EligibilityRiskAnswer,
  EligibilityRiskAnswers,
} from "./types/Eligibility";

export type Isin = string & { readonly isIsin: true };

/** Request & Responses **/

export interface EsgResult {
  esgAnswers: EsgQuestions;
  esgBestMatch?: EsgQuestions;
}

export interface EsgResultResponse {
  esgResult: EsgResult;
}

export type GetSuitabilityAssessmentRequest = AccountQuestions & {
  country: LysaCountry;
  language: Language;
  tracingReference?: string;
  riskAnswers: [
    EligibilityRiskAnswers[EligibilityRiskAnswer.IMPORTANCE],
    EligibilityRiskAnswers[EligibilityRiskAnswer.PROPENSITY],
    EligibilityRiskAnswers[EligibilityRiskAnswer.REACTION]
  ];
  investmentType?: InvestmentType;
  financial: EligibilityFinancials;
};

export interface GetSuitabilityAssessmentResponse extends EsgResultResponse {
  advisedRisk: number;
  declaration: string;
  allocation: { [key: string]: number };
  investmentType: InvestmentType;
}

export function getSuitabilityAssessment(
  data: GetSuitabilityAssessmentRequest
) {
  return API.post<GetSuitabilityAssessmentResponse>(
    `/investments/suitability-assessment`,
    data
  );
}
