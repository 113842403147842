import React, { useContext, FunctionComponent, useRef } from "react";
import { Button, Form, LysaFormRef } from "@lysaab/ui-2";
import { SignupContext } from "../../../state/SignupContext";
import { IntroDocsParagraph } from "../../../components/intro/IntroDocsParagraph";
import { IntroEmailSection } from "../../../components/intro/IntroEmailSection";
import { IntroHeader } from "../../../components/intro/IntroHeader";
import "./Intro.scss";
import { IntroList } from "./IntroList";
import { IntroSubmitButton } from "./IntroSubmitButton";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { LocalizationContext } from "../../../state/LocalizationContext";
import { initiate } from "../../../data/signup";
import { EventTracker } from "../../../utils/eventTracker/EventTracker";

interface Props {
  next: () => void;
}

export const Intro: FunctionComponent<Props> = ({ next }) => {
  const signupContext = useContext(SignupContext);
  const localizationContext = useContext(LocalizationContext);
  const formRef = useRef<LysaFormRef>();

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={(event) => {
        event.preventDefault();
        if (
          formRef.current?.isValid &&
          signupContext.state.email &&
          localizationContext.state.country
        ) {
          initiate(
            signupContext.state.email,
            localizationContext.state.country
          ).then(({ signupId }) => {
            EventTracker.setSignupId(signupId);
            next();
          });
        }
      }}
    >
      <div className="signup-intro-spain">
        <IntroHeader />
        <IntroList />
        <IntroEmailSection />
        <IntroSubmitButton />
        <Button
          variant="secondary"
          block
          component={Link}
          to="/"
          label={<FormattedMessage id="spain.intro.country-select" />}
        />
        <IntroDocsParagraph />
      </div>
    </Form>
  );
};
