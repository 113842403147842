export enum Employment {
  EMPLOYEE = "EMPLOYEE",
  SELF_EMPLOYED = "SELF_EMPLOYED",
  RETIRED = "RETIRED",
  STUDENT = "STUDENT",
  OTHER = "OTHER"
}

export enum MoneyOrigin {
  SAVINGS = "SAVINGS",
  INHERITANCE = "INHERITANCE",
  INCOME = "INCOME",
  PROPERTY_SALE = "PROPERTY_SALE",
  CORPORATION_SALE = "CORPORATION_SALE",
  TRADE = "TRADE",
  DIVIDEND = "DIVIDEND"
}

export enum Purpose {
  FINANCIAL_SECURITY = "FINANCIAL_SECURITY",
  PENSION = "PENSION",
  CONSUMPTION = "CONSUMPTION",
  GIFT = "GIFT",
  LONGTERM_INVESTMENTS = "LONGTERM_INVESTMENTS"
}

export enum DepositInterval {
  MONTHLY = "MONTHLY",
  MULTIPLE_YEARLY = "MULTIPLE_YEARLY",
  ONCE_YEARLY = "ONCE_YEARLY",
  SELDOM = "SELDOM"
}

export enum WithdrawalInterval {
  MONTHLY = "MONTHLY",
  MULTIPLE_YEARLY = "MULTIPLE_YEARLY",
  ONCE_YEARLY = "ONCE_YEARLY",
  SELDOM = "SELDOM"
}
