import React, { useContext, FunctionComponent, useRef } from "react";
import { Button, Form, LysaFormRef } from "@lysaab/ui-2";
import { SignupContext } from "../../state/SignupContext";
import { IntroDocsParagraph } from "../../components/intro/IntroDocsParagraph";
import { IntroEmailSection } from "../../components/intro/IntroEmailSection";
import { IntroHeader } from "../../components/intro/IntroHeader";
import { LocalizationContext } from "../../state/LocalizationContext";
import "./Intro.scss";
import { initiate, saveInterested } from "../../data/signup";
import { IntroList } from "../../components/intro/IntroList";
import { IntroSubmitButton } from "../../components/intro/IntroSubmitButton";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { EventTracker } from "../../utils/eventTracker/EventTracker";

interface Props {
  next: () => void;
}

export const Intro: FunctionComponent<Props> = ({ next }) => {
  const signupContext = useContext(SignupContext);
  const localizationContext = useContext(LocalizationContext);
  const formRef = useRef<LysaFormRef>();

  return (
    <Form
      lysaFormRef={formRef}
      onSubmit={(event) => {
        event.preventDefault();

        if (
          formRef.current?.isValid &&
          signupContext.state.email &&
          localizationContext.state.country
        ) {
          saveInterested(
            signupContext.state.email,
            localizationContext.state.language,
            localizationContext.state.country
          );

          initiate(
            signupContext.state.email,
            localizationContext.state.country
          ).then(({ signupId }) => {
            EventTracker.setSignupId(signupId);
            next();
          });
        }
      }}
    >
      <div className="signup-intro">
        <IntroHeader />
        <IntroList />
        <IntroEmailSection />
        <IntroSubmitButton />
        <Button
          variant="secondary"
          block
          component={Link}
          to="/"
          label={<FormattedMessage id="intro.country-select" />}
        />
        <IntroDocsParagraph />
      </div>
    </Form>
  );
};
