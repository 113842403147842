import { API, encode } from "@lysaab/ui-2";
import { SignupId } from "@lysaab/lysa-onfido/data/signup";
import { ConfirmResponse } from "@lysaab/lysa-onfido/data/onfido";

interface JwtResponse {
  token: string;
}

export function initiate(signupId: SignupId) {
  const url = "/onfido/onboarding/initiate";

  return API.post<JwtResponse>(url, { signupId });
}

export function confirmId(signupId: SignupId) {
  const url = encode`/onfido/onboarding/id/confirm?signupId=${signupId}`;

  return API.post<ConfirmResponse>(url);
}

export function confirmPoa(signupId: SignupId, documentId: string) {
  const url = "/onfido/onboarding/poa/confirm";

  return API.post<ConfirmResponse>(url, { signupId, documentId });
}
