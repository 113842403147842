import {
  HorizonQuestions,
  getValidHorizonQuestions,
  isHorizonUpdated,
  isNeedEarlierProbability,
  isSavingsHorizonLength,
  isValidHorizon,
} from "./HorizonQuestions";
import {
  SustainabilityQuestions,
  getValidSustainability,
  isSustainabilityImportantNone,
  isSustainabilityImportantSpecific,
  isSustainabilityNotImportant,
  isSustainabilityUpdated,
  isValidSustainability,
} from "./SustainabilityQuestions";

export type AccountQuestions = SustainabilityQuestions & HorizonQuestions;

export function isValidAccountQuestions(
  data: unknown
): data is AccountQuestions {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (!isValidHorizon(data)) {
    return false;
  }

  if (!isValidSustainability(data)) {
    return false;
  }

  return true;
}

export function isAccountQuestionsUpdated(
  data: Partial<AccountQuestions>,
  oldData: Partial<AccountQuestions>
): boolean {
  return (
    isHorizonUpdated(data, oldData) || isSustainabilityUpdated(data, oldData)
  );
}

export function getAccountQuestions(data: unknown): Partial<AccountQuestions> {
  if (typeof data !== "object" || data === null) {
    throw new Error(
      "getAccountQuestions - data didn't fulfill requirements for being HorizonQuestions"
    );
  }

  const horizonQuestions = {
    needEarlier:
      "needEarlier" in data && isNeedEarlierProbability(data.needEarlier)
        ? data.needEarlier
        : undefined,
    savingsHorizon:
      "savingsHorizon" in data && isSavingsHorizonLength(data.savingsHorizon)
        ? data.savingsHorizon
        : undefined,
  };

  if (isSustainabilityNotImportant(data)) {
    return {
      ...horizonQuestions,
      sustainability: data.sustainability,
    };
  }

  if (isSustainabilityImportantNone(data)) {
    return {
      ...horizonQuestions,
      sustainability: data.sustainability,
      sustainabilityPreference: data.sustainabilityPreference,
    };
  }

  if (isSustainabilityImportantSpecific(data)) {
    return {
      ...horizonQuestions,
      sustainability: data.sustainability,
      sustainabilityPreference: data.sustainabilityPreference,
      pai: data.pai,
      sfdr: data.sfdr,
      taxonomy: data.taxonomy,
    };
  }

  return {
    ...horizonQuestions,
    sustainability: undefined,
  };
}

export function getValidAccountQuestions(data: unknown): AccountQuestions {
  if (!isValidHorizon(data)) {
    throw new Error(
      "getAccountQuestions - data didn't fulfill requirements for being HorizonQuestions"
    );
  }

  return {
    ...getValidHorizonQuestions(data),
    ...getValidSustainability(data),
  };
}
