import React, { useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import {
  Card,
  RequiredValidator,
  LysaFormRef,
  Form,
  CircleButton,
  CountrySelect,
  WorldCountry,
  Button,
} from "@lysaab/ui-2";
import { SignupContext } from "../../state/SignupContext";
import { LocalizationContext } from "../../state/LocalizationContext";
import "./citizenship.scss";

interface Props {
  next: () => void;
}

export const ROUTE = "/citizenship";

const messages = defineMessages({
  citizenshipLabel: { id: "citizenship.label" },
  citizenshipPlaceholder: { id: "citizenship.placeholder" },
  citizenshipRequired: { id: "citizenship.required" },
});

export const Citizenship = ({ next }: Props) => {
  const intl = useIntl();
  const signupContext = useContext(SignupContext);
  const localizationContext = useContext(LocalizationContext);
  const formRef = useRef<LysaFormRef>();
  const [citizenships, setCitizenships] =
    useState<(WorldCountry | undefined)[]>();

  useEffect(() => {
    if (typeof citizenships !== "undefined") {
      return;
    }
    const defaultCitizenship =
      signupContext.state.citizenships.length > 0
        ? signupContext.state.citizenships
        : [
            (localizationContext.state.country?.toString() as WorldCountry) ||
              WorldCountry.SWEDEN,
          ];
    setCitizenships(defaultCitizenship);
  }, [
    citizenships,
    localizationContext.state.country,
    signupContext.state.citizenships,
  ]);

  return (
    <div className="citizenship">
      <h1>
        <FormattedMessage id="citizenship.header" />
      </h1>
      <Form
        lysaFormRef={formRef}
        onSubmit={(event) => {
          event.preventDefault();
          if (formRef.current?.isValid) {
            signupContext.setState({
              citizenships: citizenships?.filter(isWorldCountry),
            });
            next();
          }
        }}
      >
        <Card>
          {citizenships?.map((citizenship, idx) => (
            <div className="repeat-country" key={idx}>
              <CountrySelect
                language={localizationContext.state.language}
                label={intl.formatMessage(messages.citizenshipLabel)}
                placeholder={intl.formatMessage(
                  messages.citizenshipPlaceholder
                )}
                value={citizenship}
                onChange={(value) => {
                  const newCitizenships = [...(citizenships || [])];
                  newCitizenships[idx] = value.value;
                  setCitizenships(newCitizenships);
                }}
                validators={[
                  new RequiredValidator(
                    intl.formatMessage(messages.citizenshipRequired)
                  ),
                ]}
                omitList={[
                  ...citizenships
                    .filter(isWorldCountry)
                    .filter((country) => country !== citizenship),
                  WorldCountry.UNITED_STATES_OF_AMERICA,
                ]}
              />
              <div className="action-buttons">
                {citizenships.length > 1 && (
                  <CircleButton
                    type="button"
                    onClick={() => {
                      const newCitizenships = [...(citizenships || [])];
                      newCitizenships.splice(idx, 1);
                      setCitizenships(newCitizenships);
                    }}
                    icon="Minus"
                  />
                )}
                {idx === citizenships.length - 1 && citizenships.length < 3 && (
                  <CircleButton
                    type="button"
                    onClick={() => {
                      setCitizenships([...(citizenships || []), undefined]);
                    }}
                    icon="Plus"
                  />
                )}
              </div>
            </div>
          ))}
        </Card>
        <Button
          block
          type="submit"
          data-test-id="citizenship-next-button"
          label={<FormattedMessage id="citizenship.button.next" />}
        />
      </Form>
    </div>
  );
};

const isWorldCountry = (
  country: WorldCountry | undefined
): country is WorldCountry => {
  return typeof country !== "undefined";
};
