import { defineMessages } from "react-intl";
import {
  RiskImportance,
  RiskPropensity,
  RiskReaction,
} from "../../../data/types/Eligibility";
import {
  NeedEarlierProbability,
  SavingsHorizonLength,
} from "../../../data/types/HorizonQuestions";
import {
  PAIImportance,
  SFDRImportance,
  SustainabilityImportance,
  SustainabilityPreference,
  TaxonomyImportance,
} from "../../../data/types/SustainabilityQuestions";

type keys =
  | `suitabilityHeader`
  | `suitabilityIntro`
  | `suitabilityP2`
  | `suitabilityExperienceHeader`
  | `suitabilityExperienceText`
  | `suitabilitySituationHeader`
  | `suitabilitySituationText`
  | `suitabilityRiskHeader`
  | `suitabilityRiskText`
  | `suitabilityRiskImportant${RiskImportance}`
  | `suitabilityRiskPropensity${RiskPropensity}`
  | `suitabilityRiskReaction${RiskReaction}`
  | `suitabilityHorizonHeader`
  | `suitabilityHorizonText`
  | `suitabilityHorizonLength${SavingsHorizonLength}`
  | `suitabilityHorizonProbability${NeedEarlierProbability}`
  | `suitabilityAdviceHeader`
  | `suitabilityAdviceText`
  | `suitabilityEsgHeader`
  | `sustainabilityNotImportant`
  | `sustainabilityImportantNone`
  | `sustainabilityImportantSpecific`
  | `sustainabilityImportance${SustainabilityImportance}`
  | `sustainabilityPreference${SustainabilityPreference}`
  | `pai${PAIImportance}`
  | `sfdr${SFDRImportance}`
  | `taxonomy${TaxonomyImportance}`
  | `updatedEsg`
  | `updatedEsgInsert`
  | `sfdr`
  | `taxonomy`;

export const messages = defineMessages<keys>({
  suitabilityHeader: {
    id: "suitability_pdf_link.header",
  },
  suitabilityIntro: {
    id: "suitability_pdf_link.intro",
  },
  suitabilityP2: {
    id: "suitability_pdf_link.p2",
  },
  suitabilityExperienceHeader: {
    id: "suitability_pdf_link.experience.header",
  },
  suitabilityExperienceText: {
    id: "suitability_pdf_link.experience.text",
  },
  suitabilitySituationHeader: {
    id: "suitability_pdf_link.situation.header",
  },
  suitabilitySituationText: {
    id: "suitability_pdf_link.situation.text",
  },
  suitabilityRiskHeader: {
    id: "suitability_pdf_link.risk.header",
  },
  suitabilityRiskText: {
    id: "suitability_pdf_link.risk.text",
  },
  [`suitabilityRiskImportant${RiskImportance.MINIMIZE}` as const]: {
    id: "suitability_pdf_link.risk.important.minimize",
  },
  [`suitabilityRiskImportant${RiskImportance.BOTH}` as const]: {
    id: "suitability_pdf_link.risk.important.both",
  },
  [`suitabilityRiskImportant${RiskImportance.MAXIMIZE}` as const]: {
    id: "suitability_pdf_link.risk.important.maximize",
  },
  [`suitabilityRiskPropensity${RiskPropensity.TOO_RISKY}` as const]: {
    id: "suitability_pdf_link.risk.propensity.toorisky",
  },
  [`suitabilityRiskPropensity${RiskPropensity.PRETTY_GOOD}` as const]: {
    id: "suitability_pdf_link.risk.propensity.prettygood",
  },
  [`suitabilityRiskPropensity${RiskPropensity.GOOD}` as const]: {
    id: "suitability_pdf_link.risk.propensity.good",
  },
  [`suitabilityRiskPropensity${RiskPropensity.PRETTY_BAD}` as const]: {
    id: "suitability_pdf_link.risk.propensity.prettybad",
  },
  [`suitabilityRiskPropensity${RiskPropensity.BAD}` as const]: {
    id: "suitability_pdf_link.risk.propensity.bad",
  },
  [`suitabilityRiskReaction${RiskReaction.SELL}` as const]: {
    id: "suitability_pdf_link.risk.reaction.sell",
  },
  [`suitabilityRiskReaction${RiskReaction.KEEP}` as const]: {
    id: "suitability_pdf_link.risk.reaction.keep",
  },
  [`suitabilityRiskReaction${RiskReaction.BUY}` as const]: {
    id: "suitability_pdf_link.risk.reaction.buy",
  },
  suitabilityHorizonHeader: {
    id: "suitability_pdf_link.horizon.header",
  },
  suitabilityHorizonText: {
    id: "suitability_pdf_link.horizon.text",
  },
  [`suitabilityHorizonLength${SavingsHorizonLength.SHORT}` as const]: {
    id: "suitability_pdf_link.horizon.length.short",
  },
  [`suitabilityHorizonLength${SavingsHorizonLength.MIDDLE}` as const]: {
    id: "suitability_pdf_link.horizon.length.middle",
  },
  [`suitabilityHorizonLength${SavingsHorizonLength.KINDA_LONG}` as const]: {
    id: "suitability_pdf_link.horizon.length.kindalong",
  },
  [`suitabilityHorizonLength${SavingsHorizonLength.LONG}` as const]: {
    id: "suitability_pdf_link.horizon.length.long",
  },
  [`suitabilityHorizonLength${SavingsHorizonLength.VERY_LONG}` as const]: {
    id: "suitability_pdf_link.horizon.length.verylong",
  },
  [`suitabilityHorizonProbability${NeedEarlierProbability.VERY_LIKELY}` as const]:
    {
      id: "suitability_pdf_link.horizon.probability.verylikely",
    },
  [`suitabilityHorizonProbability${NeedEarlierProbability.LIKELY}` as const]: {
    id: "suitability_pdf_link.horizon.probability.likely",
  },
  [`suitabilityHorizonProbability${NeedEarlierProbability.SOMEWHAT_LIKELY}` as const]:
    {
      id: "suitability_pdf_link.horizon.probability.somewhatlikely",
    },
  [`suitabilityHorizonProbability${NeedEarlierProbability.SOMEWHAT_UNLIKELY}` as const]:
    {
      id: "suitability_pdf_link.horizon.probability.somewhatunlikely",
    },
  [`suitabilityHorizonProbability${NeedEarlierProbability.UNLIKELY}` as const]:
    {
      id: "suitability_pdf_link.horizon.probability.unlikely",
    },
  [`suitabilityHorizonProbability${NeedEarlierProbability.VERY_UNLIKELY}` as const]:
    {
      id: "suitability_pdf_link.horizon.probability.veryunlikely",
    },
  suitabilityAdviceHeader: {
    id: "suitability_pdf_link.advice.header",
  },
  suitabilityAdviceText: {
    id: "suitability_pdf_link.advice.text",
  },
  suitabilityEsgHeader: {
    id: "suitability_pdf_link.esg.header",
  },
  sustainabilityNotImportant: {
    id: "suitability_pdf_link.esg.NOT_IMPORTANT",
  },
  sustainabilityImportantNone: {
    id: "suitability_pdf_link.esg.IMPORTANT.NONE",
  },
  sustainabilityImportantSpecific: {
    id: "suitability_pdf_link.esg.IMPORTANT.SPECIFIC",
  },
  [`sustainabilityImportance${SustainabilityImportance.NOT_IMPORTANT}` as const]:
    {
      id: "suitability_pdf_link.SustainabilityImportance.alt.NOT_IMPORTANT",
    },
  [`sustainabilityImportance${SustainabilityImportance.IMPORTANT}` as const]: {
    id: "suitability_pdf_link.SustainabilityImportance.alt.IMPORTANT",
  },
  [`sustainabilityPreference${SustainabilityPreference.NONE}` as const]: {
    id: "suitability_pdf_link.SustainabilityPreference.alt.NONE",
  },
  [`sustainabilityPreference${SustainabilityPreference.SPECIFIC}` as const]: {
    id: "suitability_pdf_link.SustainabilityPreference.alt.SPECIFIC",
  },
  [`pai${PAIImportance.NOT_IMPORTANT}` as const]: {
    id: "suitability_pdf_link.PAIImportance.alt.NOT_IMPORTANT",
  },
  [`pai${PAIImportance.IMPORTANT}` as const]: {
    id: "suitability_pdf_link.PAIImportance.alt.IMPORTANT",
  },
  [`sfdr${SFDRImportance.NONE}` as const]: {
    id: "suitability_pdf_link.SFDRImportance.alt.NONE",
  },
  [`sfdr${SFDRImportance.LEAST_25}` as const]: {
    id: "suitability_pdf_link.SFDRImportance.alt.LEAST_25",
  },
  [`sfdr${SFDRImportance.LEAST_50}` as const]: {
    id: "suitability_pdf_link.SFDRImportance.alt.LEAST_50",
  },
  [`sfdr${SFDRImportance.LEAST_75}` as const]: {
    id: "suitability_pdf_link.SFDRImportance.alt.LEAST_75",
  },
  [`taxonomy${TaxonomyImportance.NONE}` as const]: {
    id: "suitability_pdf_link.TaxonomyImportance.alt.NONE",
  },
  [`taxonomy${TaxonomyImportance.LEAST_25}` as const]: {
    id: "suitability_pdf_link.TaxonomyImportance.alt.LEAST_25",
  },
  [`taxonomy${TaxonomyImportance.LEAST_50}` as const]: {
    id: "suitability_pdf_link.TaxonomyImportance.alt.LEAST_50",
  },
  [`taxonomy${TaxonomyImportance.LEAST_75}` as const]: {
    id: "suitability_pdf_link.TaxonomyImportance.alt.LEAST_75",
  },
  updatedEsg: {
    id: "suitability_pdf_link.esg.updated",
  },
  updatedEsgInsert: {
    id: "suitability_pdf_link.esg.updated.insert",
  },
  sfdr: {
    id: "suitability_pdf_link.esg.sfdr",
  },
  taxonomy: {
    id: "suitability_pdf_link.esg.taxonomy",
  },
});
