import React, { useContext, useRef, useEffect } from "react";
import { LysaCountry } from "@lysaab/shared";
import {
  Card,
  LysaFormRef,
  Form,
  DanishTin,
  FinnishTin,
  SwedishTin,
  AllTinTypes,
  GermanTin,
  WorldCountry,
  SpanishTin,
  Snackbar,
  SNACKBAR_TYPES,
  Button,
} from "@lysaab/ui-2";
import { SignupContext } from "../../../state/SignupContext";
import { LocalizationContext } from "../../../state/LocalizationContext";
import { CrsReportingCountries } from "../../../pages/crs/CrsReportingCountries";
import { CrsCountrySelect } from "../../../pages/crs/CrsCountrySelect";
import { CrsName } from "../../../pages/crs/CrsName";
import { CrsAddress } from "../../../pages/crs/CrsAddress";
import { CrsBirthday } from "../../../pages/crs/CrsBirthday";
import { FormattedMessage, useIntl } from "react-intl";
import { crsMessages } from "../../../pages/crs/CrsMessages";
import { CrsReportingCountryValidation } from "../../../pages/crs/CrsReportingCountryValidation";

import "./Crs.scss";
import { TaxLink } from "../../../components/taxLink/TaxLink";

interface Props {
  birthdayField?: boolean;
  next: () => void;
}

export const ROUTE = "/crs";

const countryTinParserMap: Record<LysaCountry, AllTinTypes> = {
  [LysaCountry.DENMARK]: DanishTin,
  [LysaCountry.FINLAND]: FinnishTin,
  [LysaCountry.SWEDEN]: SwedishTin,
  [LysaCountry.GERMANY]: GermanTin,
  [LysaCountry.SPAIN]: SpanishTin,
};

export const Crs: React.FC<Props> = ({ birthdayField = false, next }) => {
  const signupContext = useContext(SignupContext);
  const localizationContext = useContext(LocalizationContext);
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();

  useEffect(() => {
    if (
      !signupContext.state.crsReportingCountries &&
      localizationContext.state.country
    ) {
      signupContext.setState({
        crsReportingCountries: [
          {
            tin: "",
            country:
              localizationContext.state.country.toString() as WorldCountry,
          },
        ],
      });
    }
  }, [signupContext, localizationContext.state.country]);

  return (
    <div className="crs-germany">
      <Form
        lysaFormRef={formRef}
        onSubmit={(event) => {
          event.preventDefault();

          let crsBirthday = signupContext.state.crsBirthday;

          if (!birthdayField) {
            const firstCountry = signupContext.state.crsReportingCountries?.[0];

            const tinParserCountry =
              firstCountry?.country || localizationContext.state.country;
            const TinParser = tinParserCountry
              ? countryTinParserMap[tinParserCountry as LysaCountry]
              : undefined;

            if (TinParser && firstCountry?.tin) {
              crsBirthday = new TinParser(firstCountry.tin).getBirthDate();
              signupContext.setState({ crsBirthday });
            }

            if (!crsBirthday) {
              console.error(
                "Could not parse birthday from tin",
                firstCountry?.tin
              );
            }
          }

          if (
            formRef.current?.isValid &&
            signupContext.state.crsReportingCountries &&
            crsBirthday
          ) {
            next();
          }
        }}
      >
        <h1>{intl.formatMessage(crsMessages.header)}</h1>

        <Card>
          <CrsReportingCountries validateAge={!birthdayField} />
          <CrsReportingCountryValidation />
          <CrsCountrySelect />
          <CrsName />
          <CrsAddress />
          {birthdayField && <CrsBirthday />}
        </Card>
        <Snackbar type={SNACKBAR_TYPES.INFO} icon>
          <FormattedMessage
            id="germany.crs.tax-info"
            values={{
              // Not sure what to use here except `any`. It's even
              // what they use themselves in their examples
              // https://github.com/formatjs/formatjs/blob/f7ba6da8a5ab267e3d052c11f4a2103f90cea3c0/packages/react-intl/examples/Messages.tsx#L58
              help: (parts: any) => <TaxLink>{parts}</TaxLink>,
            }}
          />
        </Snackbar>
        <p>{intl.formatMessage(crsMessages.buttonNextConfirmText)}</p>
        <Button
          type="submit"
          block
          label={intl.formatMessage(crsMessages.buttonNext)}
        />
      </Form>
    </div>
  );
};
