import React, { useState, useContext, useEffect } from "react";
import { Spinner, useDebounceValue } from "@lysaab/ui-2";
import { SignupContext } from "../../state/SignupContext";
import { getAllocation } from "../../data/market";
import { Fund, FundType, getHoldings } from "../../data/holdings";
import { PositionsList, Positions } from "../../components/PositionsList";

import "./EditAllocationPositions.scss";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { DEFAULT_VALUES } from "./DefaultValues";
import { LocalizationContext } from "../../state/LocalizationContext";
import { useAdvice } from "../../state/AdviceContext";

interface Props {
  chosenAllocation: string;
}

const DEBOUNCE_TIMER = 500;

const messages = defineMessages({
  positionsListHeaderStocks: { id: "editallocationpositions.header.stocks" },
  positionsListHeaderBonds: { id: "editallocationpositions.header.bonds" },
});

export const EditAllocationPositions = ({ chosenAllocation }: Props) => {
  const [loading, setLoading] = useState(true);
  const [holdings, setHoldings] = useState<Fund[]>();
  const [positions, setPositions] = useState<Positions>({});
  const advice = useAdvice();
  const risk = parseInt(chosenAllocation, 10);
  const signupContext = useContext(SignupContext);
  const localizationContext = useContext(LocalizationContext);
  const investmentType = advice.result?.investmentType;
  const intl = useIntl();
  let investment = signupContext.state.allocationInvestment || 0;

  if (
    investment <
      DEFAULT_VALUES[localizationContext.state.currency].minInvestment ||
    investment >
      DEFAULT_VALUES[localizationContext.state.currency].maxInvestment
  ) {
    investment = 0;
  }

  useEffect(() => {
    getHoldings().then(setHoldings);
  }, []);

  const debouncedRisk = useDebounceValue(risk, DEBOUNCE_TIMER);

  useEffect(() => {
    if (!localizationContext.state.country) {
      return;
    }

    getAllocation(
      debouncedRisk,
      investmentType,
      localizationContext.state.country
    ).then((allocation) => {
      const pos: Positions = {};
      Object.keys(allocation).forEach((isin) => {
        pos[isin] = { worth: (investment * allocation[isin]) / 100 };
      });
      setPositions(pos);
      setLoading(false);
    });
  }, [
    debouncedRisk,
    investmentType,
    investment,
    localizationContext.state.country,
  ]);

  return (
    <div className="edit-allocation-positions-page">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <PositionsList
            header={intl.formatMessage(messages.positionsListHeaderStocks)}
            holdings={holdings}
            positions={positions}
            type={FundType.STOCKS}
          />
          <PositionsList
            header={intl.formatMessage(messages.positionsListHeaderBonds)}
            holdings={holdings}
            positions={positions}
            type={FundType.BONDS}
          />
        </>
      )}

      <div className="disclaimer">
        <FormattedMessage id="editallocationpositions.text.disclaimer" />
      </div>
    </div>
  );
};
