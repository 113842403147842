export enum SavingsHorizonLength {
  VERY_LONG = "VERY_LONG",
  LONG = "LONG",
  KINDA_LONG = "KINDA_LONG",
  MIDDLE = "MIDDLE",
  SHORT = "SHORT",
}

export enum NeedEarlierProbability {
  VERY_UNLIKELY = "VERY_UNLIKELY",
  UNLIKELY = "UNLIKELY",
  SOMEWHAT_UNLIKELY = "SOMEWHAT_UNLIKELY",
  SOMEWHAT_LIKELY = "SOMEWHAT_LIKELY",
  LIKELY = "LIKELY",
  VERY_LIKELY = "VERY_LIKELY",
}

export const isSavingsHorizonLength = (
  value?: unknown
): value is SavingsHorizonLength => {
  return (
    typeof value === "string" &&
    Object.values(SavingsHorizonLength).some((v) => value === v)
  );
};

export const isNeedEarlierProbability = (
  value?: unknown
): value is NeedEarlierProbability => {
  return (
    typeof value === "string" &&
    Object.values(NeedEarlierProbability).some((v) => value === v)
  );
};

export interface HorizonQuestions {
  needEarlier: NeedEarlierProbability;
  savingsHorizon: SavingsHorizonLength;
}

export function isHorizonUpdated(
  data: Partial<HorizonQuestions>,
  oldData: Partial<HorizonQuestions>
): boolean {
  const [horizon, oldHorizon] = [data, oldData].map((horizon) => {
    return {
      needEarlier: horizon.needEarlier,
      savingsHorizon: horizon.savingsHorizon,
    };
  });

  return JSON.stringify(horizon) !== JSON.stringify(oldHorizon);
}

export function isValidHorizon(data: unknown): data is HorizonQuestions {
  if (typeof data !== "object" || data === null) {
    return false;
  }

  if (!("needEarlier" in data) || !isNeedEarlierProbability(data.needEarlier)) {
    return false;
  }

  if (
    !("savingsHorizon" in data) ||
    !isSavingsHorizonLength(data.savingsHorizon)
  ) {
    return false;
  }

  return true;
}

export function getValidHorizonQuestions(data: unknown): HorizonQuestions {
  if (!isValidHorizon(data)) {
    throw new Error(
      "getHorizonQuestions - data didn't fulfill requirements for being HorizonQuestions"
    );
  }

  const horizonQuestions = {
    needEarlier: data.needEarlier,
    savingsHorizon: data.savingsHorizon,
  };

  return horizonQuestions;
}
