import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Card } from "@lysaab/ui-2";
import phoneLock from "./PhoneLock.svg";
import email from "./Email.svg";

import "./AuthSelection.scss";

interface Props {
  nextTotp: () => void;
  nextEmail: () => void;
}

export const AuthSelection: React.FC<Props> = ({ nextTotp, nextEmail }) => {
  return (
    <div className="auth-selection">
      <h1>
        <FormattedMessage id="authselection.header" />
      </h1>
      <p>
        <FormattedMessage id="authselection.intro" />
      </p>
      <section>
        <Card>
          <div className="auth-selection-bg">
            <img src={phoneLock} alt="Logo" />
          </div>
          <div className="auth-selection-fg">
            <div>
              <h2>
                <FormattedMessage id="authselection.app.header" />
              </h2>
              <ul>
                <li>
                  <FormattedMessage id="authselection.app.bullet2" />
                </li>
              </ul>
            </div>

            <Button
              block
              onClick={() => {
                nextTotp();
              }}
              data-test-id="auth-selection-app"
              label={<FormattedMessage id="authselection.app.button" />}
            />
          </div>
        </Card>
        <Card>
          <div className="auth-selection-bg">
            <img src={email} alt="Logo" />
          </div>
          <div className="auth-selection-fg">
            <div>
              <h2>
                <FormattedMessage id="authselection.email.header" />
              </h2>
              <ul>
                <li>
                  <FormattedMessage id="authselection.email.bullet2" />
                </li>
              </ul>
            </div>

            <Button
              block
              onClick={() => {
                nextEmail();
              }}
              data-test-id="auth-selection-email"
              label={<FormattedMessage id="authselection.email.button" />}
            />
          </div>
        </Card>
      </section>
    </div>
  );
};
