import { Typography } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";

export const IntroHeader = () => {
  return (
    <Typography type="h1">
      <FormattedMessage id="intro.greeting" />
    </Typography>
  );
};
